<template>
  <ui-modal
    :show="show"
    no-footer
    v-bind="$attrs"
    v-on="$listeners"
    v-slot="{ close }"
  >
    <div class="pending-payout-alert">
      <div class="pending-payout-alert__title">
        Oops, payout glitch!
      </div>
      <div class="pending-payout-alert__description">
        We are sorry, for technical reasons we are unable
        to make payouts to your bank card at the moment.
        We are working to fix the problem and hope to resume
        normal payout services as soon as possible.
      </div>
      <ui-icon
        name="card-error"
        size="96px"
        class="pending-payout-alert__icon"
      />
      <ui-button
        class="pending-payout-alert__btn"
        size="sm"
        round
        type="primary"
        @click="close"
      >
        {{ $t('form.buttons.ok') }}
      </ui-button>
    </div>
  </ui-modal>
</template>

<script>
export default {
  name: 'PendingPayoutAlert',

  model: {
    prop: 'show',
    event: 'input',
  },

  props: {
    show: Boolean,
  },
};
</script>
