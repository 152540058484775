<template>
  <div class="onboarding-header">
    <ui-icon-button
      v-if="showBackButton"
      height="12px"
      width="14px"
      name="arrow-left"
      class="onboarding-header__button onboarding-header__button_left"
      @click="$emit('previous-step')"
    />
    <ui-icon
      width="90px"
      height="24px"
      name="easytip-horizontal"
    />
    <ui-icon-button
      v-if="currentStep !== stepCount"
      height="12px"
      width="14px"
      name="arrow-right"
      class="onboarding-header__button onboarding-header__button_right"
      :class="{
        'onboarding-header__button_muted': !completedSteps[currentStep]
      }"
      :disabled="!completedSteps[currentStep]"
      @click="$emit('next-step')"
    />
  </div>
</template>

<script>
export default {
  name: 'OnboardingHeader',

  props: {
    currentStep: {
      type: Number,
      required: true,
    },
    stepCount: {
      type: Number,
      default: 3,
    },
    completedSteps: {
      type: Object,
      required: true,
    },
  },

  computed: {
    showBackButton() {
      return this.currentStep > 1 && this.currentStep !== 3;
    },
  },
};
</script>
