<template>
  <div class="document document-privacy">
    <h1 v-if="!hideTitle" class="title">EASYTIP PRIVACY POLICY</h1>
    <div class="updated-date">
      <span>Last updated: 02.02.2024</span>
    </div>
    <div class="document__content">
      <h2 id="section-1">1. INTRODUCTION</h2>
      <p>
        This privacy policy (the “<b>EasyTip Privacy Policy</b>”) notice provides you with details of how QR Tip Ltd, a limited liability company (Company Number 12646560) formed under the laws of England with its registered address at Harwood House, 43 Harwood Road, London,  SW6 4QP United Kingdom (“<b>EasyTip</b>”) collects and processes your Personal Data (as defined in Section 3 below) through your use of the EasyTip website located at <a href="https://easytip.net">easytip.net</a> and any sub-domains used in connection with or related to the same and any future versions (the “<b>EasyTip Website</b>”) as well as EasyTip’s mobile applications (the “<b>EasyTip App</b>”) and proprietary software (the “<b>EasyTip Software</b>”). Together, the EasyTip Website, the EasyTip App and the EasyTip Software constitute the “<b>EasyTip Platform</b>”.
      </p>
      <p>By providing us with your data, you warrant to us that you are over 13 years of age.</p>
      <p>
        EasyTip is the data controller and we are responsible for your Personal Data (EasyTip is also referred to as “we”, “us” or “our” in this privacy notice).
      </p>

      <h2 id="section-2">2. CONTACT DETAILS</h2>
      <p>Our full contact details are:</p>
      <p>QR Tip Ltd<br />Harwood House<br />43 Harwood Road<br />London, SW6 4QP<br />United Kingdom</p>
      <p>Email address: <a href="mailto:info@easytip.net">info@easytip.net</a></p>
      <p>
        It is important that the information EasyTip holds about you is accurate and up to date. Please inform us of any changes to your personal information by emailing us at the email address above.
      </p>

      <h2 id="section-3">3. PURPOSE OF DATA COLLECTION</h2>
      <p>We process your data in order to provide you with the EasyTip Platform and all associated services.</p>

      <h2 id="section-4">4. TYPES OF DATA COLLECTED AND PROCESSED</h2>
      <p>“<b>Personal Data</b>” means any information capable of identifying an individual (and does not include anonymised data).</p>
      <p>EasyTip may process the following categories of Personal Data about you:</p>
      <ul class="list-style-lower-latin">
        <li>
          <p><b>Communication Data</b></p>
          <p>
            “<b>Communication Data</b>” includes any communications that you send to EasyTip, whether through the contact form on our website, via email, text message, social media messaging, social media posting or by any other means. EasyTip processes this data for the purposes of communicating with you. We also process it to keep records and to establish, pursue or defend legal claims. EasyTip’s lawful ground for this type of processing is our legitimate interest in replying to communications we receive, keeping records, and establishing, pursuing or defending legal claims.
          </p>
        </li>
        <li>
          <p><b>Customer Data</b></p>
          <p>
            “<b>Customer Data</b>” includes: (1) data provided by you in opening an account on the EasyTip Platform (or by someone opening an account on your behalf).  Such data may include your name, title, email address, phone number, and other contact details and, in certain circumstances, your photograph; (2) data relating to any payments you make or receive using the Payment Processors associated with the EasyTip Platform (such as the amount, date and recipient of payments); and (3) contact details optionally provided by Customers when submitting reviews. EasyTip processes this data to enable you to access and use the EasyTip Platform, to keep records of any payments you make or receive, and to enable follow-up on Customer reviews by the Merchants who use the EasyTip Platform. EasyTip’s lawful ground for this processing is the provision (or prospective provision) of services to you.
          </p>
        </li>
        <li>
          <p><b>Usage Data</b></p>
          <p>
            “<b>Usage Data</b>” includes data about how you use the EasyTip Platform together with any data that you post on the EasyTip Platform, including any reviews. EasyTip processes this data to operate the EasyTip Platform and to make sure that appropriate content is provided to you. EasyTip also processes this date to ensure the security of the EasyTip Platform, to maintain back-ups of information and to enable the publication and administration of the EasyTip Platform. EasyTip’s lawful ground for such processing is our legitimate interest in enabling the proper administer of the EasyTip Platform and our business.
          </p>
        </li>
        <li>
          <p><b>Technical Data</b></p>
          <p>“<b>Technical Data</b>” includes data about your use of the EasyTip Platform, and includes:</p>
          <ul>
            <li>your IP address, log-in details, and browser details;</li><li>the duration, number and frequency of your visits to EasyTip Website pages and the EasyTip App, and your page views and navigation paths; and</li><li>time zone settings and other details regarding the devices with which you access the EasyTip Platform.</li>
          </ul>
          <p>
            This technical data is produced by an analytics tracking system. EasyTip processes this data to analyse your use of the EasyTip Platform, to administer the EasyTip Platform and our business, to deliver website content and advertisements to you, and to analyse the effectiveness of our advertising. EasyTip’s lawful ground for processing this type of data is our legitimate interests to enable the proper administration of the EasyTip Platform and to develop our business and marketing strategies.
          </p>
        </li>
        <li>
          <p><b>Marketing Data</b></p>
          <p>
            “<b>Marketing Data</b>” includes data regarding your communication and marketing preferences and your preferences regarding EasyTip and third parties. EasyTip processes this data in order to deliver relevant content and advertisements to you and to analyse the effectiveness of this advertising. EasyTip’s lawful ground for processing this type of data is our legitimate interests in studying how Users use our products and services, developing such products and services, and determining our marketing strategy.
          </p>
          <p>
            EasyTip may use Customer Data, Usage Data, Technical Data and Marketing Data to deliver relevant content, advertisements and other marketing communications to you and to determine the effectiveness of such advertisements. EasyTip’s lawful ground for this is our legitimate interest to develop our business.
          </p>
        </li>
        <li>
          <p><b>Sensitive Data</b></p>
          <p>
            “<b>Sensitive Data</b>” is data that includes details about your race or ethnicity, religious or philosophical beliefs, sex life, sexual orientation, political opinions, membership in trade unions, and health, or genetic and biometric data. EASYTIP DOES NOT COLLECT ANY SENSITIVE DATA ABOUT YOU.
          </p>
        </li>
        <li>
          <p><b>Other</b></p>
          <p>
            EasyTip may be required to collect Personal Data by law. EasyTip may also do so under the terms of the contract between EasyTip and you. If you do not provide EasyTip with that data when requested, EasyTip may not be able to perform the relevant services.
          </p>
          <p>
            EasyTip will only use your Personal Data for the purpose for which it was originally collected or, if necessary, a reasonably compatible purpose. If EasyTip is required to use your Personal Data for a new and unrelated new purpose, EasyTip will inform you of the grounds for this use.
          </p>
          <p>EasyTip may process your Personal Data without your knowledge or consent where this is required or permitted by law.</p>
        </li>
      </ul>

      <h2 id="section-5">5. PERSONAL DATA COLLECTION</h2>
      <p>
        EasyTip may collect data about you when you provide the data directly to us (for example, by completing a form on the EasyTip Platform, by opening an account, by sending us emails or messages, or by providing us with feedback). EasyTip may collect certain data from you automatically as you use the EasyTip Platform. Please see EasyTip’s cookie policy for more details: <a href="https://easytip.net/privacy-policy">https://easytip.net/privacy-policy</a>
      </p>
      <p>
        The Personal Data we collect is required to identity establishment and verification, anti-money laundering and terrorist financing measures, provision of Card Services, execution of contractual and other legal obligations.
      </p>
      <p>
        Information we may collect: your name, surname, nationality, personal number, date of birth, number of your ID document, a copy of your ID document, address, a photo, face data, email address, phone number, payment account number, IP address, occupation and other information necessary to comply with anti-money laundering and terrorist financing requirements and to provide you with Card Services. We collect this data directly from you and/or from third parties including but not limited to identity verification service providers (<a href="https://onfido.com">onfido.com</a>) and financial sanctions databases.
      </p>
      <p>
        EasyTip may receive data about you from third parties such as analytics providers, advertising networks and search information providers based outside of the EU, and from providers of payment and technical services.
      </p>
      <p>
        We and third-parties mentioned above will store your data for 5 years as of the completion of a transaction or a business relationship. Face data is not retained by EasyTip.
      </p>
      <p>
        We can provide this data to supervising institutions, credit, financial, payment and (or) electronic money institutions, courts, law enforcement institutions, tax authorities, payment receivers, lawyers, auditors, other subjects with a legal access to this information and subjects with whom we have a contract but only if you give your consent.
      </p>
      <p>
        The storage and processing of your Personal Data are governed by the laws of England and EasyTip terms available on <a href="https://easytip.net">www.easytip.net</a>. If you refuse to provide this data and or/information or withdraw your consent, we retain the right not to enter into or terminate this Agreement with you and to refuse to provide you with our Card Services.
      </p>
      <p>
        Providing your personal data as described herein is fundamentally necessary for us to provide you with services via the EasyTip-Platform. Therefore, if you do not want to provide your personal data, we will not be able to provide you with such services.
      </p>

      <h2 id="section-6">6. MARKETING COMMUNICATIONS</h2>
      <p>
        EasyTip’s lawful ground for processing your Personal Data to send you marketing communications is either your consent or our legitimate interest in developing our business.
      </p>
      <p>Under the applicable law, EasyTip may send you marketing communications if:</p>
      <ul class="list-style-lower-latin">
        <li>You have requested information from EasyTip or purchased goods or services from EasyTip; or</li>
        <li>You have provided EasyTip with your details when entering a competition or registering for a promotion or free resources; and</li>
        <li>In the case of either (a) or (b) above, you have not opted out of receiving those marketing communications.</li>
      </ul>
      <p>
        EasyTip will obtain your express opt-in consent before sharing your Personal Data with any third party for their marketing purposes.
      </p>
      <p>
        You can ask EasyTip or any third parties to stop sending you marketing messages at any time by: (1) following the opt-out link on any marketing communication you receive; or (2) emailing us at the email address set out in Section 2 above.
      </p>
      <p>
        Where you opt-out of receiving marketing communications, this will not apply to Personal Data provided to us as a result of the use of a product or service or other transactions.
      </p>

      <h2 id="section-7">7. DISCLOSURE OF YOUR PERSONAL DATA</h2>
      <p>EasyTip may be required to share your Personal Data with the following parties:</p>
      <ul>
        <li>Providers of IT and system administration services;</li>
        <li>Professional advisers including lawyers, bankers, auditors and insurers;</li>
        <li>Tax authorities (including HM Revenue & Customs);</li>
        <li>Other regulators and authorities in the United Kingdom and other relevant jurisdictions who may require reporting of our activities in certain circumstances; and</li>
        <li>Third parties to whom EasyTip sells, transfers, or merges parts of our business or our assets.</li>
      </ul>
      <p>
        EasyTip requires all third parties to whom we transfer your data to respect the security of your Personal Data and to treat it in accordance with the law. EasyTip only allows such third parties to process your Personal Data for specific purposes that comply with this Privacy Policy and that have been agreed to by EasyTip.
      </p>
      <p>
        In the case of Service Providers using the EasyTip Platform to connect to a Payment Processor to receive payments of tips via such Payment Processor, EasyTip may be required to share Personal Data with your employer or accountants and other professionals working on your employer's behalf for the purposes of: (1) verifying your employment; and (2) enabling such persons to operate payroll and tax procedures. EasyTip may also share Personal Data with such employers for the purposes of implementing schemes for the pooling of tips.
      </p>

      <h2 id="section-8">8. INTERNATIONAL DATA TRANSFERS</h2>
      <p>
        EasyTip is subject to the provisions of the General Data Protection Regulations that protect your Personal Data. If EasyTip transfers your data to third parties outside of the EEA, we will ensure that certain safeguards are in place to ensure a similar degree of security for your Personal Data. Therefore:
      </p>
      <ul>
        <li>
          EasyTip may transfer your Personal Data to countries that the European Commission have approved as providing an adequate level of protection for Personal Data; or
        </li>
        <li>
          Where EasyTip uses certain service providers who are established outside of the EEA, we may use specific contracts or codes of conduct or certification mechanisms approved by the European Commission which give Personal Data the same protection it has in Europe.
        </li>
      </ul>
      <p>
        If none of the above safeguards is available, EasyTip may request your explicit consent to the specific transfer. You will have the right to withdraw this consent at any time.
      </p>

      <h2 id="section-9">9. DATA SECURITY</h2>
      <p>
        EasyTip has put in place security measures to prevent your Personal Data from being accidentally lost, used, altered, disclosed, or accessed without authorisation. EasyTip allows access to your Personal Data only by those employees and partners who have a business need to know such data. Such persons will only process your Personal Data on EasyTip’s instructions, and they must keep it confidential.
      </p>
      <p>
        EasyTip has established procedures to deal with any suspected Personal Data breaches. EasyTip will notify you and any relevant regulator of a breach if we are legally required to.
      </p>

      <h2 id="section-10">10. DATA RETENTION</h2>
      <p>
        EasyTip will only retain your Personal Data for as long as necessary to fulfil the purposes for which we collected it (including for the purposes of satisfying any legal, accounting, or reporting requirements).
      </p>
      <p>
        When determining the appropriate period for the retention of data, EasyTip considers the following factors with respect to the relevant data: amount; nature and sensitivity; potential risk of harm from unauthorised use/disclosure; processing purposes; and legal requirements.
      </p>
      <p>
        For tax purposes, the law may require EasyTip to keep basic information about our customers (including contact and identity details and financial and transaction data for six years after they stop being customers).
      </p>
      <p>
        In some circumstances, EasyTip may anonymise your Personal Data for research or statistical purposes in which case EasyTip may use this information indefinitely without further notice to you.
      </p>

      <h2 id="section-11">11. YOUR RIGHTS</h2>
      <p>
        Under data protection laws you have rights in relation to your Personal Data, including the right to request access, correction, erasure, restriction, transfer, to object to processing, to portability of data and (where the lawful ground of processing is consent) to withdraw consent.
      </p>
      <p>
        More information about these rights is available at: <a href="https://ico.org.uk/for-organisations/guide-to-the-general-data-protection-regulation-gdpr/individual-rights/">https://ico.org.uk/for-organisations/guide-to-the-general-data-protection-regulation-gdpr/individual-rights/</a>
      </p>
      <p>If you wish to exercise any of your rights, please email EasyTip at the email address set out in Section 2 above.</p>
      <p>
        You will not be charged any fee to access your Personal Data (or to exercise any of the other rights). However, EasyTip may charge a reasonable fee if your request is clearly unfounded, repetitive or excessive. EasyTip may also refuse to comply with your request in these circumstances.
      </p>
      <p>
        EasyTip may be required to request specific information from you to help confirm your identity and ensure your right to access your Personal Data (or to exercise any other rights). This is a security measure to ensure that Personal Data is not unlawfully disclosed to any person. EasyTip may also contact you to ask you for further information in relation to your request to speed up our response.
      </p>
      <p>
        EasyTip endeavours to respond to all legitimate requests within one month. It may on occasion take longer than one month to respond if your request is particularly complex or you have made numerous requests. In this case, EasyTip will notify you.
      </p>
      <p>
        If you are not happy with any aspect of how EasyTip collects and uses your data, you have the right to complain to the Information Commissioner’s Office (ICO), the UK supervisory authority for data protection issues (<a href="https://ico.org.uk">www.ico.org.uk</a>). EasyTip would appreciate it if you contact us first if you do have a complaint so that we can try to resolve it for you.
      </p>

      <h2 id="section-12">12. THIRD-PARTY LINKS</h2>
      <p>
        The EasyTip Platform may include links to third-party websites, plug-ins and applications. Clicking on those links or enabling those connections may allow third parties to collect or share data about you. EasyTip does not control these third-party websites and we are not responsible for their data management procedures. When you leave the EasyTip Platform, we encourage you to read the privacy statement of each website you visit.
      </p>

      <h2 id="section-13">13. COOKIES</h2>
      <p>
        You can set your browser to refuse all or some browser cookies, or to alert you when websites set or access cookies. If you disable or refuse cookies, please note that some parts of the EasyTip Platform may become inaccessible or not function properly. For more information, please see our Cookie Policy at: <a href="https://easytip.net/privacy-policy">https://easytip.net/privacy-policy</a>
      </p>

      <h2 id="section-14">14. EASYTIP PLATFORM USAGE – TERMS AND CONDITIONS</h2>
      <p>
        By browsing and using the EasyTip Platform, you are agreeing to comply with and be bound by the EasyTip Terms of Service available at: <a href="https://easytip.net/terms-of-service">https://easytip.net/terms-of-service</a>.
      </p>
      <div class="document__gradient" />
    </div>
  </div>
</template>

<script>
export default {

  props: {
    hideTitle: Boolean,
  },
};
</script>
