export const GENDERS = [
  {
    value: 'FEMALE',
    name: 'Female',
  },
  {
    value: 'MALE',
    name: 'Male',
  },
];
