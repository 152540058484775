<template>
  <div
    class="payout-plan"
    :class="[
      { 'payout-plan_free': isFree },
      { 'payout-plan_selected': selected },
      { 'payout-plan_disabled': disabled },
    ]"
  >
    <div class="payout-plan__free-circle" />
    <div class="payout-plan__status">
      <div class="payout-plan__status-value" />
    </div>
    <div class="payout-plan__free-description">
      <div class="payout-plan__free-title">
        Free
      </div>
      <div class="payout-plan__free-period">
        14 days
      </div>
    </div>
    <div class="payout-plan__content">
      <div>
        <div v-if="plan.header" class="payout-plan__title">
          {{ plan.header }}
        </div>
        <div v-if="plan.info" class="payout-plan__info">
          {{ plan.info.value }} {{ plan.info.description }}
        </div>
      </div>
      <div class="payout-plan__content_bottom">
        <div v-if="plan.example" class="payout-plan__example">
          Example:<br/>
          {{ plan.example[0] }}
        </div>
        <div v-if="plan.periodPayedDescription" class="payout-plan__period">
          {{ plan.periodPayedDescription }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PayoutPlan',

  props: {
    plan: {
      type: Object,
      required: true,
    },
    selected: Boolean,
    isFree: Boolean,
    disabled: Boolean,
  },
};
</script>
