export const LABELS = {
  gbp: '£',
  usd: '$',
  aed: 'AED',
  eur: '€',
  chf: '₣',
};

export const GBP = {
  iso: 'gbp',
  label: LABELS.gbp,
};

export const USD = {
  iso: 'usd',
  label: LABELS.usd,
};

export const AED = {
  iso: 'aed',
  label: LABELS.aed,
};

export const EUR = {
  iso: 'eur',
  label: LABELS.eur,
};

export const CHF = {
  iso: 'chf',
  label: LABELS.chf,
};

export default {
  gb: GBP,
  us: USD,
  ca: USD,
  ae: AED,
  ch: CHF,

  at: EUR,
  be: EUR,
  de: EUR,
  gr: EUR,
  ie: EUR,
  es: EUR,
  it: EUR,
  cy: EUR,
  lv: EUR,
  lt: EUR,
  lu: EUR,
  mt: EUR,
  nl: EUR,
  pt: EUR,
  sk: EUR,
  sl: EUR,
  fi: EUR,
  fr: EUR,
  ee: EUR,
};
