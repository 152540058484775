<template>
  <div class="onboarding-onfido-error">
    <div
      v-if="statusConfig.message"
      class="onboarding-onfido-error__message"
    >
      {{ statusConfig.message }}
    </div>
    <div
      v-if="statusConfig.buttons"
      class="onboarding-onfido-error__actions"
    >
      <new-button
        v-if="statusConfig.buttons.includes(STATUS_CONFIG_BUTTONS_TYPES.TRY_AGAIN)"
        wide
        @click="$emit('try-again')"
      >
        Start again
      </new-button>
      <new-button
        v-if="statusConfig.buttons.includes(STATUS_CONFIG_BUTTONS_TYPES.CONTINUE)"
        wide
        @click="skipOnboardingHandler"
      >
        Continue
      </new-button>
      <new-button
        v-if="statusConfig.buttons.includes(STATUS_CONFIG_BUTTONS_TYPES.EXIT_APP)"
        wide
        @click="$router.push({ name: 'logout' })"
      >
        Exit app
      </new-button>
    </div>
  </div>
</template>
<script>
const STATUS_CONFIG_BUTTONS_TYPES = {
  TRY_AGAIN: 'TRY_AGAIN',
  CONTINUE: 'CONTINUE',
  EXIT_APP: 'EXIT_APP',
};

export default {
  props: {
    // {
    //   "status": "string",
    //
    //   "backPage": "string"
    //   "code": "string",
    //   "message": "string",
    //   "buttons": [
    //     "TRY_AGAIN",
    //     "CONTINUE",
    //     "EXIT_APP"
    //   ]
    // }
    statusConfig: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      STATUS_CONFIG_BUTTONS_TYPES,
    };
  },

  methods: {
    skipOnboardingHandler() {
      this.$router.push({ name: 'overview', params: { skipOnboarding: 'true' } });
    },
  },
};
</script>
