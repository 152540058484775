<template>
  <div class="root-modals">
    <user-locations-modal v-if="!isAdminOrSales" />
    <id-info-modal
      v-if="showRequestIdModal"
      :user-id="profile.id"
    />
    <bank-details-modal
      v-model="showBankDetailsModal"
    />
    <pending-payout-alert
      v-model="showPendingPayoutAlert"
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import BankDetailsModal from '@/components/BankInfo/BankDetailsModal.vue';
import UserLocationsModal from '@/components/User/UserLocationsModal.vue';
import IdInfoModal from '@/components/IdInfo/IdInfoModal.vue';
import PendingPayoutAlert from '@/components/PendingPayoutAlert.vue';

export default {
  name: 'RootModals',
  components: {
    BankDetailsModal,
    UserLocationsModal,
    PendingPayoutAlert,
    IdInfoModal,
  },

  data() {
    return {
      showPendingPayoutAlert: false,
      showBankDetailsModal: false,
    };
  },

  computed: {
    ...mapState('user', [
      'profile',
      'showRequestIdModal',
    ]),
    ...mapGetters({
      isAdminOrSales: 'auth/isAdminOrSales',
    }),
  },

  watch: {
    'profile.pendingPayoutAlert': {
      immediate: true,
      handler(pendingPayoutAlert) {
        this.showPendingPayoutAlert = Boolean(pendingPayoutAlert);
      },
    },
    'profile.isRequiredBankAccountInformation': {
      immediate: true,
      handler(isRequiredBankAccountInformation) {
        this.showBankDetailsModal = Boolean(isRequiredBankAccountInformation);
      },
    },
  },
};
</script>
