<template>
  <onboarding-step
    v-if="showManageVenuePayout"
    title="Company bank account"
    description="Please add your company bank account details to Stripe - our payment processing partner. The tips you collect will be sent to your bank account directly. "
  >
    <div class="onboarding-stripe-card">
      <user-stripe
        is-onboarding
        is-venue
        :back-route="backRoute"
      />
    </div>
  </onboarding-step>
</template>

<script>
import { mapState } from 'vuex';
import OnboardingStep from '@/components/Onboarding/OnboardingStep.vue';
import UserStripe from '@/components/User/UserStripe.vue';

export default {
  name: 'OnboardingStripeCard',

  components: {
    UserStripe,
    OnboardingStep,
  },

  props: {
    backRoute: {
      type: String,
      default: '',
    },
  },

  computed: {
    ...mapState('user', [
      'showManageVenuePayout',
    ]),
  },

  created() {
    if (!this.showManageVenuePayout) {
      this.$emit('next-step');
    }
  },
};
</script>
