<template>
  <div class="onboarding-stepper">
    <div class="onboarding-stepper-wrapper">
      <div
        v-for="(step, index) in stepCount"
        :key="step"
        class="onboarding-stepper__step-wrapper"
      >
        <div v-if="index" class="onboarding-stepper__divider" />
        <div
          class="onboarding-stepper__step"
          :class="{ 'onboarding-stepper__step_active': step <= currentStep }"
        >
          <ui-icon
            v-if="currentStep > step"
            name="onboarding-check"
            width="25px"
            height="22px"
          />
          <template v-else>
            {{ step }}
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OnboardingStepper',

  props: {
    currentStep: {
      type: Number,
      required: true,
    },
    stepCount: {
      type: Number,
      default: 3,
    },
  },
};
</script>
