<template>
  <div class="document document-privacy">
    <h1 v-if="!hideTitle" class="title">EASYTIP PRIVACY POLICY</h1>
    <div class="updated-date">
      <span>Last updated: 02.02.2024</span>
    </div>
    <div class="document__content">
      <h2 id="section-1">1. INTRODUCTION</h2>
      <p>
        QR Tip Information Technologies LLC ("<b>EasyTip</b>", "<b>we</b>" or "<b>us</b>") respects your privacy and is committed to protecting your personal data.  This Data Privacy Policy (the "<b>Privacy Policy</b>") sets out how EasyTip collects and processes personal information about you when you visit our website located at <a href="https://easytip.net">www.easytip.net</a> (together with any related subdomains and any future versions, the “<b>EasyTip Website</b>”) or use EasyTip’s mobile applications (the “<b>EasyTip App</b>”) and proprietary software (the "<b>EasyTip Software</b>"). Together, the EasyTip Website, the EasyTip App and the EasyTip Software constitute the “<b>EasyTip Platform</b>”.
      </p>
      <p>
        It is important that you read this Privacy Policy together with our Terms and Conditions which can be found at <a href="https://easytip.net">www.easytip.net</a>.
      </p>

      <h2 id="section-2">2. WHO WE ARE</h2>
      <p>
        The EasyTip Platform is operated by QR Tip Information Technologies LLC, a limited liability company incorporated under the laws of the Dubai DED in the United Arab Emirates under registered number 1136779 with its registered address at Albahar 546-0, Al Khabeesi, Dubai, UAE.
      </p>

      <h2 id="section-3">3. WHAT TYPES OF DATA IS COLLECTED AND PROCESSED</h2>
      <p>“<b>Personal Data</b>” means any information capable of identifying an individual (and does not include anonymised data).</p>
      <p>EasyTip may process the following categories of Personal Data about you:</p>
      <ul class="list-style-lower-latin">
        <li>
          <p><b>Communication Data</b></p>
          <p>
            “<b>Communication Data</b>” includes any communications that you send to EasyTip, whether through the contact form on our website, via email, text message, social media messaging, social media posting or by any other means. EasyTip processes this data for the purposes of communicating with you. We also process it to keep records and to establish, pursue or defend legal claims. EasyTip’s lawful ground for this type of processing is our legitimate interest in replying to communications we receive, keeping records, and establishing, pursuing or defending legal claims.
          </p>
        </li>

        <li>
          <p><b>Customer Data</b></p>
          <p>
            “<b>Customer Data</b>” includes: (1) data provided by you in opening an account on the EasyTip Platform (or by someone opening an account on your behalf). Such data may include your name, title, email address, phone number, and other contact details and, in certain circumstances, your photograph; (2) data relating to any payments you make or receives using the Payment Processors associated with the EasyTip Platform (such as the amount, date and recipient of payments); and (3) contact details optionally provided by Customers when submitting reviews. EasyTip processes this data to enable you to access and use the EasyTip Platform, to keep records of any payments you make or receive, and to enable follow-up on Customer reviews by the Merchants who use the EasyTip Platform. EasyTip’s lawful ground for this processing is the provision (or prospective provision) of services to you.
          </p>
        </li>

        <li>
          <p><b>Usage Data</b></p>
          <p>
            “<b>Usage Data</b>” includes data about how you use the EasyTip Platform together with any data that you post on the EasyTip Platform, including any reviews. EasyTip processes this data to operate the EasyTip Platform and to make sure that appropriate content is provided to you. EasyTip also processes this date to ensure the security of the EasyTip Platform, to maintain back-ups of information and to enable the publication and administration of the EasyTip Platform. EasyTip’s lawful ground for such processing is our legitimate interest in enabling the proper administer of the EasyTip Platform and our business.
          </p>
        </li>

        <li>
          <p><b>Technical Data</b></p>
          <p>“<b>Technical Data</b>” includes data about your use of the EasyTip Platform, and includes:</p>
          <ul>
            <li>
              your IP address, log-in details, and browser details;
            </li>
            <li>
              the duration, number and frequency of your visits to EasyTip Website pages and the EasyTip App, and your page views and navigation paths; and
            </li>
            <li>
              time zone settings and other details regarding the devices with which you access the EasyTip Platform.
            </li>
          </ul>
          <p>
            This technical data is produced by an analytics tracking system. EasyTip processes this data to analyse your use of the EasyTip Platform, to administer the EasyTip Platform and our business, to deliver website content and advertisements to you, and to analyse the effectiveness of our advertising. EasyTip’s lawful ground for processing this type of data is our legitimate interests to enable the proper administration of the EasyTip Platform and to develop our business and marketing strategies.
          </p>
        </li>

        <li>
          <p><b>Marketing Data</b></p>
          <p>“<b>Marketing Data</b>” includes data regarding your communication and marketing preferences and your preferences regarding EasyTip and third parties. EasyTip processes this data in order to deliver relevant content and advertisements to you and to analyse the effectiveness of this advertising. EasyTip’s lawful ground for processing this type of data is our legitimate interests in studying how Users use our products and services, developing such products and services, and determining our marketing strategy.</p>
          <p>
            EasyTip may use Customer Data, Usage Data, Technical Data and Marketing Data to deliver relevant content, advertisements and other marketing communications to you and to determine the effectiveness of such advertisements. EasyTip’s lawful ground for this is our legitimate interest to develop our business.
          </p>
        </li>

        <li>
          <p><b>Sensitive Data</b></p>
          <p>“<b>Sensitive Data</b>” is data that includes details about your race or ethnicity, religious or philosophical beliefs, sex life, sexual orientation, political opinions, membership in trade unions, and health, or genetic and biometric data. EasyTip does not collect any sensitive data about you.</p>
          <p>
            EasyTip may be required to collect Personal Data by law. EasyTip may also do so under the terms of the contract between EasyTip and you. If you do not provide EasyTip with that data when requested, EasyTip may not be able to perform the relevant services.
          </p>
          <p>
            EasyTip will only use your Personal Data for the purpose for which it was originally collected or, if necessary, a reasonably compatible purpose. If EasyTip is required to use your Personal Data for a new and unrelated new purpose, EasyTip will inform you of the grounds for this use.
          </p>
          <p>
            EasyTip may process your Personal Data without your knowledge or consent where this is required or permitted by law.
          </p>
        </li>
      </ul>

      <h2 id="section-4">4. WHAT PERSONAL DATA IS COLLECTED</h2>
      <p>
        EasyTip may collect data about you when you provide the data directly to us (for example, by completing a form on the EasyTip Platform, by opening an account, by sending us emails or messages, or by providing us with feedback). EasyTip may collect certain data from you automatically as you use the EasyTip Platform. Please see EasyTip’s cookie policy for more details: easytip.net/cookiepolicy.
      </p>

      <p>
        The Personal Data we collect is required to identity establishment and verification, anti-money laundering and terrorist financing measures, provision of Card Services, execution of contractual and other legal obligations.
      </p>

      <p>
        Information we may collect: your name, surname, nationality, personal number, date of birth, number of your ID document, a copy of your ID document, address, a photo, face data, email address, phone number, payment account number, IP address, occupation and other information necessary to comply with anti-money laundering and terrorist financing requirements and to provide you with Card Services. We collect this data directly from you and/or from third parties including but not limited to identity verification service providers (<a href="https://onfido.com">onfido.com</a>) and financial sanctions databases.
      </p>

      <p>
        EasyTip may receive data about you from third parties such as analytics providers, advertising networks and search information providers based outside of the EU, and from providers of payment and technical services.
      </p>

      <p>
        We and third-parties mentioned above will store your data for 5 years as of the completion of a transaction or a business relationship. Face data is not retained by EasyTip.
      </p>

      <p>
        We can provide this data to supervising institutions, credit, financial, payment and (or) electronic money institutions, courts, law enforcement institutions, tax authorities, payment receivers, lawyers, auditors, other subjects with a legal access to this information and subjects with whom we have a contract but only if you give your consent.
      </p>

      <p>
        The storage and processing of your Personal Data are governed by the laws of the UAE and EasyTip terms available on www.easytip.net. If you refuse to provide this data and or/information or withdraw your consent, we retain the right not to enter into or terminate this Agreement with you and to refuse to provide you with our Card Services.
      </p>

      <p>
        Please note that as a user of the EasyTip Platform, you are responsible for keeping your login information, including your password, confidential. If you believe that your login information or password has been compromised or that someone else has accessed your account, please notify us immediately by contacting us at info@easytip.net.
      </p>

      <p>
        It is also important that the information EasyTip holds about you is accurate and up to date. Please inform us of any changes to your personal information by emailing us at the email address above.
      </p>

      <h2 id="section-5">5. WHY DO WE NEED YOUR INFORMATION?</h2>
      <p>
        We need the information we collect automatically about your visit to the EasyTip Platform to:
      </p>
      <ul class="list-style-lower-latin">
        <li>administer the EasyTip Platform and the associated services;</li>
        <li>improve our services to ensure that they are provided in the most effective manner;</li>
        <li>allow you to participate in interactive features of the EasyTip Platform when you choose to do so;</li>
        <li>analyse how visitors use the EasyTip Platform (including behaviour patterns and the tracking of visits across multiple devices); and</li>
        <li>improve your online experience.</li>
      </ul>
      <p>EasyTip needs the information you provide directly, including by filling in any forms, to:</p>
      <ul class="list-style-lower-latin">
        <li>provide the EasyTip Platform and the associated services to you;</li>
        <li>direct you to products and/or services, including third party products and/or services, which may be of interest to you;</li>
        <li>contact you with information and offers, including third party offers, suggestions and recommendations of products and/or services which may be of interest to you; and</li>
        <li>comply with our regulatory or legislative requirements.</li>
      </ul>
      <p>You do not have to give us any information or confirm that the information we have obtained from other sources is correct.</p>

      <h2 id="section-6">6. WHO DO WE SHARE YOUR INFORMATION WITH?</h2>
      <p>EasyTip may share some of your information with the following parties:</p>
      <ul>
        <li>Our parent company, QR Tip Ltd, a company incorporated under the laws of England & Wales;</li>
        <li>Providers of IT, Financial services and system administration services;</li>
        <li>Professional advisers including lawyers, bankers, auditors and insurers;</li>
        <li>Tax authorities;</li>
        <li>Other regulators and authorities in the UAE and other relevant jurisdictions who may require reporting of our activities in certain circumstances; and</li>
        <li>Third parties to whom EasyTip sells, transfers, or merges parts of our business or our assets.</li>
      </ul>
      <p>
        In the case of Service Providers using the EasyTip Platform to connect to a Payment Processor to receive payments of tips via such Payment Processor, EasyTip may be required to share Personal Data with your employer or accountants and other professionals working on your employer's behalf for the purposes of: (1) verifying your employment; and (2) enabling such persons to operate payroll and tax procedures. EasyTip may also share Personal Data with such employers for the purposes of pooling and distributing tips.
      </p>
      <p>
        In the future, we may choose to sell business assets. User information is typically one of the transferred business assets. If we, or substantially all of our assets, were acquired, or if we go out of business or enter bankruptcy or insolvency, user information would be one of the assets transferred or acquired by a third party. You acknowledge that such transfers may occur and that any acquirer of us or our assets may continue to use your personal information as set out in this Privacy Policy.
      </p>
      <p>
        We use analytics tools (including but not limited to Google analytics) to collect details of how you use the EasyTip Platform, as well as anonymous data that you enter into our forms. We do this to help us analyse how users use the EasyTip Platform (including behaviour patterns and the tracking of visits across multiple devices). All the information that is collected is anonymous and is only used to improve the EasyTip Platform and the associated services.
      </p>
      <p>
        The profiling and automated decision-making we carry out in respect of the information we hold about you and your account is used to enable us to ensure relevant information, suggestions and recommendations for products/services are sent to you by us.
      </p>
      <p>
        We also may use a number of third parties to supply us with products and services. In such event, we will only share your information with our suppliers where it is necessary for them to provide us or you with the services that are needed.
      </p>

      <h2 id="section-7">7. WHERE IS YOUR INFORMATION STORED?</h2>
      <p>
        Your information is stored on servers and filing systems in the United Arab Emirates.
      </p>
      <p>
        The EasyTip Platform may occasionally contain links to and from the websites of third parties (for example, payment processing sites). If you follow a link to any of these websites, please note that these websites have their own terms and conditions, privacy notices and cookie notices and we do not accept any responsibility or liability for those notices or your use of those websites.
      </p>
      <p>
        Sending information via the internet is not 100% secure. We do our best to protect your information.  However, we cannot guarantee the security of any information you transmit to the EasyTip Platform, and any such transmission is made at your own risk. Once we receive your information, we employ procedures and security features aimed to prevent unauthorised access.
      </p>

      <h2 id="section-9">9. HOW DO WE PROTECT YOUR PERSONAL INFORMATION?</h2>
      <p>
        EasyTip strives to keep your data secure and protect it from unauthorised access and disclosure. We implement a variety of measures aimed at ensuring the security of your personal data on our systems.
      </p>

      <h2 id="section-10">10. HOW LONG DO WE KEEP YOUR INFORMATION FOR?</h2>
      <p>
        EasyTip only retains your information for as long as we need it.
      </p>
      <p>
        If you have an online account with us or provide information directly to us by filling in the forms on through the EasyTip Platform, we will retain your information for no longer than the number of years we are required by law to retain such information after you cease to have any active accounts with us. We retain your information for this period in order to:
      </p>
      <ul class="list-style-lower-latin">
        <li>ensure we contact you with information on products, services, suggestions, offers and recommendations relevant and appropriate to you;</li>
        <li>deal with any issues or concerns that you may have about how we handled your account;</li>
        <li>bring or defend any legal claims; and</li>
        <li>comply with regulatory audit requirements.</li>
      </ul>

      <h2 id="section-11">11. WHAT RIGHTS DO YOU HAVE?</h2>
      <p>You have a number of rights under applicable UAE data protection regulations.  These include the right to:</p>
      <ul class="list-style-lower-latin">
        <li>access your information;</li>
        <li>withdraw your consent to the processing of your information at any time;</li>
        <li>ask us to make changes to your information to ensure that it is accurate and up to date;</li>
        <li>delete or erase your information (the "right to be forgotten");</li>
        <li>stop or restrict the processing of your information;</li>
        <li>object to our processing your information;</li>
        <li>not be subject to automated decision-making; and</li>
        <li>request the transfer of some of the information we hold about you (known as data portability).</li>
      </ul>
      <p>If you would like to exercise any of your rights, please contact us at info@easytip.net. Please note that an archive copy of any information provided to us may be retained by us for our records and for audit purposes.</p>
      <p>You have the right to request a copy of the information we hold about you by sending your request in writing to us at <a href="mailto:info@easytip.net">info@easytip.net</a>.</p>
      <p>You can unsubscribe from our emails at any time by clicking on “unsubscribe” at the bottom of the email or writing to us at <a href="mailto:info@easytip.net">info@easytip.net</a>.</p>
      <p>You can typically remove or reject cookies from the Website with your browser settings. Many browsers are set to accept cookies until you change your settings. If you remove or reject our cookies, it may affect how the Website works for you.</p>

      <h2 id="section-12">12. MAKING A COMPLAINT</h2>
      <p>If you have any questions, concerns or complaints about the way we process your personal information, please contact us at <a href="mailto:info@easytip.net">info@easytip.net</a>.</p>
      <p>If you are not happy with the way we have handled your complaint or are still concerned about our handling of your personal information, you can make a complaint to the relevant privacy supervisory authority.</p>

      <h2 id="section-13">13. CHANGES TO THIS PRIVACY POLICY</h2>
      <p>EasyTip may periodically change this Privacy Policy.  If we make any substantive changes in how we collect or use personal data, we will change the content last updated date below. Other changes including grammar or spelling corrections will automatically update the timestamp at the bottom of this document.</p>
      <div class="document__gradient" />
    </div>
  </div>
</template>

<script>
export default {

  props: {
    hideTitle: Boolean,
  },
};
</script>
