export default {
  VISA: {
    paymentSystem: 'VISA',
    image: 'visa',
    icon: 'visa-icon',
    width: 25,
    height: 9,
  },
  MASTER_CARD: {
    paymentSystem: 'MASTER_CARD',
    image: 'master-card',
    icon: 'master-card-icon',
    width: 20,
    height: 13,
  },
  AMEX: {
    paymentSystem: 'AMEX',
    image: 'amex',
    icon: 'amex-icon',
    width: 16,
    height: 16,
  },
  PAYPAL: {
    paymentSystem: 'PAYPAL',
    image: 'paypal',
    icon: 'paypal-icon',
    width: 14,
    height: 16,
  },
  GIROPAY: {
    paymentSystem: 'GIROPAY',
    image: 'giropay',
    icon: 'giropay-icon',
    width: 19,
    height: 8,
  },
  GOOGLEPAY: {
    paymentSystem: 'GOOGLEPAY',
    image: 'googlepay',
    icon: 'googlepay-icon',
    width: 12,
    height: 12,
  },
  APPLEPAY: {
    paymentSystem: 'APPLEPAY',
    image: 'applepay',
    icon: 'applepay-icon',
    width: 12,
    height: 15,
  },
  MOBILE: {
    paymentSystem: 'MOBILE',
    icon: 'mobile-icon',
    width: 16,
    height: 16,
  },
  CARD: {
    paymentSystem: 'CARD',
    icon: 'card-icon',
    width: 16,
    height: 16,
  },
};
