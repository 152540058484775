<template>
  <div class="resend-code" @click="resendCode">
    <span
      v-if="errorMessage"
      :class="{
        'resend-code_active': error === '422012',
        'resend-code_expired': error === '422013',
      }"
    >
      {{ errorMessage }}
    </span>
    <span
      v-if="error !== '422012'"
      class="resend-code_underline"
      :class="{
        'resend-code_active': !showTimer,
      }"
    >
      {{ $t('form.buttons.resendVerificationCode') }}
    </span>
    <template v-if="showTimer">({{ timer }})</template>
  </div>
</template>

<script>
import Timer from '@/class/Timer';
import getErrorByCode from '@/utils/getErrorByCode';

const TIMER_DURATION = 2;

export default {
  props: {
    minutes: { type: Number, default: 1 },
    error: String,
  },
  data() {
    return {
      timer: null,
    };
  },
  computed: {
    showTimer() {
      return !!this.timer;
    },
    errorMessage() {
      if (!this.error) {
        return '';
      }

      return getErrorByCode(this.error).message;
    },
  },
  mounted() {
    this.startTimer();
  },
  beforeDestroy() {
    this.stopTimer();
  },
  methods: {
    startTimer() {
      this.timer = new Timer(this.minutes * 60 * 1000, () => {
        this.stopTimer();
      });
    },
    stopTimer() {
      if (this.timer) {
        this.timer.stop();
        this.timer = null;
      }
    },
    resendCode() {
      if (this.timer) {
        return;
      }

      this.$emit('resend');

      this.startTimer();
    },
  },
};
</script>

<style scoped>

</style>
