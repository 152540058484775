<template>
  <div class="onboarding-shared-card">
    <div class="onboarding-shared-card__title">
      {{ $t('payoutCard.shared.title') }}
    </div>
    <div
      v-html="$t('payoutCard.shared.description')"
      class="onboarding-shared-card__description"
    />
    <ui-toggle
      class="onboarding-shared-card__toggle"
      :elements="sharedCardStaffValues"
      :selected-value="isEmployerCardSelected ? 'employer-card' : 'my-card'"
      @select="handleSharedCardToggler"
    />
    <vue-bottom-sheet ref="sharedCardOnboardingTerms">
      <onboarding-shared-card-terms
        @cancel="closeTerms"
        @accept="sharedCardTermsAcceptedHandler"
      />
    </vue-bottom-sheet>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import OnboardingSharedCardTerms from '@/components/Onboarding/OnboardingSharedCardTerms.vue';

export default {
  name: 'OnboardingSharedCard',
  components: { OnboardingSharedCardTerms },

  data() {
    return {
      isEmployerCardSelected: false,
    };
  },

  computed: {
    ...mapState({
      sharedCardStaffValues: (state) => state.payouts.sharedCardStaffValues,
    }),
    ...mapGetters({
      isSharedCard: 'user/isSharedCard',
    }),
  },

  methods: {
    openTerms() {
      this.$refs.sharedCardOnboardingTerms.open();
    },
    closeTerms() {
      this.$refs.sharedCardOnboardingTerms.close();
    },
    handleSharedCardToggler(type) {
      if (type === 'employer-card') {
        this.openTerms();
      } else {
        this.isEmployerCardSelected = false;
        this.$emit('toggle-shared-card', 'removeSharedCard');
      }
    },
    sharedCardTermsAcceptedHandler() {
      this.isEmployerCardSelected = true;
      this.$emit('toggle-shared-card', 'setSharedCard');
      this.closeTerms();
    },
  },

  mounted() {
    if (this.isSharedCard) {
      this.isEmployerCardSelected = true;
    }
  },
};
</script>
