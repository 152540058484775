export const DOCUMENT_TYPES = [
  {
    name: 'Passport',
    value: 'PASSPORT',
  },
  {
    name: 'Driving License',
    value: 'DRIVER_LICENSE',
  },
  {
    name: 'ID',
    value: 'ID',
  },
];
