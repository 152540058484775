<template>
  <onboarding-step
    title="Issuing your card.."
  >
    <div class="onboarding-nym-card">
      <nym-card
        :key="nymCardRefresher"
        :loading-completed="isCardCreated"
        :loading-duration="INITIAL_CARD_ACTIVATION_POLLING_DURATION"
        is-loading
        class="onboarding-nym-card__card"
      />
      <ui-card class="onboarding-nym-card__list">
        <div
          v-for="step in progressSteps"
          :key="step"
          class="onboarding-nym-card__list-element"
        >
          {{ step }}
          <ui-icon
            name="onboarding-check"
            size="24px"
            class="onboarding-checklist__icon"
          />
        </div>
      </ui-card>
    </div>
    <vue-bottom-sheet
      :click-to-close="false"
      :swipe-able="false"
      ref="vbsNymCardPolling"
    >
      <bottom-sheet-polling>
        The process is in progress<br/>
        Please wait a minute..
      </bottom-sheet-polling>
    </vue-bottom-sheet>
    <vue-bottom-sheet
      :click-to-close="false"
      :swipe-able="false"
      ref="vbsNymCardSuccess"
    >
      <bottom-sheet-success
        @submit="nextStepHandler"
      >
        Card has been created successfully
      </bottom-sheet-success>
    </vue-bottom-sheet>
    <vue-bottom-sheet
      :click-to-close="false"
      :swipe-able="false"
      ref="vbsNymCardError"
    >
      <bottom-sheet-confirmation
        @confirm="tryAgainHandler"
      >
        Operation failed.<br/>
        Please confirm to try again
      </bottom-sheet-confirmation>
    </vue-bottom-sheet>
  </onboarding-step>
</template>

<script>
import * as Sentry from '@sentry/vue';
import { mapState } from 'vuex';
import OnboardingStep from '@/components/Onboarding/OnboardingStep.vue';
import NymCard from '@/components/Nym/NymCard.vue';
import BottomSheetPolling from '@/components/BottomSheet/BottomSheetPolling.vue';
import BottomSheetSuccess from '@/components/BottomSheet/BottomSheetSuccess.vue';
import BottomSheetConfirmation from '@/components/BottomSheet/BottomSheetConfirmation.vue';
import api from '@/api';

const INITIAL_CARD_ACTIVATION_POLLING_DURATION = 120;

export default {
  name: 'OnboardingNymCard',

  components: {
    BottomSheetSuccess,
    BottomSheetPolling,
    NymCard,
    OnboardingStep,
    BottomSheetConfirmation,
  },

  data() {
    return {
      nymCardRefresher: false,
      isCardCreated: false,
      progressSteps: [
        'Instant payout from EasyTip account',
        'Apple and Google Pay ready',
        'Internet payments',
      ],
      cardActivationPollingDuration: INITIAL_CARD_ACTIVATION_POLLING_DURATION,
      INITIAL_CARD_ACTIVATION_POLLING_DURATION,
    };
  },

  computed: {
    ...mapState({
      profile: (state) => state.user.profile,
    }),
  },

  methods: {
    cardCreatedHandler() {
      this.$refs.vbsNymCardPolling.close();
      this.cardActivationPollingDuration = INITIAL_CARD_ACTIVATION_POLLING_DURATION;
      this.$refs.vbsNymCardSuccess.open();
      this.isCardCreated = true;
    },
    openErrorHandler() {
      this.$refs.vbsNymCardPolling.close();
      this.cardActivationPollingDuration = INITIAL_CARD_ACTIVATION_POLLING_DURATION;
      this.$refs.vbsNymCardError.open();
    },
    async cardStatusPolling() {
      try {
        const { data } = await api.nymCard.getNymCards({
          userId: this.profile.id,
        });
        const atLeastOneActiveCard = data.some((card) => card.status === 'ACTIVE');
        if (atLeastOneActiveCard) {
          this.cardCreatedHandler();
        } else {
          this.cardActivationPollingDuration -= 1;
          if (this.cardActivationPollingDuration > 0) {
            setTimeout(this.cardStatusPolling, 1000);
          } else {
            this.openErrorHandler();
          }
        }
      } catch (e) {
        this.openErrorHandler();

        Sentry.captureException(e, {
          tags: {
            section: 'onboarding',
          },
        });
      }
    },
    startCardStatusPolling() {
      this.$refs.vbsNymCardPolling.open();
      this.cardStatusPolling();
    },
    tryAgainHandler() {
      this.$refs.vbsNymCardError.close();
      this.$refs.vbsNymCardPolling.open();
      this.nymCardRefresher = !this.nymCardRefresher;
      this.startCardStatusPolling();
    },
    nextStepHandler() {
      this.$refs.vbsNymCardSuccess.close();
      this.$emit('next-step');
    },
  },

  mounted() {
    this.startCardStatusPolling();
  },
};
</script>
