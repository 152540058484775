<template>
  <div class="onboarding-card-status">
    <div class="onboarding-card-status__content">
      <div class="onboarding-card-status__icon">
        <ui-icon
          :name="currentStatusData.icon"
          :size="currentStatusData.iconSize"
        />
      </div>
      <div class="onboarding-card-status__text">
        {{ currentStatusData.message }}
      </div>
    </div>
    <div class="onboarding-card-status__actions">
      <template v-if="needDifferentCard && selectedCardType">
        <new-button
          wide
          @click="updateAnotherCardHandler"
        >
          Add {{ selectedCardType === CARD_TYPES.CORPORATE ? 'Personal card' : 'Company card' }}
        </new-button>
        <new-button
          type="ghost"
          wide
          @click="$emit('next')"
        >
          Skip
        </new-button>
      </template>
      <new-button
        v-else
        wide
        @click="$emit('next')"
      >
        Next
      </new-button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import CARD_TYPES from '@/config/cardTypes';

export default {
  name: 'OnboardingCardStatus',

  props: {
    status: {
      type: String,
      required: true,
    },
    needDifferentCard: Boolean,
  },

  data() {
    return {
      CARD_TYPES,
    };
  },

  computed: {
    ...mapState({
      selectedCardType: (state) => state.payouts.selectedCardType,
    }),
    statusesData() {
      return [
        {
          status: 'ACTIVE',
          type: 'success',
          icon: 'check',
          iconSize: '14px',
          message: this.$t('payoutCard.active.message'),
        },
        {
          status: 'PENDING',
          type: 'warning',
          icon: 'card-status-warning',
          iconSize: '20px',
          message: this.$t('payoutCard.pending.message'),
        },
        {
          status: 'PENDING_VERIFICATION',
          type: 'warning',
          icon: 'card-status-warning',
          iconSize: '20px',
          message: this.$t('payoutCard.pending-verification.message'),
        },
        {
          status: 'BLOCKED',
          type: 'error',
          icon: 'card-status-error',
          iconSize: '20px',
          message: this.$t('payoutCard.invalid.message'),
        },
      ];
    },
    currentStatusData() {
      return this.statusesData.find(({ status }) => status === this.status);
    },
  },

  methods: {
    ...mapActions('payouts', [
      'setSelectedCardType',
    ]),
    updateAnotherCardHandler() {
      this.setSelectedCardType(Object.values(CARD_TYPES).find((type) => type !== this.selectedCardType));
      this.$emit('close');
    },
  },
};
</script>
