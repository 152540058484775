<template>
  <div class="skip-card-filling-confirm">
    <div class="skip-card-filling-confirm__text">
      Do you want to skip the card data entry?<br/>
      We won't be able to process payouts without your card details.
    </div>
    <new-button
      class="skip-card-filling-confirm__skip-btn"
      @click="$emit('skip')"
    >
      Skip
    </new-button>
    <new-button
      class="skip-card-filling-confirm__cancel-btn"
      type="secondary"
      @click="$emit('cancel')"
    >
      Cancel
    </new-button>
  </div>
</template>

<script>
export default {
  name: 'SkipCardFillingConfirm',
};
</script>
