export const PAYMENT_SYSTEM = {
  CHECKOUT_UAE: 'CHECKOUT_UAE',
  CHECKOUT: 'CHECKOUT',
  CHECKOUT_UK_DASHBOARD: 'CHECKOUT_UK_DASHBOARD',
  STRIPE: 'STRIPE',
  PAYER_MAX: 'PAYER_MAX',
  RYFT: 'RYFT',
};

export const PAYMENT_SYSTEM_LABEL = {
  [PAYMENT_SYSTEM.CHECKOUT]: 'Checkout UK',
  [PAYMENT_SYSTEM.CHECKOUT_UAE]: 'Checkout UAE',
  [PAYMENT_SYSTEM.CHECKOUT_UK_DASHBOARD]: 'Checkout UK Dashboard',
  [PAYMENT_SYSTEM.STRIPE]: 'Stripe',
  [PAYMENT_SYSTEM.PAYER_MAX]: 'PayerMax',
  [PAYMENT_SYSTEM.RYFT]: 'Ryft',
};

export const PAYMENT_SYSTEMS = [
  {
    name: PAYMENT_SYSTEM_LABEL[PAYMENT_SYSTEM.CHECKOUT_UAE],
    value: PAYMENT_SYSTEM.CHECKOUT_UAE,
    countries: ['ae'],
    attributes: {
      paymentSystem: PAYMENT_SYSTEM.CHECKOUT_UAE,
      payoutPricePlanType: 'BY_PERIOD',
    },
    disabled: false,
  },
  {
    name: PAYMENT_SYSTEM_LABEL[PAYMENT_SYSTEM.CHECKOUT],
    value: PAYMENT_SYSTEM.CHECKOUT,
    countries: ['gb', 'de', 'ae'],
    attributes: {
      paymentSystem: PAYMENT_SYSTEM.CHECKOUT,
      payoutPricePlanType: 'BY_PERIOD',
    },
    disabled: true,
  },
  {
    name: PAYMENT_SYSTEM_LABEL[PAYMENT_SYSTEM.CHECKOUT_UK_DASHBOARD],
    value: PAYMENT_SYSTEM.CHECKOUT_UK_DASHBOARD,
    countries: ['gb', 'de', 'ae'],
    attributes: {
      paymentSystem: PAYMENT_SYSTEM.CHECKOUT_UK_DASHBOARD,
      payoutPricePlanType: null,
    },
    disabled: false,
  },
  {
    name: PAYMENT_SYSTEM_LABEL[PAYMENT_SYSTEM.STRIPE],
    value: PAYMENT_SYSTEM.STRIPE,
    countries: ['gb', 'de'],
    attributes: {
      paymentSystem: PAYMENT_SYSTEM.STRIPE,
      payoutPricePlanType: null,
    },
    disabled: false,
  },
  {
    name: PAYMENT_SYSTEM_LABEL[PAYMENT_SYSTEM.PAYER_MAX],
    value: PAYMENT_SYSTEM.PAYER_MAX,
    countries: ['ae'],
    attributes: {
      paymentSystem: PAYMENT_SYSTEM.PAYER_MAX,
      payoutPricePlanType: 'BY_PERIOD',
    },
  },
  {
    name: PAYMENT_SYSTEM_LABEL[PAYMENT_SYSTEM.RYFT],
    value: PAYMENT_SYSTEM.RYFT,
    countries: ['gb'],
    attributes: {
      paymentSystem: PAYMENT_SYSTEM.RYFT,
      payoutPricePlanType: null,
    },
  },
];

export const PAYMENT_SYSTEMS_REFUND = [PAYMENT_SYSTEM.CHECKOUT, PAYMENT_SYSTEM.CHECKOUT_UAE, PAYMENT_SYSTEM.PAYER_MAX];
export const PAYMENT_SYSTEMS_PLANS = [PAYMENT_SYSTEM.CHECKOUT, PAYMENT_SYSTEM.CHECKOUT_UAE, PAYMENT_SYSTEM.PAYER_MAX];
export const PAYMENT_SYSTEMS_REDIRECT_REPORTING = [PAYMENT_SYSTEM.CHECKOUT, PAYMENT_SYSTEM.CHECKOUT_UAE, PAYMENT_SYSTEM.PAYER_MAX];
export const CHECKOUT_PAYMENT_SYSTEM_LIST = [PAYMENT_SYSTEM.CHECKOUT, PAYMENT_SYSTEM.CHECKOUT_UAE];
export const DEFAULT_PAYMENT_SYSTEM = PAYMENT_SYSTEMS[0];
