<template>
  <onboarding-step
    title="Payout card"
    :description="stepDescription"
  >
    <template v-if="showSharedCardSwitcher">
      <div class="onboarding-payout-card">
        <onboarding-shared-card
          @toggle-shared-card="toggleSharedCard"
        />
      </div>
      <new-button
        type="primary"
        class="onboarding-payout-card__next-btn"
        @click="toPayoutCardForm"
      >
        Next
      </new-button>
    </template>
    <div v-else class="onboarding-payout-card">
      <checkout-card-info
        :user-id="profile.id"
        onboarding
        @confirmed="cardAddedHandler"
        @skip="openSkipCardFillingConfirm"
      />
      <new-button
        type="ghost"
        class="onboarding-payout-card__skip-btn"
        @click="openSkipCardFillingConfirm"
      >
        Skip
      </new-button>
    </div>
    <vue-bottom-sheet ref="skipCardFillingConfirm">
      <skip-card-filling-confirm
        @skip="cancelOnboardingHandler"
        @cancel="closeSkipCardFillingConfirm"
      />
    </vue-bottom-sheet>
    <vue-bottom-sheet
      :click-to-close="canCloseStatusBottomSheet"
      :swipe-able="canCloseStatusBottomSheet"
      ref="onboardingCardStatus"
    >
      <onboarding-card-status
        :need-different-card="needDifferentCard"
        :status="cardStatus"
        @next="nextStepHandler"
        @close="closeStatusHandler"
      />
    </vue-bottom-sheet>
    <vue-bottom-sheet
      :click-to-close="false"
      :swipe-able="false"
      ref="cardStatusPolling"
    >
      <bottom-sheet-polling>
        We are verifying your card details.<br/>
        Please wait a minute..
      </bottom-sheet-polling>
    </vue-bottom-sheet>
  </onboarding-step>
</template>

<script>
import * as Sentry from '@sentry/vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import api from '@/api/index';
import OnboardingStep from '@/components/Onboarding/OnboardingStep.vue';
import CheckoutCardInfo from '@/components/Checkout/CheckoutCardInfo.vue';
import BottomSheetPolling from '@/components/BottomSheet/BottomSheetPolling.vue';
import SkipCardFillingConfirm from '@/components/Onboarding/SkipCardFillingConfirm.vue';
import OnboardingCardStatus from '@/components/Onboarding/OnboardingCardStatus.vue';
import OnboardingSharedCard from '@/components/Onboarding/OnboardingSharedCard.vue';
import CARD_TYPES from '@/config/cardTypes';

const PENDING_STATUS = 'PENDING';
const INITIAL_POLLING_COUNT = 20;

const STEP_DESCRIPTION = 'We send payouts directly to your Visa or Mastercard. This makes payouts instant. Just enter your debit or credit card details and we will store them safely and securely.';

export default {
  name: 'OnboardingPayoutCard',

  components: {
    OnboardingSharedCard,
    BottomSheetPolling,
    OnboardingCardStatus,
    SkipCardFillingConfirm,
    CheckoutCardInfo,
    OnboardingStep,
  },

  data() {
    return {
      myCardSelected: false,
      pollingCounter: INITIAL_POLLING_COUNT,
      cardStatus: PENDING_STATUS,
      needDifferentCard: false,
    };
  },

  computed: {
    ...mapState({
      profile: (state) => state.user.profile,
      location: (state) => state.user.location,
      selectedCardType: (state) => state.payouts.selectedCardType,
    }),
    ...mapGetters({
      isAllowedSharedCard: 'user/isAllowedSharedCard',
      isSharedCard: 'user/isSharedCard',
      hasDifferentCardTypes: 'payouts/hasDifferentCardTypes',
    }),
    showSharedCardSwitcher() {
      return this.isAllowedSharedCard && !this.myCardSelected;
    },
    stepDescription() {
      return this.showSharedCardSwitcher ? '' : STEP_DESCRIPTION;
    },
    canCloseStatusBottomSheet() {
      return this.cardStatus !== 'ACTIVE';
    },
  },

  methods: {
    ...mapActions({
      initPayouts: 'payouts/initPayouts',
      loadUser: 'user/loadUser',
      setCurrentCard: 'payouts/setCurrentCard',
    }),
    async toggleSharedCard(method) {
      try {
        await api.checkout[method](this.profile.id);

        this.initPayouts();
        this.loadUser();
      } catch (e) {
        this.$showServerError(e);

        Sentry.captureException(e, {
          tags: {
            section: 'onboarding',
          },
        });
      }
    },
    async cardStatusPolling() {
      try {
        const method = this.selectedCardType === CARD_TYPES.CORPORATE
          ? 'getCorporateCard'
          : 'getPersonalCard';
        const request = this.selectedCardType === CARD_TYPES.CORPORATE
          ? this.location.id
          : this.profile.id;

        const { data } = await api.checkout[method](request);
        this.cardStatus = data.status;
        await this.setCurrentCard(data);
        if (data.status === PENDING_STATUS && this.pollingCounter) {
          this.pollingCounter -= 1;
          setTimeout(this.cardStatusPolling, 1000);
        } else {
          this.cancelPolling();
        }
      } catch (e) {
        this.cancelPolling();
      }
    },
    async checkNeedDifferentCard() {
      try {
        const method = this.selectedCardType === CARD_TYPES.CORPORATE
          ? 'getPersonalCard'
          : 'getCorporateCard';
        const request = this.selectedCardType === CARD_TYPES.CORPORATE
          ? this.profile.id
          : this.location.id;

        const { data } = await api.checkout[method](request);
        this.needDifferentCard = !data;
      } catch (e) {
        this.needDifferentCard = e?.response?.status === 404;
      }
    },
    openSkipCardFillingConfirm() {
      this.$refs.skipCardFillingConfirm.open();
    },
    closeSkipCardFillingConfirm() {
      this.$refs.skipCardFillingConfirm.close();
    },
    cardAddedHandler() {
      this.$refs.cardStatusPolling.open();
      this.cardStatusPolling();
    },
    async cancelPolling() {
      if (this.hasDifferentCardTypes) {
        await this.checkNeedDifferentCard();
      }
      this.$nextTick(() => {
        this.$refs.cardStatusPolling.close();
        this.pollingCounter = INITIAL_POLLING_COUNT;
        this.$refs.onboardingCardStatus.open();
      });
    },
    cancelOnboardingHandler() {
      this.closeSkipCardFillingConfirm();
      this.$emit('cancel-onboarding');
    },
    closeStatusHandler() {
      this.needDifferentCard = false;
      this.$refs.onboardingCardStatus.close();
    },
    nextStepHandler() {
      this.closeStatusHandler();
      this.$emit('next-step');
    },
    toPayoutCardForm() {
      if (this.isSharedCard) {
        this.$emit('cancel-onboarding');
      } else {
        this.myCardSelected = true;
      }
    },
  },
};
</script>
