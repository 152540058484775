<template>
  <div class="onboarding-shared-card-terms">
    <div class="onboarding-shared-card-terms__title">
      {{ $t('sharedCard.staffTerms.title') }}
    </div>
    <div class="onboarding-shared-card-terms__notice">
      {{ $t('sharedCard.staffTerms.notice') }}
    </div>
    <div class="onboarding-shared-card-terms__agreement">
      <p
        v-for="(agreement, index) in $t('sharedCard.staffTerms.agreements').length"
        :key="agreement"
      >
        {{ $t(`sharedCard.staffTerms.agreements[${index}]`) }}
      </p>
    </div>
    <div class="onboarding-shared-card-terms__actions">
      <new-button
        type="secondary"
        size="sm"
        @click="$emit('cancel')"
      >
        Cancel
      </new-button>
      <new-button
        size="sm"
        @click="$emit('accept')"
      >
        Accept
      </new-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OnboardingSharedCardTerms',
};
</script>
