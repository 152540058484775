import store from '@/store';

const protectedLogic = (el) => {
  if (['DIV', 'SPAN'].includes(el.tagName)) {
    // Disable all input fields
    if (el.classList.contains('form-group')) {
      el.classList.add('is-disabled');
    }

    // Disable avatar
    if (el.classList.contains('picture-container')) {
      el.style['pointer-events'] = 'none';
    }

    // Disable all payouts plans [BY_PERIOD]
    if (el.classList.contains('period-plan')) {
      el.classList.add('period-plan_disabled');
    }

    // Disable all payouts plans [BY_AMOUNT]
    if (el.classList.contains('radio-card-group')) {
      el.childNodes.forEach((item) => {
        item.style['pointer-events'] = 'none';
        item.childNodes[0].classList.add('radio-card_disabled');
      });
    }

    // Disable all reviews icons
    if (el.classList.contains('review__item')) {
      el.setAttribute('disabled', true);
    }

    // Disable all calendar-period days
    if (el.classList.contains('ui-calendar__range')) {
      el.setAttribute('disabled', true);
    }

    // Disable alerts checkboxes
    if (el.classList.contains('form-check')) {
      el.setAttribute('disabled', true);
    }

    // Disable menu files
    if (el.classList.contains('menu-section-file')) {
      el.setAttribute('disabled', true);
    }

    // Disable toggler
    if (el.classList.contains('toggle')) {
      el.classList.add('disabled');
    }

    // Disable IG create post/picture buttons
    if (el.classList.contains('last-reviews__link')) {
      el.setAttribute('disabled', true);
    }

    // Disable modals
    if (el.hasAttribute('show')) {
      el.style.display = 'none';
    }

    el.querySelectorAll('*').forEach((item) => {
      // Disable payouts checkout card labels
      if (item.classList.contains('checkout-card-info__frame-input-label')) {
        item.style.color = '#ccc';
        item.style['-webkit-text-fill-color'] = '#ccc';
      }
      // Disable payouts checkout card inputs
      if (item.classList.contains('checkout-card-info__frame-input')) {
        item.style['pointer-events'] = 'none';
      }

      // Disable all children
      item.disabled = true;
    });
  } else if (el.tagName === 'BUTTON') {
    // Disable all buttons
    el.disabled = true;
  } else if (el.tagName === 'LI') {
    // Disable notifications list item
    el.classList.add('disabled');

    // Disable table actions dropdown
    if (el.classList.contains('dropdown')) {
      el.style['pointer-events'] = 'none';
    }
  } else if (el.tagName === 'UL') {
    // Disable menu dragging
    if (el.classList.contains('smooth-dnd-container')) {
      el.style['pointer-events'] = 'none';
    }
  } else if (el.tagName === 'LABEL') {
    // Disable menu toggle
    if (el.classList.contains('ui-switch-wrapper')) {
      el.style['pointer-events'] = 'none';
      el.childNodes[0].classList.add('ui-switch_disabled');
    }
  }
};

export default {
  bind(el, binding, vnode) {
    const isReadonly = store.getters['auth/isReadOnly'];

    if (isReadonly) {
      vnode.context.$root.$nextTick(() => {
        protectedLogic(el);
      });
    }
  },
  update(el, binding, vnode) {
    const isReadonly = store.getters['auth/isReadOnly'];

    if (isReadonly) {
      vnode.context.$root.$nextTick(() => {
        protectedLogic(el);
      });
    }
  },
};
