export default {
  default: {
    description: 'Unknown Error - Our support team is notified about the problem.',
    type: 'danger',
  },
  422012: {
    description: 'Invalid code. Please try again.',
    type: 'danger',
  },
  422013: {
    description: 'Code expired.',
    type: 'danger',
  },
  422010: {
    description: 'Payment account is fully activated',
    type: 'info',
  },
  4000006: {
    description: 'Your account is not active, please contact to support',
    type: 'success',
  },
  409024: {
    description: 'Group with the same name already exists',
    type: 'danger',
  },
  422006: {
    description: 'This ID is being used. Please choose another ID',
    type: 'danger',
  },
  404002: {
    description: 'There is no user with this phone number {phoneNumber} in EasyTip',
    type: 'info',
  },
  409031: {
    description: 'You can\'t calculate tips distribution, because the percentages aren\'t set. Please contact your EasyTip manager to fix it!',
    type: 'danger',
  },
  409032: {
    description: 'We can\'t calculate tips distribution, because there is no percentages for some of the groups. Please set and try again!',
    type: 'danger',
  },
  409029: {
    description: 'You can\'t change Tax reporting type field if the stuff member already has transactions. If you need to make changes, please contact your EasyTip manager or technical support.',
    type: 'danger',
  },
  422007: {
    description: 'Email already exists',
    type: 'danger',
  },
  409001: {
    description: 'Location name already exists',
    type: 'danger',
  },
  404003: {
    description: 'Operation is not applicable. User has not exceeded the number of OTP attempts.',
    type: 'danger',
  },
};
