<template>
  <div class="onboarding-main-information-required-error">
    <div class="onboarding-main-information-required-error__text">
      {{ $t('alerts.requiredModal.message') }}
    </div>
    <new-button @click="$emit('submit')">
      {{ $t('form.buttons.ok') }}
    </new-button>
  </div>
</template>

<script>
export default {
  name: 'OnboardingMainInformationRequiredError',
};
</script>
