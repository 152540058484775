<template>
  <onboarding-step
    v-if="showManagePayout"
    title="Bank account"
    description="Please add your bank account details to Stripe - our payment processing partner. The tips you collect will be sent to your bank account directly."
  >
    <div class="onboarding-stripe-card">
      <user-stripe
        is-onboarding
      />
    </div>
  </onboarding-step>
</template>

<script>
import { mapState } from 'vuex';
import OnboardingStep from '@/components/Onboarding/OnboardingStep.vue';
import UserStripe from '@/components/User/UserStripe.vue';

export default {
  name: 'OnboardingStripeCard',

  components: {
    UserStripe,
    OnboardingStep,
  },

  computed: {
    ...mapState('user', [
      'showManagePayout',
    ]),
  },

  created() {
    if (!this.showManagePayout) {
      this.$emit('next-step');
    }
  },
};
</script>
