<template>
  <div class="onboarding-main-information-error">
    <div class="onboarding-main-information-error__info">
      <ui-icon name="card-status-error" size="18px" />
      <span class="onboarding-main-information-error__text">
          {{ errorText }}
      </span>
    </div>
    <new-button
      :is-loading="formLoading"
      class="onboarding-main-information-error__resend-btn"
      @click="$emit('try-again')"
    >
      Try again
    </new-button>
    <new-button
      type="ghost"
      class="onboarding-main-information-error__exit-btn"
      @click="$emit('exit')"
    >
      Exit
    </new-button>
  </div>
</template>

<script>
import getErrorByCode from '@/utils/getErrorByCode';

export default {
  name: 'OnboardingMainInformationError',

  props: {
    errorCode: {
      type: Number,
      default: 0,
    },
    formLoading: Boolean,
  },

  computed: {
    errorText() {
      if (this.errorCode) {
        return getErrorByCode(this.errorCode).message;
      }
      return 'Can’t save form data. Please try again later';
    },
  },
};
</script>
