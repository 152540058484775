<template>
  <onboarding-step
    :title="currentStepConfig.title"
    :description="currentStepConfig.description"
  >
    <ui-card class="onboarding-form__card">
      <validation-observer v-slot="{ validate }">
        <div class="onboarding-form">
          <div class="onboarding-form__card">
            <template v-if="currentStep === steps.PERSONAL_INFORMATION">
              <validation-provider
                tag="div"
                :name="$t('onboarding.form.nationality')"
                rules="required"
                mode="passive"
                v-slot="{ errors }"
              >
                <ui-select
                  v-model="form.nationality"
                  :items="countries"
                  :label="$t('onboarding.form.nationality')"
                  :placeholder="$t('form.placeholders.select')"
                  label-key="name"
                  value-key="isoCode"
                  filterable
                  required
                  :error="errors[0]"
                />
              </validation-provider>
              <validation-provider
                tag="div"
                :name="$t('onboarding.form.countryOfBirth')"
                rules="required"
                mode="passive"
                v-slot="{ errors }"
              >
                <ui-select
                  v-model="form.countryOfBirth"
                  :items="countries"
                  :label="$t('onboarding.form.countryOfBirth')"
                  :placeholder="$t('form.placeholders.select')"
                  label-key="name"
                  value-key="isoCode"
                  filterable
                  required
                  :error="errors[0]"
                />
              </validation-provider>
              <validation-provider
                tag="div"
                :name="$t('onboarding.form.gender')"
                rules="required"
                mode="passive"
                v-slot="{ errors }"
              >
                <ui-select
                  v-model="form.gender"
                  :items="GENDERS"
                  :label="$t('onboarding.form.gender')"
                  :placeholder="$t('form.placeholders.select')"
                  filterable
                  required
                  :error="errors[0]"
                />
              </validation-provider>
              <div class="onboarding-form__title">
                {{ $t('onboarding.form.title.nameOnCard') }}
              </div>
              <validation-provider
                tag="div"
                :name="$t('onboarding.form.nameLabel')"
                rules="required|cardholderName"
                mode="passive"
                v-slot="{ errors }"
              >
                <ui-input
                  v-model="form.nameOnCard"
                  :label="$t('onboarding.form.nameLabel')"
                  minlength="3"
                  required
                  uppercase
                  :error="errors[0]"
                />
              </validation-provider>
              <div class="onboarding-form__title">
                {{ $t('onboarding.form.title.addressOfResidence') }}
              </div>
              <validation-provider
                tag="div"
                :name="$t('onboarding.form.residenceAddress')"
                rules="required"
                mode="passive"
                v-slot="{ errors }"
              >
                <ui-input
                  v-model="form.addressOfResidence.streetAddress"
                  :label="$t('onboarding.form.residenceAddress')"
                  required
                  :error="errors[0]"
                />
              </validation-provider>
              <validation-provider
                tag="div"
                :name="$t('onboarding.form.residenceCity')"
                rules="required|min:2"
                mode="passive"
                v-slot="{ errors }"
              >
                <ui-input
                  v-model="form.addressOfResidence.city"
                  :label="$t('onboarding.form.residenceCity')"
                  minlength="2"
                  required
                  :error="errors[0]"
                />
              </validation-provider>
              <validation-provider
                tag="div"
                :name="$t('onboarding.form.residenceCountry')"
                rules="required"
                mode="passive"
                v-slot="{ errors }"
              >
                <ui-select
                  v-model="form.addressOfResidence.country"
                  :items="countries"
                  :label="$t('onboarding.form.residenceCountry')"
                  :placeholder="$t('form.placeholders.select')"
                  label-key="name"
                  value-key="isoCode"
                  filterable
                  required
                  :error="errors[0]"
                />
              </validation-provider>
            </template>
            <template v-else>
              <validation-provider
                tag="div"
                :name="$t('onboarding.form.employmentStatus')"
                :key="$t('onboarding.form.employmentStatus')"
                rules="required"
                mode="passive"
                v-slot="{ errors }"
              >
                <ui-select
                  v-model="form.employmentStatus"
                  :items="EMPLOYMENT_STATUSES"
                  :label="$t('onboarding.form.employmentStatus')"
                  :placeholder="$t('form.placeholders.select')"
                  filterable
                  required
                  :error="errors[0]"
                />
              </validation-provider>
              <validation-provider
                tag="div"
                :name="$t('onboarding.form.companyName')"
                :key="$t('onboarding.form.companyName')"
                rules="required"
                mode="passive"
                v-slot="{ errors }"
              >
                <ui-input
                  v-model="form.companyName"
                  :label="$t('onboarding.form.companyName')"
                  required
                  :error="errors[0]"
                />
              </validation-provider>
              <validation-provider
                tag="div"
                :name="$t('onboarding.form.industry')"
                :key="$t('onboarding.form.industry')"
                rules="required"
                mode="passive"
                v-slot="{ errors }"
              >
                <ui-select
                  v-model="form.industry"
                  :items="INDUSTRIES"
                  :label="$t('onboarding.form.industry')"
                  :placeholder="$t('form.placeholders.select')"
                  filterable
                  required
                  :error="errors[0]"
                />
              </validation-provider>
              <validation-provider
                tag="div"
                :name="$t('onboarding.form.jobTitle')"
                :key="$t('onboarding.form.jobTitle')"
                rules="required"
                mode="passive"
                v-slot="{ errors }"
              >
                <ui-input
                  v-model="form.jobTitle"
                  :label="$t('onboarding.form.jobTitle')"
                  required
                  :error="errors[0]"
                />
              </validation-provider>
              <validation-provider
                tag="div"
                :name="$t('onboarding.form.salary')"
                :key="$t('onboarding.form.salary')"
                rules="required|decimal|min_value:1"
                mode="passive"
                v-slot="{ errors }"
              >
                <ui-input
                  v-model.number="form.salary.amount"
                  :label="`${$t('onboarding.form.salary')} ${currency ? ` (${currency})` : ''}`"
                  required
                  :error="errors[0]"
                />
              </validation-provider>
              <div class="onboarding-form__title">
                {{ $t('onboarding.form.title.employersAddress') }}
              </div>
              <validation-provider
                tag="div"
                :name="$t('onboarding.form.employersAddress')"
                :key="$t('onboarding.form.employersAddress')"
                rules="required"
                mode="passive"
                v-slot="{ errors }"
              >
                <ui-input
                  v-model="form.addressOfEmployer.streetAddress"
                  :label="$t('onboarding.form.employersAddress')"
                  required
                  :error="errors[0]"
                />
              </validation-provider>
              <validation-provider
                tag="div"
                :name="$t('onboarding.form.employersCity')"
                :key="$t('onboarding.form.employersCity')"
                rules="required"
                mode="passive"
                v-slot="{ errors }"
              >
                <ui-input
                  v-model="form.addressOfEmployer.city"
                  :label="$t('onboarding.form.employersCity')"
                  required
                  :error="errors[0]"
                />
              </validation-provider>
              <validation-provider
                tag="div"
                :name="$t('onboarding.form.employersCountry')"
                :key="$t('onboarding.form.employersCountry')"
                rules="required"
                mode="passive"
                v-slot="{ errors }"
              >
                <ui-select
                  v-model="form.addressOfEmployer.country"
                  :items="countries"
                  :label="$t('onboarding.form.employersCountry')"
                  :placeholder="$t('form.placeholders.select')"
                  label-key="name"
                  value-key="isoCode"
                  filterable
                  required
                  :error="errors[0]"
                />
              </validation-provider>
            </template>
            <new-button
              :is-loading="loading"
              :disabled="loading"
              class="onboarding-form__submit-btn"
              @click.prevent="send(validate)"
            >
              Next
            </new-button>
          </div>
        </div>
        <vue-bottom-sheet ref="vbsKycError">
          <bottom-sheet-confirmation
            @confirm="send(validate)"
          >
            Profile verification failed.<br/>
            Please confirm to try again
          </bottom-sheet-confirmation>
        </vue-bottom-sheet>
      </validation-observer>
    </ui-card>
    <vue-bottom-sheet
      :click-to-close="false"
      :swipe-able="false"
      ref="vbsReadyKycPolling"
    >
      <bottom-sheet-polling>
        Performing profile verification.<br/>
        Please wait a minute..
      </bottom-sheet-polling>
    </vue-bottom-sheet>
    <vue-bottom-sheet ref="vbsReadyKycStatusTimeout">
      <bottom-sheet-confirmation
        confirm-text="Try again"
        @confirm="tryAgainReadyKycStatusPollingHandler"
      >
        Response timed out.<br/>
        Please try again.
      </bottom-sheet-confirmation>
    </vue-bottom-sheet>
  </onboarding-step>
</template>

<script>
import * as Sentry from '@sentry/vue';
import { mapState } from 'vuex';
import api from '@/api';
import { LABELS } from '@/config/currencies';
import { EMPLOYMENT_STATUSES } from '@/config/employmentStatuses';
import { GENDERS } from '@/config/genders';
import { INDUSTRIES } from '@/config/industries';
import OnboardingStep from '@/components/Onboarding/OnboardingStep.vue';
import BottomSheetConfirmation from '@/components/BottomSheet/BottomSheetConfirmation.vue';
import { NYM_STATUSES } from '@/config/nymStatuses';
import BottomSheetPolling from '@/components/BottomSheet/BottomSheetPolling.vue';

const INITIAL_READY_KYC_STATUS_POLLING_DURATION = 120;

export default {
  components: {
    BottomSheetConfirmation,
    OnboardingStep,
    BottomSheetPolling,
  },

  props: {
    currentStep: {
      type: Number,
      required: true,
    },
    steps: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      EMPLOYMENT_STATUSES,
      INDUSTRIES,
      GENDERS,
      form: {
        addressOfEmployer: {
          city: '',
          country: '',
          streetAddress: '',
        },
        addressOfResidence: {
          city: '',
          country: '',
          streetAddress: '',
        },
        companyName: '',
        countryOfBirth: '',
        employmentStatus: '',
        industry: '',
        jobTitle: '',
        nameOnCard: '',
        nationality: '',
        gender: '',
        salary: {
          amount: '',
          currency: 'AED',
        },
      },
      countries: [],
      readyKycStatusPollingDuration: INITIAL_READY_KYC_STATUS_POLLING_DURATION,
    };
  },
  computed: {
    ...mapState('user', [
      'profile',
      'location',
    ]),
    currentStepConfig() {
      if (this.currentStep === this.steps.PERSONAL_INFORMATION) {
        return {
          title: this.$t('onboarding.personalInformation.title'),
          description: this.$t('onboarding.personalInformation.description'),
        };
      }
      return {
        title: this.$t('onboarding.employerInformation.title'),
        description: this.$t('onboarding.employerInformation.description'),
      };
    },
    currency() {
      return LABELS[this.location.currencyCode];
    },
    isAllPersonalInformationFilled() {
      return this.form.nationality
        && this.form.countryOfBirth
        && this.form.gender
        && this.form.nameOnCard
        && this.form.addressOfResidence.streetAddress
        && this.form.addressOfResidence.city
        && this.form.addressOfResidence.country;
    },
  },
  methods: {
    stopReadyKYCStatusPolling(error = null) {
      this.readyKycStatusPollingDuration = INITIAL_READY_KYC_STATUS_POLLING_DURATION;
      this.$refs.vbsReadyKycPolling.close();
      if (error instanceof Error) {
        this.$showServerError(error);
      } else if (error) {
        this.$refs.vbsReadyKycStatusTimeout.open();
      }
    },
    async runKycHandler() {
      try {
        await api.nymCard.nymCardRunKyc({
          userId: this.profile.id,
        });
        this.$emit('next-step');
      } catch (e) {
        this.$showServerError(e);

        Sentry.captureException(e, {
          tags: {
            section: 'onboarding',
          },
        });
      } finally {
        this.stopReadyKYCStatusPolling();
      }
    },
    async startReadyKYCStatusPolling() {
      try {
        const { data } = await api.nymCard.getUserStatusFromNym({ userId: this.profile.id });
        if (data.code) {
          this.stopReadyKYCStatusPolling();
          this.$emit('error', data);
        } else if (data.status === NYM_STATUSES.PENDINGKYC) {
          await this.runKycHandler();
        } else {
          this.readyKycStatusPollingDuration -= 1;
          if (this.readyKycStatusPollingDuration > 0) {
            setTimeout(this.startReadyKYCStatusPolling, 1000);
          } else {
            this.stopReadyKYCStatusPolling(true);
          }
        }
      } catch (e) {
        this.stopReadyKYCStatusPolling(e);

        Sentry.captureException(e, {
          tags: {
            section: 'onboarding',
          },
        });
      }
    },
    tryAgainReadyKycStatusPollingHandler() {
      this.$refs.vbsReadyKycStatusTimeout.close();
      this.$refs.vbsReadyKycPolling.open();
      this.startReadyKYCStatusPolling();
    },
    async send(validate) {
      this.$refs.vbsKycError.close();
      if (this.currentStep === this.steps.PERSONAL_INFORMATION) {
        if (!this.isAllPersonalInformationFilled) {
          await validate();
        } else {
          this.$emit('next-step');
        }
        return;
      }

      if (!this.isAllPersonalInformationFilled) {
        await this.$emit('previous-step');
        await validate();
        return;
      }

      // eslint-disable-next-line no-unreachable
      if (!await validate()) {
        console.log('error validate');

        return;
      }

      if (this.loading) {
        return;
      }

      this.loading = true;

      try {
        await api.nymCard.nymCardSendKycData({
          userId: this.profile.id,
          data: this.form,
        });
        this.$refs.vbsReadyKycPolling.open();
        await this.startReadyKYCStatusPolling();
      } catch (e) {
        this.$refs.vbsKycError.open();

        Sentry.captureException(e, {
          tags: {
            section: 'onboarding',
          },
        });
      } finally {
        this.loading = false;
      }
    },
    async loadAllCountries() {
      try {
        const { data } = await api.handbook.getCountries();
        this.countries = data;
      } catch (e) {
        this.$showServerError(e);

        Sentry.captureException(e, {
          tags: {
            section: 'onboarding',
          },
        });
      }
    },
  },
  created() {
    this.loadAllCountries();
  },
};
</script>
