var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('onboarding-step',{attrs:{"title":_vm.currentStepConfig.title,"description":_vm.currentStepConfig.description}},[_c('ui-card',{staticClass:"onboarding-form__card"},[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
return [_c('div',{staticClass:"onboarding-form"},[_c('div',{staticClass:"onboarding-form__card"},[(_vm.currentStep === _vm.steps.PERSONAL_INFORMATION)?[_c('validation-provider',{attrs:{"tag":"div","name":_vm.$t('onboarding.form.nationality'),"rules":"required","mode":"passive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ui-select',{attrs:{"items":_vm.countries,"label":_vm.$t('onboarding.form.nationality'),"placeholder":_vm.$t('form.placeholders.select'),"label-key":"name","value-key":"isoCode","filterable":"","required":"","error":errors[0]},model:{value:(_vm.form.nationality),callback:function ($$v) {_vm.$set(_vm.form, "nationality", $$v)},expression:"form.nationality"}})]}}],null,true)}),_c('validation-provider',{attrs:{"tag":"div","name":_vm.$t('onboarding.form.countryOfBirth'),"rules":"required","mode":"passive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ui-select',{attrs:{"items":_vm.countries,"label":_vm.$t('onboarding.form.countryOfBirth'),"placeholder":_vm.$t('form.placeholders.select'),"label-key":"name","value-key":"isoCode","filterable":"","required":"","error":errors[0]},model:{value:(_vm.form.countryOfBirth),callback:function ($$v) {_vm.$set(_vm.form, "countryOfBirth", $$v)},expression:"form.countryOfBirth"}})]}}],null,true)}),_c('validation-provider',{attrs:{"tag":"div","name":_vm.$t('onboarding.form.gender'),"rules":"required","mode":"passive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ui-select',{attrs:{"items":_vm.GENDERS,"label":_vm.$t('onboarding.form.gender'),"placeholder":_vm.$t('form.placeholders.select'),"filterable":"","required":"","error":errors[0]},model:{value:(_vm.form.gender),callback:function ($$v) {_vm.$set(_vm.form, "gender", $$v)},expression:"form.gender"}})]}}],null,true)}),_c('div',{staticClass:"onboarding-form__title"},[_vm._v(" "+_vm._s(_vm.$t('onboarding.form.title.nameOnCard'))+" ")]),_c('validation-provider',{attrs:{"tag":"div","name":_vm.$t('onboarding.form.nameLabel'),"rules":"required|cardholderName","mode":"passive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ui-input',{attrs:{"label":_vm.$t('onboarding.form.nameLabel'),"minlength":"3","required":"","uppercase":"","error":errors[0]},model:{value:(_vm.form.nameOnCard),callback:function ($$v) {_vm.$set(_vm.form, "nameOnCard", $$v)},expression:"form.nameOnCard"}})]}}],null,true)}),_c('div',{staticClass:"onboarding-form__title"},[_vm._v(" "+_vm._s(_vm.$t('onboarding.form.title.addressOfResidence'))+" ")]),_c('validation-provider',{attrs:{"tag":"div","name":_vm.$t('onboarding.form.residenceAddress'),"rules":"required","mode":"passive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ui-input',{attrs:{"label":_vm.$t('onboarding.form.residenceAddress'),"required":"","error":errors[0]},model:{value:(_vm.form.addressOfResidence.streetAddress),callback:function ($$v) {_vm.$set(_vm.form.addressOfResidence, "streetAddress", $$v)},expression:"form.addressOfResidence.streetAddress"}})]}}],null,true)}),_c('validation-provider',{attrs:{"tag":"div","name":_vm.$t('onboarding.form.residenceCity'),"rules":"required|min:2","mode":"passive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ui-input',{attrs:{"label":_vm.$t('onboarding.form.residenceCity'),"minlength":"2","required":"","error":errors[0]},model:{value:(_vm.form.addressOfResidence.city),callback:function ($$v) {_vm.$set(_vm.form.addressOfResidence, "city", $$v)},expression:"form.addressOfResidence.city"}})]}}],null,true)}),_c('validation-provider',{attrs:{"tag":"div","name":_vm.$t('onboarding.form.residenceCountry'),"rules":"required","mode":"passive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ui-select',{attrs:{"items":_vm.countries,"label":_vm.$t('onboarding.form.residenceCountry'),"placeholder":_vm.$t('form.placeholders.select'),"label-key":"name","value-key":"isoCode","filterable":"","required":"","error":errors[0]},model:{value:(_vm.form.addressOfResidence.country),callback:function ($$v) {_vm.$set(_vm.form.addressOfResidence, "country", $$v)},expression:"form.addressOfResidence.country"}})]}}],null,true)})]:[_c('validation-provider',{key:_vm.$t('onboarding.form.employmentStatus'),attrs:{"tag":"div","name":_vm.$t('onboarding.form.employmentStatus'),"rules":"required","mode":"passive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ui-select',{attrs:{"items":_vm.EMPLOYMENT_STATUSES,"label":_vm.$t('onboarding.form.employmentStatus'),"placeholder":_vm.$t('form.placeholders.select'),"filterable":"","required":"","error":errors[0]},model:{value:(_vm.form.employmentStatus),callback:function ($$v) {_vm.$set(_vm.form, "employmentStatus", $$v)},expression:"form.employmentStatus"}})]}}],null,true)}),_c('validation-provider',{key:_vm.$t('onboarding.form.companyName'),attrs:{"tag":"div","name":_vm.$t('onboarding.form.companyName'),"rules":"required","mode":"passive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ui-input',{attrs:{"label":_vm.$t('onboarding.form.companyName'),"required":"","error":errors[0]},model:{value:(_vm.form.companyName),callback:function ($$v) {_vm.$set(_vm.form, "companyName", $$v)},expression:"form.companyName"}})]}}],null,true)}),_c('validation-provider',{key:_vm.$t('onboarding.form.industry'),attrs:{"tag":"div","name":_vm.$t('onboarding.form.industry'),"rules":"required","mode":"passive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ui-select',{attrs:{"items":_vm.INDUSTRIES,"label":_vm.$t('onboarding.form.industry'),"placeholder":_vm.$t('form.placeholders.select'),"filterable":"","required":"","error":errors[0]},model:{value:(_vm.form.industry),callback:function ($$v) {_vm.$set(_vm.form, "industry", $$v)},expression:"form.industry"}})]}}],null,true)}),_c('validation-provider',{key:_vm.$t('onboarding.form.jobTitle'),attrs:{"tag":"div","name":_vm.$t('onboarding.form.jobTitle'),"rules":"required","mode":"passive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ui-input',{attrs:{"label":_vm.$t('onboarding.form.jobTitle'),"required":"","error":errors[0]},model:{value:(_vm.form.jobTitle),callback:function ($$v) {_vm.$set(_vm.form, "jobTitle", $$v)},expression:"form.jobTitle"}})]}}],null,true)}),_c('validation-provider',{key:_vm.$t('onboarding.form.salary'),attrs:{"tag":"div","name":_vm.$t('onboarding.form.salary'),"rules":"required|decimal|min_value:1","mode":"passive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ui-input',{attrs:{"label":((_vm.$t('onboarding.form.salary')) + " " + (_vm.currency ? (" (" + _vm.currency + ")") : '')),"required":"","error":errors[0]},model:{value:(_vm.form.salary.amount),callback:function ($$v) {_vm.$set(_vm.form.salary, "amount", _vm._n($$v))},expression:"form.salary.amount"}})]}}],null,true)}),_c('div',{staticClass:"onboarding-form__title"},[_vm._v(" "+_vm._s(_vm.$t('onboarding.form.title.employersAddress'))+" ")]),_c('validation-provider',{key:_vm.$t('onboarding.form.employersAddress'),attrs:{"tag":"div","name":_vm.$t('onboarding.form.employersAddress'),"rules":"required","mode":"passive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ui-input',{attrs:{"label":_vm.$t('onboarding.form.employersAddress'),"required":"","error":errors[0]},model:{value:(_vm.form.addressOfEmployer.streetAddress),callback:function ($$v) {_vm.$set(_vm.form.addressOfEmployer, "streetAddress", $$v)},expression:"form.addressOfEmployer.streetAddress"}})]}}],null,true)}),_c('validation-provider',{key:_vm.$t('onboarding.form.employersCity'),attrs:{"tag":"div","name":_vm.$t('onboarding.form.employersCity'),"rules":"required","mode":"passive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ui-input',{attrs:{"label":_vm.$t('onboarding.form.employersCity'),"required":"","error":errors[0]},model:{value:(_vm.form.addressOfEmployer.city),callback:function ($$v) {_vm.$set(_vm.form.addressOfEmployer, "city", $$v)},expression:"form.addressOfEmployer.city"}})]}}],null,true)}),_c('validation-provider',{key:_vm.$t('onboarding.form.employersCountry'),attrs:{"tag":"div","name":_vm.$t('onboarding.form.employersCountry'),"rules":"required","mode":"passive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ui-select',{attrs:{"items":_vm.countries,"label":_vm.$t('onboarding.form.employersCountry'),"placeholder":_vm.$t('form.placeholders.select'),"label-key":"name","value-key":"isoCode","filterable":"","required":"","error":errors[0]},model:{value:(_vm.form.addressOfEmployer.country),callback:function ($$v) {_vm.$set(_vm.form.addressOfEmployer, "country", $$v)},expression:"form.addressOfEmployer.country"}})]}}],null,true)})],_c('new-button',{staticClass:"onboarding-form__submit-btn",attrs:{"is-loading":_vm.loading,"disabled":_vm.loading},on:{"click":function($event){$event.preventDefault();return _vm.send(validate)}}},[_vm._v(" Next ")])],2)]),_c('vue-bottom-sheet',{ref:"vbsKycError"},[_c('bottom-sheet-confirmation',{on:{"confirm":function($event){return _vm.send(validate)}}},[_vm._v(" Profile verification failed."),_c('br'),_vm._v(" Please confirm to try again ")])],1)]}}])})],1),_c('vue-bottom-sheet',{ref:"vbsReadyKycPolling",attrs:{"click-to-close":false,"swipe-able":false}},[_c('bottom-sheet-polling',[_vm._v(" Performing profile verification."),_c('br'),_vm._v(" Please wait a minute.. ")])],1),_c('vue-bottom-sheet',{ref:"vbsReadyKycStatusTimeout"},[_c('bottom-sheet-confirmation',{attrs:{"confirm-text":"Try again"},on:{"confirm":_vm.tryAgainReadyKycStatusPollingHandler}},[_vm._v(" Response timed out."),_c('br'),_vm._v(" Please try again. ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }