const categories = [
  'smileys_people',
  'animals_nature',
  'food_drink',
  'travel_places',
  'activities',
  'objects',
  'symbols',
];

const emojis = {
  smileys_people: [
    {
      name: 'grinning',
      hex: 0x1f600,
    },
    {
      name: 'smiley',
      hex: 0x1f603,
    },
    {
      name: 'smile',
      hex: 0x1f604,
    },
    {
      name: 'grin',
      hex: 0x1f601,
    },
    {
      name: 'satisfied',
      hex: 0x1f606,
    },
    {
      name: 'sweat_smile',
      hex: 0x1f605,
    },
    {
      name: 'rolling_on_the_floor_laughing',
      hex: 0x1f923,
    },
    {
      name: 'joy',
      hex: 0x1f602,
    },
    {
      name: 'slightly_smiling_face',
      hex: 0x1f642,
    },
    {
      name: 'upside_down_face',
      hex: 0x1f643,
    },
    {
      name: 'wink',
      hex: 0x1f609,
    },
    {
      name: 'blush',
      hex: 0x1f60a,
    },
    {
      name: 'innocent',
      hex: 0x1f607,
    },
    {
      name: 'smiling_face_with_3_hearts',
      hex: 0x1f970,
    },
    {
      name: 'heart_eyes',
      hex: 0x1f60d,
    },
    {
      name: 'grinning_face_with_star_eyes',
      hex: 0x1f929,
    },
    {
      name: 'kissing_heart',
      hex: 0x1f618,
    },
    {
      name: 'kissing',
      hex: 0x1f617,
    },
    {
      name: 'relaxed',
      hex: 0x263a,
    },
    {
      name: 'kissing_closed_eyes',
      hex: 0x1f61a,
    },
    {
      name: 'kissing_smiling_eyes',
      hex: 0x1f619,
    },
    {
      name: 'smiling_face_with_tear',
      hex: 0x1f972,
    },
    {
      name: 'yum',
      hex: 0x1f60b,
    },
    {
      name: 'stuck_out_tongue',
      hex: 0x1f61b,
    },
    {
      name: 'stuck_out_tongue_winking_eye',
      hex: 0x1f61c,
    },
    {
      name: 'grinning_face_with_one_large_and_one_small_eye',
      hex: 0x1f92a,
    },
    {
      name: 'stuck_out_tongue_closed_eyes',
      hex: 0x1f61d,
    },
    {
      name: 'money_mouth_face',
      hex: 0x1f911,
    },
    {
      name: 'hugging_face',
      hex: 0x1f917,
    },
    {
      name: 'smiling_face_with_smiling_eyes_and_hand_covering_mouth',
      hex: 0x1f92d,
    },
    {
      name: 'face_with_finger_covering_closed_lips',
      hex: 0x1f92b,
    },
    {
      name: 'thinking_face',
      hex: 0x1f914,
    },
    {
      name: 'zipper_mouth_face',
      hex: 0x1f910,
    },
    {
      name: 'face_with_one_eyebrow_raised',
      hex: 0x1f928,
    },
    {
      name: 'neutral_face',
      hex: 0x1f610,
    },
    {
      name: 'expressionless',
      hex: 0x1f611,
    },
    {
      name: 'no_mouth',
      hex: 0x1f636,
    },
    {
      name: 'face_in_clouds',
      hex: 0x1f636,
    },
    {
      name: 'smirk',
      hex: 0x1f60f,
    },
    {
      name: 'unamused',
      hex: 0x1f612,
    },
    {
      name: 'face_with_rolling_eyes',
      hex: 0x1f644,
    },
    {
      name: 'grimacing',
      hex: 0x1f62c,
    },
    {
      name: 'face_exhaling',
      hex: 0x1f62e,
    },
    {
      name: 'lying_face',
      hex: 0x1f925,
    },
    {
      name: 'relieved',
      hex: 0x1f60c,
    },
    {
      name: 'pensive',
      hex: 0x1f614,
    },
    {
      name: 'sleepy',
      hex: 0x1f62a,
    },
    {
      name: 'drooling_face',
      hex: 0x1f924,
    },
    {
      name: 'sleeping',
      hex: 0x1f634,
    },
    {
      name: 'mask',
      hex: 0x1f637,
    },
    {
      name: 'face_with_thermometer',
      hex: 0x1f912,
    },
    {
      name: 'face_with_head_bandage',
      hex: 0x1f915,
    },
    {
      name: 'nauseated_face',
      hex: 0x1f922,
    },
    {
      name: 'face_with_open_mouth_vomiting',
      hex: 0x1f92e,
    },
    {
      name: 'sneezing_face',
      hex: 0x1f927,
    },
    {
      name: 'hot_face',
      hex: 0x1f975,
    },
    {
      name: 'cold_face',
      hex: 0x1f976,
    },
    {
      name: 'woozy_face',
      hex: 0x1f974,
    },
    {
      name: 'dizzy_face',
      hex: 0x1f635,
    },
    {
      name: 'face_with_spiral_eyes',
      hex: 0x1f635,
    },
    {
      name: 'shocked_face_with_exploding_head',
      hex: 0x1f92f,
    },
    {
      name: 'face_with_cowboy_hat',
      hex: 0x1f920,
    },
    {
      name: 'partying_face',
      hex: 0x1f973,
    },
    {
      name: 'disguised_face',
      hex: 0x1f978,
    },
    {
      name: 'sunglasses',
      hex: 0x1f60e,
    },
    {
      name: 'nerd_face',
      hex: 0x1f913,
    },
    {
      name: 'face_with_monocle',
      hex: 0x1f9d0,
    },
    {
      name: 'confused',
      hex: 0x1f615,
    },
    {
      name: 'worried',
      hex: 0x1f61f,
    },
    {
      name: 'slightly_frowning_face',
      hex: 0x1f641,
    },
    {
      name: 'white_frowning_face',
      hex: 0x2639,
    },
    {
      name: 'open_mouth',
      hex: 0x1f62e,
    },
    {
      name: 'hushed',
      hex: 0x1f62f,
    },
    {
      name: 'astonished',
      hex: 0x1f632,
    },
    {
      name: 'flushed',
      hex: 0x1f633,
    },
    {
      name: 'pleading_face',
      hex: 0x1f97a,
    },
    {
      name: 'frowning',
      hex: 0x1f626,
    },
    {
      name: 'anguished',
      hex: 0x1f627,
    },
    {
      name: 'fearful',
      hex: 0x1f628,
    },
    {
      name: 'cold_sweat',
      hex: 0x1f630,
    },
    {
      name: 'disappointed_relieved',
      hex: 0x1f625,
    },
    {
      name: 'cry',
      hex: 0x1f622,
    },
    {
      name: 'sob',
      hex: 0x1f62d,
    },
    {
      name: 'scream',
      hex: 0x1f631,
    },
    {
      name: 'confounded',
      hex: 0x1f616,
    },
    {
      name: 'persevere',
      hex: 0x1f623,
    },
    {
      name: 'disappointed',
      hex: 0x1f61e,
    },
    {
      name: 'sweat',
      hex: 0x1f613,
    },
    {
      name: 'weary',
      hex: 0x1f629,
    },
    {
      name: 'tired_face',
      hex: 0x1f62b,
    },
    {
      name: 'yawning_face',
      hex: 0x1f971,
    },
    {
      name: 'triumph',
      hex: 0x1f624,
    },
    {
      name: 'rage',
      hex: 0x1f621,
    },
    {
      name: 'angry',
      hex: 0x1f620,
    },
    {
      name: 'serious_face_with_symbols_covering_mouth',
      hex: 0x1f92c,
    },
    {
      name: 'smiling_imp',
      hex: 0x1f608,
    },
    {
      name: 'imp',
      hex: 0x1f47f,
    },
    {
      name: 'skull',
      hex: 0x1f480,
    },
    {
      name: 'skull_and_crossbones',
      hex: 0x2620,
    },
    {
      name: 'shit',
      hex: 0x1f4a9,
    },
    {
      name: 'clown_face',
      hex: 0x1f921,
    },
    {
      name: 'japanese_ogre',
      hex: 0x1f479,
    },
    {
      name: 'japanese_goblin',
      hex: 0x1f47a,
    },
    {
      name: 'ghost',
      hex: 0x1f47b,
    },
    {
      name: 'alien',
      hex: 0x1f47d,
    },
    {
      name: 'space_invader',
      hex: 0x1f47e,
    },
    {
      name: 'robot_face',
      hex: 0x1f916,
    },
    {
      name: 'smiley_cat',
      hex: 0x1f63a,
    },
    {
      name: 'smile_cat',
      hex: 0x1f638,
    },
    {
      name: 'joy_cat',
      hex: 0x1f639,
    },
    {
      name: 'heart_eyes_cat',
      hex: 0x1f63b,
    },
    {
      name: 'smirk_cat',
      hex: 0x1f63c,
    },
    {
      name: 'kissing_cat',
      hex: 0x1f63d,
    },
    {
      name: 'scream_cat',
      hex: 0x1f640,
    },
    {
      name: 'crying_cat_face',
      hex: 0x1f63f,
    },
    {
      name: 'pouting_cat',
      hex: 0x1f63e,
    },
    {
      name: 'see_no_evil',
      hex: 0x1f648,
    },
    {
      name: 'hear_no_evil',
      hex: 0x1f649,
    },
    {
      name: 'speak_no_evil',
      hex: 0x1f64a,
    },
    {
      name: 'kiss',
      hex: 0x1f48b,
    },
    {
      name: 'love_letter',
      hex: 0x1f48c,
    },
    {
      name: 'cupid',
      hex: 0x1f498,
    },
    {
      name: 'gift_heart',
      hex: 0x1f49d,
    },
    {
      name: 'sparkling_heart',
      hex: 0x1f496,
    },
    {
      name: 'heartpulse',
      hex: 0x1f497,
    },
    {
      name: 'heartbeat',
      hex: 0x1f493,
    },
    {
      name: 'revolving_hearts',
      hex: 0x1f49e,
    },
    {
      name: 'two_hearts',
      hex: 0x1f495,
    },
    {
      name: 'heart_decoration',
      hex: 0x1f49f,
    },
    {
      name: 'heavy_heart_exclamation_mark_ornament',
      hex: 0x2763,
    },
    {
      name: 'broken_heart',
      hex: 0x1f494,
    },
    {
      name: 'heart_on_fire',
      hex: 0x2764,
    },
    {
      name: 'mending_heart',
      hex: 0x2764,
    },
    {
      name: 'heart',
      hex: 0x2764,
    },
    {
      name: 'orange_heart',
      hex: 0x1f9e1,
    },
    {
      name: 'yellow_heart',
      hex: 0x1f49b,
    },
    {
      name: 'green_heart',
      hex: 0x1f49a,
    },
    {
      name: 'blue_heart',
      hex: 0x1f499,
    },
    {
      name: 'purple_heart',
      hex: 0x1f49c,
    },
    {
      name: 'brown_heart',
      hex: 0x1f90e,
    },
    {
      name: 'black_heart',
      hex: 0x1f5a4,
    },
    {
      name: 'white_heart',
      hex: 0x1f90d,
    },
    {
      name: '100',
      hex: 0x1f4af,
    },
    {
      name: 'anger',
      hex: 0x1f4a2,
    },
    {
      name: 'collision',
      hex: 0x1f4a5,
    },
    {
      name: 'dizzy',
      hex: 0x1f4ab,
    },
    {
      name: 'sweat_drops',
      hex: 0x1f4a6,
    },
    {
      name: 'dash',
      hex: 0x1f4a8,
    },
    {
      name: 'hole',
      hex: 0x1f573,
    },
    {
      name: 'bomb',
      hex: 0x1f4a3,
    },
    {
      name: 'speech_balloon',
      hex: 0x1f4ac,
    },
    {
      name: 'eye-in-speech-bubble',
      hex: 0x1f441,
    },
    {
      name: 'left_speech_bubble',
      hex: 0x1f5e8,
    },
    {
      name: 'right_anger_bubble',
      hex: 0x1f5ef,
    },
    {
      name: 'thought_balloon',
      hex: 0x1f4ad,
    },
    {
      name: 'zzz',
      hex: 0x1f4a4,
    },
    {
      name: 'wave',
      hex: 0x1f44b,
    },
    {
      name: 'raised_back_of_hand',
      hex: 0x1f91a,
    },
    {
      name: 'raised_hand_with_fingers_splayed',
      hex: 0x1f590,
    },
    {
      name: 'raised_hand',
      hex: 0x270b,
    },
    {
      name: 'spock-hand',
      hex: 0x1f596,
    },
    {
      name: 'ok_hand',
      hex: 0x1f44c,
    },
    {
      name: 'pinched_fingers',
      hex: 0x1f90c,
    },
    {
      name: 'pinching_hand',
      hex: 0x1f90f,
    },
    {
      name: 'v',
      hex: 0x270c,
    },
    {
      name: 'hand_with_index_and_middle_fingers_crossed',
      hex: 0x1f91e,
    },
    {
      name: 'i_love_you_hand_sign',
      hex: 0x1f91f,
    },
    {
      name: 'sign_of_the_horns',
      hex: 0x1f918,
    },
    {
      name: 'call_me_hand',
      hex: 0x1f919,
    },
    {
      name: 'point_left',
      hex: 0x1f448,
    },
    {
      name: 'point_right',
      hex: 0x1f449,
    },
    {
      name: 'point_up_2',
      hex: 0x1f446,
    },
    {
      name: 'reversed_hand_with_middle_finger_extended',
      hex: 0x1f595,
    },
    {
      name: 'point_down',
      hex: 0x1f447,
    },
    {
      name: 'point_up',
      hex: 0x261d,
    },
    {
      name: 'thumbsup',
      hex: 0x1f44d,
    },
    {
      name: 'thumbsdown',
      hex: 0x1f44e,
    },
    {
      name: 'fist',
      hex: 0x270a,
    },
    {
      name: 'punch',
      hex: 0x1f44a,
    },
    {
      name: 'left-facing_fist',
      hex: 0x1f91b,
    },
    {
      name: 'right-facing_fist',
      hex: 0x1f91c,
    },
    {
      name: 'clap',
      hex: 0x1f44f,
    },
    {
      name: 'raised_hands',
      hex: 0x1f64c,
    },
    {
      name: 'open_hands',
      hex: 0x1f450,
    },
    {
      name: 'palms_up_together',
      hex: 0x1f932,
    },
    {
      name: 'handshake',
      hex: 0x1f91d,
    },
    {
      name: 'pray',
      hex: 0x1f64f,
    },
    {
      name: 'writing_hand',
      hex: 0x270d,
    },
    {
      name: 'nail_care',
      hex: 0x1f485,
    },
    {
      name: 'selfie',
      hex: 0x1f933,
    },
    {
      name: 'muscle',
      hex: 0x1f4aa,
    },
    {
      name: 'mechanical_arm',
      hex: 0x1f9be,
    },
    {
      name: 'mechanical_leg',
      hex: 0x1f9bf,
    },
    {
      name: 'leg',
      hex: 0x1f9b5,
    },
    {
      name: 'foot',
      hex: 0x1f9b6,
    },
    {
      name: 'ear',
      hex: 0x1f442,
    },
    {
      name: 'ear_with_hearing_aid',
      hex: 0x1f9bb,
    },
    {
      name: 'nose',
      hex: 0x1f443,
    },
    {
      name: 'brain',
      hex: 0x1f9e0,
    },
    {
      name: 'anatomical_heart',
      hex: 0x1fac0,
    },
    {
      name: 'lungs',
      hex: 0x1fac1,
    },
    {
      name: 'tooth',
      hex: 0x1f9b7,
    },
    {
      name: 'bone',
      hex: 0x1f9b4,
    },
    {
      name: 'eyes',
      hex: 0x1f440,
    },
    {
      name: 'eye',
      hex: 0x1f441,
    },
    {
      name: 'tongue',
      hex: 0x1f445,
    },
    {
      name: 'lips',
      hex: 0x1f444,
    },
    {
      name: 'baby',
      hex: 0x1f476,
    },
    {
      name: 'child',
      hex: 0x1f9d2,
    },
    {
      name: 'boy',
      hex: 0x1f466,
    },
    {
      name: 'girl',
      hex: 0x1f467,
    },
    {
      name: 'adult',
      hex: 0x1f9d1,
    },
    {
      name: 'person_with_blond_hair',
      hex: 0x1f471,
    },
    {
      name: 'man',
      hex: 0x1f468,
    },
    {
      name: 'bearded_person',
      hex: 0x1f9d4,
    },
    {
      name: 'man_with_beard',
      hex: 0x1f9d4,
    },
    {
      name: 'woman_with_beard',
      hex: 0x1f9d4,
    },
    {
      name: 'red_haired_man',
      hex: 0x1f468,
    },
    {
      name: 'curly_haired_man',
      hex: 0x1f468,
    },
    {
      name: 'white_haired_man',
      hex: 0x1f468,
    },
    {
      name: 'bald_man',
      hex: 0x1f468,
    },
    {
      name: 'woman',
      hex: 0x1f469,
    },
    {
      name: 'red_haired_woman',
      hex: 0x1f469,
    },
    {
      name: 'red_haired_person',
      hex: 0x1f9d1,
    },
    {
      name: 'curly_haired_woman',
      hex: 0x1f469,
    },
    {
      name: 'curly_haired_person',
      hex: 0x1f9d1,
    },
    {
      name: 'white_haired_woman',
      hex: 0x1f469,
    },
    {
      name: 'white_haired_person',
      hex: 0x1f9d1,
    },
    {
      name: 'bald_woman',
      hex: 0x1f469,
    },
    {
      name: 'bald_person',
      hex: 0x1f9d1,
    },
    {
      name: 'blond-haired-woman',
      hex: 0x1f471,
    },
    {
      name: 'blond-haired-man',
      hex: 0x1f471,
    },
    {
      name: 'older_adult',
      hex: 0x1f9d3,
    },
    {
      name: 'older_man',
      hex: 0x1f474,
    },
    {
      name: 'older_woman',
      hex: 0x1f475,
    },
    {
      name: 'person_frowning',
      hex: 0x1f64d,
    },
    {
      name: 'man-frowning',
      hex: 0x1f64d,
    },
    {
      name: 'woman-frowning',
      hex: 0x1f64d,
    },
    {
      name: 'person_with_pouting_face',
      hex: 0x1f64e,
    },
    {
      name: 'man-pouting',
      hex: 0x1f64e,
    },
    {
      name: 'woman-pouting',
      hex: 0x1f64e,
    },
    {
      name: 'no_good',
      hex: 0x1f645,
    },
    {
      name: 'man-gesturing-no',
      hex: 0x1f645,
    },
    {
      name: 'woman-gesturing-no',
      hex: 0x1f645,
    },
    {
      name: 'ok_woman',
      hex: 0x1f646,
    },
    {
      name: 'man-gesturing-ok',
      hex: 0x1f646,
    },
    {
      name: 'woman-gesturing-ok',
      hex: 0x1f646,
    },
    {
      name: 'information_desk_person',
      hex: 0x1f481,
    },
    {
      name: 'man-tipping-hand',
      hex: 0x1f481,
    },
    {
      name: 'woman-tipping-hand',
      hex: 0x1f481,
    },
    {
      name: 'raising_hand',
      hex: 0x1f64b,
    },
    {
      name: 'man-raising-hand',
      hex: 0x1f64b,
    },
    {
      name: 'woman-raising-hand',
      hex: 0x1f64b,
    },
    {
      name: 'deaf_person',
      hex: 0x1f9cf,
    },
    {
      name: 'deaf_man',
      hex: 0x1f9cf,
    },
    {
      name: 'deaf_woman',
      hex: 0x1f9cf,
    },
    {
      name: 'bow',
      hex: 0x1f647,
    },
    {
      name: 'man-bowing',
      hex: 0x1f647,
    },
    {
      name: 'woman-bowing',
      hex: 0x1f647,
    },
    {
      name: 'face_palm',
      hex: 0x1f926,
    },
    {
      name: 'man-facepalming',
      hex: 0x1f926,
    },
    {
      name: 'woman-facepalming',
      hex: 0x1f926,
    },
    {
      name: 'shrug',
      hex: 0x1f937,
    },
    {
      name: 'man-shrugging',
      hex: 0x1f937,
    },
    {
      name: 'woman-shrugging',
      hex: 0x1f937,
    },
    {
      name: 'health_worker',
      hex: 0x1f9d1,
    },
    {
      name: 'male-doctor',
      hex: 0x1f468,
    },
    {
      name: 'female-doctor',
      hex: 0x1f469,
    },
    {
      name: 'student',
      hex: 0x1f9d1,
    },
    {
      name: 'male-student',
      hex: 0x1f468,
    },
    {
      name: 'female-student',
      hex: 0x1f469,
    },
    {
      name: 'teacher',
      hex: 0x1f9d1,
    },
    {
      name: 'male-teacher',
      hex: 0x1f468,
    },
    {
      name: 'female-teacher',
      hex: 0x1f469,
    },
    {
      name: 'judge',
      hex: 0x1f9d1,
    },
    {
      name: 'male-judge',
      hex: 0x1f468,
    },
    {
      name: 'female-judge',
      hex: 0x1f469,
    },
    {
      name: 'farmer',
      hex: 0x1f9d1,
    },
    {
      name: 'male-farmer',
      hex: 0x1f468,
    },
    {
      name: 'female-farmer',
      hex: 0x1f469,
    },
    {
      name: 'cook',
      hex: 0x1f9d1,
    },
    {
      name: 'male-cook',
      hex: 0x1f468,
    },
    {
      name: 'female-cook',
      hex: 0x1f469,
    },
    {
      name: 'mechanic',
      hex: 0x1f9d1,
    },
    {
      name: 'male-mechanic',
      hex: 0x1f468,
    },
    {
      name: 'female-mechanic',
      hex: 0x1f469,
    },
    {
      name: 'factory_worker',
      hex: 0x1f9d1,
    },
    {
      name: 'male-factory-worker',
      hex: 0x1f468,
    },
    {
      name: 'female-factory-worker',
      hex: 0x1f469,
    },
    {
      name: 'office_worker',
      hex: 0x1f9d1,
    },
    {
      name: 'male-office-worker',
      hex: 0x1f468,
    },
    {
      name: 'female-office-worker',
      hex: 0x1f469,
    },
    {
      name: 'scientist',
      hex: 0x1f9d1,
    },
    {
      name: 'male-scientist',
      hex: 0x1f468,
    },
    {
      name: 'female-scientist',
      hex: 0x1f469,
    },
    {
      name: 'technologist',
      hex: 0x1f9d1,
    },
    {
      name: 'male-technologist',
      hex: 0x1f468,
    },
    {
      name: 'female-technologist',
      hex: 0x1f469,
    },
    {
      name: 'singer',
      hex: 0x1f9d1,
    },
    {
      name: 'male-singer',
      hex: 0x1f468,
    },
    {
      name: 'female-singer',
      hex: 0x1f469,
    },
    {
      name: 'artist',
      hex: 0x1f9d1,
    },
    {
      name: 'male-artist',
      hex: 0x1f468,
    },
    {
      name: 'female-artist',
      hex: 0x1f469,
    },
    {
      name: 'pilot',
      hex: 0x1f9d1,
    },
    {
      name: 'male-pilot',
      hex: 0x1f468,
    },
    {
      name: 'female-pilot',
      hex: 0x1f469,
    },
    {
      name: 'astronaut',
      hex: 0x1f9d1,
    },
    {
      name: 'male-astronaut',
      hex: 0x1f468,
    },
    {
      name: 'female-astronaut',
      hex: 0x1f469,
    },
    {
      name: 'firefighter',
      hex: 0x1f9d1,
    },
    {
      name: 'male-firefighter',
      hex: 0x1f468,
    },
    {
      name: 'female-firefighter',
      hex: 0x1f469,
    },
    {
      name: 'cop',
      hex: 0x1f46e,
    },
    {
      name: 'male-police-officer',
      hex: 0x1f46e,
    },
    {
      name: 'female-police-officer',
      hex: 0x1f46e,
    },
    {
      name: 'sleuth_or_spy',
      hex: 0x1f575,
    },
    {
      name: 'male-detective',
      hex: 0x1f575,
    },
    {
      name: 'female-detective',
      hex: 0x1f575,
    },
    {
      name: 'guardsman',
      hex: 0x1f482,
    },
    {
      name: 'male-guard',
      hex: 0x1f482,
    },
    {
      name: 'female-guard',
      hex: 0x1f482,
    },
    {
      name: 'ninja',
      hex: 0x1f977,
    },
    {
      name: 'construction_worker',
      hex: 0x1f477,
    },
    {
      name: 'male-construction-worker',
      hex: 0x1f477,
    },
    {
      name: 'female-construction-worker',
      hex: 0x1f477,
    },
    {
      name: 'prince',
      hex: 0x1f934,
    },
    {
      name: 'princess',
      hex: 0x1f478,
    },
    {
      name: 'man_with_turban',
      hex: 0x1f473,
    },
    {
      name: 'man-wearing-turban',
      hex: 0x1f473,
    },
    {
      name: 'woman-wearing-turban',
      hex: 0x1f473,
    },
    {
      name: 'man_with_gua_pi_mao',
      hex: 0x1f472,
    },
    {
      name: 'person_with_headscarf',
      hex: 0x1f9d5,
    },
    {
      name: 'person_in_tuxedo',
      hex: 0x1f935,
    },
    {
      name: 'man_in_tuxedo',
      hex: 0x1f935,
    },
    {
      name: 'woman_in_tuxedo',
      hex: 0x1f935,
    },
    {
      name: 'bride_with_veil',
      hex: 0x1f470,
    },
    {
      name: 'man_with_veil',
      hex: 0x1f470,
    },
    {
      name: 'woman_with_veil',
      hex: 0x1f470,
    },
    {
      name: 'pregnant_woman',
      hex: 0x1f930,
    },
    {
      name: 'breast-feeding',
      hex: 0x1f931,
    },
    {
      name: 'woman_feeding_baby',
      hex: 0x1f469,
    },
    {
      name: 'man_feeding_baby',
      hex: 0x1f468,
    },
    {
      name: 'person_feeding_baby',
      hex: 0x1f9d1,
    },
    {
      name: 'angel',
      hex: 0x1f47c,
    },
    {
      name: 'santa',
      hex: 0x1f385,
    },
    {
      name: 'mother_christmas',
      hex: 0x1f936,
    },
    {
      name: 'mx_claus',
      hex: 0x1f9d1,
    },
    {
      name: 'superhero',
      hex: 0x1f9b8,
    },
    {
      name: 'male_superhero',
      hex: 0x1f9b8,
    },
    {
      name: 'female_superhero',
      hex: 0x1f9b8,
    },
    {
      name: 'supervillain',
      hex: 0x1f9b9,
    },
    {
      name: 'male_supervillain',
      hex: 0x1f9b9,
    },
    {
      name: 'female_supervillain',
      hex: 0x1f9b9,
    },
    {
      name: 'mage',
      hex: 0x1f9d9,
    },
    {
      name: 'male_mage',
      hex: 0x1f9d9,
    },
    {
      name: 'female_mage',
      hex: 0x1f9d9,
    },
    {
      name: 'fairy',
      hex: 0x1f9da,
    },
    {
      name: 'male_fairy',
      hex: 0x1f9da,
    },
    {
      name: 'female_fairy',
      hex: 0x1f9da,
    },
    {
      name: 'vampire',
      hex: 0x1f9db,
    },
    {
      name: 'male_vampire',
      hex: 0x1f9db,
    },
    {
      name: 'female_vampire',
      hex: 0x1f9db,
    },
    {
      name: 'merperson',
      hex: 0x1f9dc,
    },
    {
      name: 'merman',
      hex: 0x1f9dc,
    },
    {
      name: 'mermaid',
      hex: 0x1f9dc,
    },
    {
      name: 'elf',
      hex: 0x1f9dd,
    },
    {
      name: 'male_elf',
      hex: 0x1f9dd,
    },
    {
      name: 'female_elf',
      hex: 0x1f9dd,
    },
    {
      name: 'genie',
      hex: 0x1f9de,
    },
    {
      name: 'male_genie',
      hex: 0x1f9de,
    },
    {
      name: 'female_genie',
      hex: 0x1f9de,
    },
    {
      name: 'zombie',
      hex: 0x1f9df,
    },
    {
      name: 'male_zombie',
      hex: 0x1f9df,
    },
    {
      name: 'female_zombie',
      hex: 0x1f9df,
    },
    {
      name: 'massage',
      hex: 0x1f486,
    },
    {
      name: 'man-getting-massage',
      hex: 0x1f486,
    },
    {
      name: 'woman-getting-massage',
      hex: 0x1f486,
    },
    {
      name: 'haircut',
      hex: 0x1f487,
    },
    {
      name: 'man-getting-haircut',
      hex: 0x1f487,
    },
    {
      name: 'woman-getting-haircut',
      hex: 0x1f487,
    },
    {
      name: 'walking',
      hex: 0x1f6b6,
    },
    {
      name: 'man-walking',
      hex: 0x1f6b6,
    },
    {
      name: 'woman-walking',
      hex: 0x1f6b6,
    },
    {
      name: 'standing_person',
      hex: 0x1f9cd,
    },
    {
      name: 'man_standing',
      hex: 0x1f9cd,
    },
    {
      name: 'woman_standing',
      hex: 0x1f9cd,
    },
    {
      name: 'kneeling_person',
      hex: 0x1f9ce,
    },
    {
      name: 'man_kneeling',
      hex: 0x1f9ce,
    },
    {
      name: 'woman_kneeling',
      hex: 0x1f9ce,
    },
    {
      name: 'person_with_probing_cane',
      hex: 0x1f9d1,
    },
    {
      name: 'man_with_probing_cane',
      hex: 0x1f468,
    },
    {
      name: 'woman_with_probing_cane',
      hex: 0x1f469,
    },
    {
      name: 'person_in_motorized_wheelchair',
      hex: 0x1f9d1,
    },
    {
      name: 'man_in_motorized_wheelchair',
      hex: 0x1f468,
    },
    {
      name: 'woman_in_motorized_wheelchair',
      hex: 0x1f469,
    },
    {
      name: 'person_in_manual_wheelchair',
      hex: 0x1f9d1,
    },
    {
      name: 'man_in_manual_wheelchair',
      hex: 0x1f468,
    },
    {
      name: 'woman_in_manual_wheelchair',
      hex: 0x1f469,
    },
    {
      name: 'running',
      hex: 0x1f3c3,
    },
    {
      name: 'man-running',
      hex: 0x1f3c3,
    },
    {
      name: 'woman-running',
      hex: 0x1f3c3,
    },
    {
      name: 'dancer',
      hex: 0x1f483,
    },
    {
      name: 'man_dancing',
      hex: 0x1f57a,
    },
    {
      name: 'man_in_business_suit_levitating',
      hex: 0x1f574,
    },
    {
      name: 'dancers',
      hex: 0x1f46f,
    },
    {
      name: 'man-with-bunny-ears-partying',
      hex: 0x1f46f,
    },
    {
      name: 'woman-with-bunny-ears-partying',
      hex: 0x1f46f,
    },
    {
      name: 'person_in_steamy_room',
      hex: 0x1f9d6,
    },
    {
      name: 'man_in_steamy_room',
      hex: 0x1f9d6,
    },
    {
      name: 'woman_in_steamy_room',
      hex: 0x1f9d6,
    },
    {
      name: 'person_climbing',
      hex: 0x1f9d7,
    },
    {
      name: 'man_climbing',
      hex: 0x1f9d7,
    },
    {
      name: 'woman_climbing',
      hex: 0x1f9d7,
    },
    {
      name: 'fencer',
      hex: 0x1f93a,
    },
    {
      name: 'horse_racing',
      hex: 0x1f3c7,
    },
    {
      name: 'skier',
      hex: 0x26f7,
    },
    {
      name: 'snowboarder',
      hex: 0x1f3c2,
    },
    {
      name: 'golfer',
      hex: 0x1f3cc,
    },
    {
      name: 'man-golfing',
      hex: 0x1f3cc,
    },
    {
      name: 'woman-golfing',
      hex: 0x1f3cc,
    },
    {
      name: 'surfer',
      hex: 0x1f3c4,
    },
    {
      name: 'man-surfing',
      hex: 0x1f3c4,
    },
    {
      name: 'woman-surfing',
      hex: 0x1f3c4,
    },
    {
      name: 'rowboat',
      hex: 0x1f6a3,
    },
    {
      name: 'man-rowing-boat',
      hex: 0x1f6a3,
    },
    {
      name: 'woman-rowing-boat',
      hex: 0x1f6a3,
    },
    {
      name: 'swimmer',
      hex: 0x1f3ca,
    },
    {
      name: 'man-swimming',
      hex: 0x1f3ca,
    },
    {
      name: 'woman-swimming',
      hex: 0x1f3ca,
    },
    {
      name: 'person_with_ball',
      hex: 0x26f9,
    },
    {
      name: 'man-bouncing-ball',
      hex: 0x26f9,
    },
    {
      name: 'woman-bouncing-ball',
      hex: 0x26f9,
    },
    {
      name: 'weight_lifter',
      hex: 0x1f3cb,
    },
    {
      name: 'man-lifting-weights',
      hex: 0x1f3cb,
    },
    {
      name: 'woman-lifting-weights',
      hex: 0x1f3cb,
    },
    {
      name: 'bicyclist',
      hex: 0x1f6b4,
    },
    {
      name: 'man-biking',
      hex: 0x1f6b4,
    },
    {
      name: 'woman-biking',
      hex: 0x1f6b4,
    },
    {
      name: 'mountain_bicyclist',
      hex: 0x1f6b5,
    },
    {
      name: 'man-mountain-biking',
      hex: 0x1f6b5,
    },
    {
      name: 'woman-mountain-biking',
      hex: 0x1f6b5,
    },
    {
      name: 'person_doing_cartwheel',
      hex: 0x1f938,
    },
    {
      name: 'man-cartwheeling',
      hex: 0x1f938,
    },
    {
      name: 'woman-cartwheeling',
      hex: 0x1f938,
    },
    {
      name: 'wrestlers',
      hex: 0x1f93c,
    },
    {
      name: 'man-wrestling',
      hex: 0x1f93c,
    },
    {
      name: 'woman-wrestling',
      hex: 0x1f93c,
    },
    {
      name: 'water_polo',
      hex: 0x1f93d,
    },
    {
      name: 'man-playing-water-polo',
      hex: 0x1f93d,
    },
    {
      name: 'woman-playing-water-polo',
      hex: 0x1f93d,
    },
    {
      name: 'handball',
      hex: 0x1f93e,
    },
    {
      name: 'man-playing-handball',
      hex: 0x1f93e,
    },
    {
      name: 'woman-playing-handball',
      hex: 0x1f93e,
    },
    {
      name: 'juggling',
      hex: 0x1f939,
    },
    {
      name: 'man-juggling',
      hex: 0x1f939,
    },
    {
      name: 'woman-juggling',
      hex: 0x1f939,
    },
    {
      name: 'person_in_lotus_position',
      hex: 0x1f9d8,
    },
    {
      name: 'man_in_lotus_position',
      hex: 0x1f9d8,
    },
    {
      name: 'woman_in_lotus_position',
      hex: 0x1f9d8,
    },
    {
      name: 'bath',
      hex: 0x1f6c0,
    },
    {
      name: 'sleeping_accommodation',
      hex: 0x1f6cc,
    },
    {
      name: 'people_holding_hands',
      hex: 0x1f9d1,
    },
    {
      name: 'women_holding_hands',
      hex: 0x1f46d,
    },
    {
      name: 'couple',
      hex: 0x1f46b,
    },
    {
      name: 'men_holding_hands',
      hex: 0x1f46c,
    },
    {
      name: 'couplekiss',
      hex: 0x1f48f,
    },
    {
      name: 'woman-kiss-man',
      hex: 0x1f469,
    },
    {
      name: 'man-kiss-man',
      hex: 0x1f468,
    },
    {
      name: 'woman-kiss-woman',
      hex: 0x1f469,
    },
    {
      name: 'couple_with_heart',
      hex: 0x1f491,
    },
    {
      name: 'woman-heart-man',
      hex: 0x1f469,
    },
    {
      name: 'man-heart-man',
      hex: 0x1f468,
    },
    {
      name: 'woman-heart-woman',
      hex: 0x1f469,
    },
    {
      name: 'family',
      hex: 0x1f46a,
    },
    {
      name: 'man-woman-boy',
      hex: 0x1f468,
    },
    {
      name: 'man-woman-girl',
      hex: 0x1f468,
    },
    {
      name: 'man-woman-girl-boy',
      hex: 0x1f468,
    },
    {
      name: 'man-woman-boy-boy',
      hex: 0x1f468,
    },
    {
      name: 'man-woman-girl-girl',
      hex: 0x1f468,
    },
    {
      name: 'man-man-boy',
      hex: 0x1f468,
    },
    {
      name: 'man-man-girl',
      hex: 0x1f468,
    },
    {
      name: 'man-man-girl-boy',
      hex: 0x1f468,
    },
    {
      name: 'man-man-boy-boy',
      hex: 0x1f468,
    },
    {
      name: 'man-man-girl-girl',
      hex: 0x1f468,
    },
    {
      name: 'woman-woman-boy',
      hex: 0x1f469,
    },
    {
      name: 'woman-woman-girl',
      hex: 0x1f469,
    },
    {
      name: 'woman-woman-girl-boy',
      hex: 0x1f469,
    },
    {
      name: 'woman-woman-boy-boy',
      hex: 0x1f469,
    },
    {
      name: 'woman-woman-girl-girl',
      hex: 0x1f469,
    },
    {
      name: 'man-boy',
      hex: 0x1f468,
    },
    {
      name: 'man-boy-boy',
      hex: 0x1f468,
    },
    {
      name: 'man-girl',
      hex: 0x1f468,
    },
    {
      name: 'man-girl-boy',
      hex: 0x1f468,
    },
    {
      name: 'man-girl-girl',
      hex: 0x1f468,
    },
    {
      name: 'woman-boy',
      hex: 0x1f469,
    },
    {
      name: 'woman-boy-boy',
      hex: 0x1f469,
    },
    {
      name: 'woman-girl',
      hex: 0x1f469,
    },
    {
      name: 'woman-girl-boy',
      hex: 0x1f469,
    },
    {
      name: 'woman-girl-girl',
      hex: 0x1f469,
    },
    {
      name: 'speaking_head_in_silhouette',
      hex: 0x1f5e3,
    },
    {
      name: 'bust_in_silhouette',
      hex: 0x1f464,
    },
    {
      name: 'busts_in_silhouette',
      hex: 0x1f465,
    },
    {
      name: 'people_hugging',
      hex: 0x1fac2,
    },
    {
      name: 'footprints',
      hex: 0x1f463,
    },
  ],
  animals_nature: [
    {
      name: 'monkey_face',
      hex: 0x1f435,
    },
    {
      name: 'monkey',
      hex: 0x1f412,
    },
    {
      name: 'gorilla',
      hex: 0x1f98d,
    },
    {
      name: 'orangutan',
      hex: 0x1f9a7,
    },
    {
      name: 'dog',
      hex: 0x1f436,
    },
    {
      name: 'dog2',
      hex: 0x1f415,
    },
    {
      name: 'guide_dog',
      hex: 0x1f9ae,
    },
    {
      name: 'service_dog',
      hex: 0x1f415,
    },
    {
      name: 'poodle',
      hex: 0x1f429,
    },
    {
      name: 'wolf',
      hex: 0x1f43a,
    },
    {
      name: 'fox_face',
      hex: 0x1f98a,
    },
    {
      name: 'raccoon',
      hex: 0x1f99d,
    },
    {
      name: 'cat',
      hex: 0x1f431,
    },
    {
      name: 'cat2',
      hex: 0x1f408,
    },
    {
      name: 'black_cat',
      hex: 0x1f408,
    },
    {
      name: 'lion_face',
      hex: 0x1f981,
    },
    {
      name: 'tiger',
      hex: 0x1f42f,
    },
    {
      name: 'tiger2',
      hex: 0x1f405,
    },
    {
      name: 'leopard',
      hex: 0x1f406,
    },
    {
      name: 'horse',
      hex: 0x1f434,
    },
    {
      name: 'racehorse',
      hex: 0x1f40e,
    },
    {
      name: 'unicorn_face',
      hex: 0x1f984,
    },
    {
      name: 'zebra_face',
      hex: 0x1f993,
    },
    {
      name: 'deer',
      hex: 0x1f98c,
    },
    {
      name: 'bison',
      hex: 0x1f9ac,
    },
    {
      name: 'cow',
      hex: 0x1f42e,
    },
    {
      name: 'ox',
      hex: 0x1f402,
    },
    {
      name: 'water_buffalo',
      hex: 0x1f403,
    },
    {
      name: 'cow2',
      hex: 0x1f404,
    },
    {
      name: 'pig',
      hex: 0x1f437,
    },
    {
      name: 'pig2',
      hex: 0x1f416,
    },
    {
      name: 'boar',
      hex: 0x1f417,
    },
    {
      name: 'pig_nose',
      hex: 0x1f43d,
    },
    {
      name: 'ram',
      hex: 0x1f40f,
    },
    {
      name: 'sheep',
      hex: 0x1f411,
    },
    {
      name: 'goat',
      hex: 0x1f410,
    },
    {
      name: 'dromedary_camel',
      hex: 0x1f42a,
    },
    {
      name: 'camel',
      hex: 0x1f42b,
    },
    {
      name: 'llama',
      hex: 0x1f999,
    },
    {
      name: 'giraffe_face',
      hex: 0x1f992,
    },
    {
      name: 'elephant',
      hex: 0x1f418,
    },
    {
      name: 'mammoth',
      hex: 0x1f9a3,
    },
    {
      name: 'rhinoceros',
      hex: 0x1f98f,
    },
    {
      name: 'hippopotamus',
      hex: 0x1f99b,
    },
    {
      name: 'mouse',
      hex: 0x1f42d,
    },
    {
      name: 'mouse2',
      hex: 0x1f401,
    },
    {
      name: 'rat',
      hex: 0x1f400,
    },
    {
      name: 'hamster',
      hex: 0x1f439,
    },
    {
      name: 'rabbit',
      hex: 0x1f430,
    },
    {
      name: 'rabbit2',
      hex: 0x1f407,
    },
    {
      name: 'chipmunk',
      hex: 0x1f43f,
    },
    {
      name: 'beaver',
      hex: 0x1f9ab,
    },
    {
      name: 'hedgehog',
      hex: 0x1f994,
    },
    {
      name: 'bat',
      hex: 0x1f987,
    },
    {
      name: 'bear',
      hex: 0x1f43b,
    },
    {
      name: 'polar_bear',
      hex: 0x1f43b,
    },
    {
      name: 'koala',
      hex: 0x1f428,
    },
    {
      name: 'panda_face',
      hex: 0x1f43c,
    },
    {
      name: 'sloth',
      hex: 0x1f9a5,
    },
    {
      name: 'otter',
      hex: 0x1f9a6,
    },
    {
      name: 'skunk',
      hex: 0x1f9a8,
    },
    {
      name: 'kangaroo',
      hex: 0x1f998,
    },
    {
      name: 'badger',
      hex: 0x1f9a1,
    },
    {
      name: 'paw_prints',
      hex: 0x1f43e,
    },
    {
      name: 'turkey',
      hex: 0x1f983,
    },
    {
      name: 'chicken',
      hex: 0x1f414,
    },
    {
      name: 'rooster',
      hex: 0x1f413,
    },
    {
      name: 'hatching_chick',
      hex: 0x1f423,
    },
    {
      name: 'baby_chick',
      hex: 0x1f424,
    },
    {
      name: 'hatched_chick',
      hex: 0x1f425,
    },
    {
      name: 'bird',
      hex: 0x1f426,
    },
    {
      name: 'penguin',
      hex: 0x1f427,
    },
    {
      name: 'dove_of_peace',
      hex: 0x1f54a,
    },
    {
      name: 'eagle',
      hex: 0x1f985,
    },
    {
      name: 'duck',
      hex: 0x1f986,
    },
    {
      name: 'swan',
      hex: 0x1f9a2,
    },
    {
      name: 'owl',
      hex: 0x1f989,
    },
    {
      name: 'dodo',
      hex: 0x1f9a4,
    },
    {
      name: 'feather',
      hex: 0x1fab6,
    },
    {
      name: 'flamingo',
      hex: 0x1f9a9,
    },
    {
      name: 'peacock',
      hex: 0x1f99a,
    },
    {
      name: 'parrot',
      hex: 0x1f99c,
    },
    {
      name: 'frog',
      hex: 0x1f438,
    },
    {
      name: 'crocodile',
      hex: 0x1f40a,
    },
    {
      name: 'turtle',
      hex: 0x1f422,
    },
    {
      name: 'lizard',
      hex: 0x1f98e,
    },
    {
      name: 'snake',
      hex: 0x1f40d,
    },
    {
      name: 'dragon_face',
      hex: 0x1f432,
    },
    {
      name: 'dragon',
      hex: 0x1f409,
    },
    {
      name: 'sauropod',
      hex: 0x1f995,
    },
    {
      name: 't-rex',
      hex: 0x1f996,
    },
    {
      name: 'whale',
      hex: 0x1f433,
    },
    {
      name: 'whale2',
      hex: 0x1f40b,
    },
    {
      name: 'flipper',
      hex: 0x1f42c,
    },
    {
      name: 'seal',
      hex: 0x1f9ad,
    },
    {
      name: 'fish',
      hex: 0x1f41f,
    },
    {
      name: 'tropical_fish',
      hex: 0x1f420,
    },
    {
      name: 'blowfish',
      hex: 0x1f421,
    },
    {
      name: 'shark',
      hex: 0x1f988,
    },
    {
      name: 'octopus',
      hex: 0x1f419,
    },
    {
      name: 'shell',
      hex: 0x1f41a,
    },
    {
      name: 'snail',
      hex: 0x1f40c,
    },
    {
      name: 'butterfly',
      hex: 0x1f98b,
    },
    {
      name: 'bug',
      hex: 0x1f41b,
    },
    {
      name: 'ant',
      hex: 0x1f41c,
    },
    {
      name: 'bee',
      hex: 0x1f41d,
    },
    {
      name: 'beetle',
      hex: 0x1fab2,
    },
    {
      name: 'lady_beetle',
      hex: 0x1f41e,
    },
    {
      name: 'cricket',
      hex: 0x1f997,
    },
    {
      name: 'cockroach',
      hex: 0x1fab3,
    },
    {
      name: 'spider',
      hex: 0x1f577,
    },
    {
      name: 'spider_web',
      hex: 0x1f578,
    },
    {
      name: 'scorpion',
      hex: 0x1f982,
    },
    {
      name: 'mosquito',
      hex: 0x1f99f,
    },
    {
      name: 'fly',
      hex: 0x1fab0,
    },
    {
      name: 'worm',
      hex: 0x1fab1,
    },
    {
      name: 'microbe',
      hex: 0x1f9a0,
    },
    {
      name: 'bouquet',
      hex: 0x1f490,
    },
    {
      name: 'cherry_blossom',
      hex: 0x1f338,
    },
    {
      name: 'white_flower',
      hex: 0x1f4ae,
    },
    {
      name: 'rosette',
      hex: 0x1f3f5,
    },
    {
      name: 'rose',
      hex: 0x1f339,
    },
    {
      name: 'wilted_flower',
      hex: 0x1f940,
    },
    {
      name: 'hibiscus',
      hex: 0x1f33a,
    },
    {
      name: 'sunflower',
      hex: 0x1f33b,
    },
    {
      name: 'blossom',
      hex: 0x1f33c,
    },
    {
      name: 'tulip',
      hex: 0x1f337,
    },
    {
      name: 'seedling',
      hex: 0x1f331,
    },
    {
      name: 'potted_plant',
      hex: 0x1fab4,
    },
    {
      name: 'evergreen_tree',
      hex: 0x1f332,
    },
    {
      name: 'deciduous_tree',
      hex: 0x1f333,
    },
    {
      name: 'palm_tree',
      hex: 0x1f334,
    },
    {
      name: 'cactus',
      hex: 0x1f335,
    },
    {
      name: 'ear_of_rice',
      hex: 0x1f33e,
    },
    {
      name: 'herb',
      hex: 0x1f33f,
    },
    {
      name: 'shamrock',
      hex: 0x2618,
    },
    {
      name: 'four_leaf_clover',
      hex: 0x1f340,
    },
    {
      name: 'maple_leaf',
      hex: 0x1f341,
    },
    {
      name: 'fallen_leaf',
      hex: 0x1f342,
    },
    {
      name: 'leaves',
      hex: 0x1f343,
    },
  ],
  food_drink: [
    {
      name: 'grapes',
      hex: 0x1f347,
    },
    {
      name: 'melon',
      hex: 0x1f348,
    },
    {
      name: 'watermelon',
      hex: 0x1f349,
    },
    {
      name: 'tangerine',
      hex: 0x1f34a,
    },
    {
      name: 'lemon',
      hex: 0x1f34b,
    },
    {
      name: 'banana',
      hex: 0x1f34c,
    },
    {
      name: 'pineapple',
      hex: 0x1f34d,
    },
    {
      name: 'mango',
      hex: 0x1f96d,
    },
    {
      name: 'apple',
      hex: 0x1f34e,
    },
    {
      name: 'green_apple',
      hex: 0x1f34f,
    },
    {
      name: 'pear',
      hex: 0x1f350,
    },
    {
      name: 'peach',
      hex: 0x1f351,
    },
    {
      name: 'cherries',
      hex: 0x1f352,
    },
    {
      name: 'strawberry',
      hex: 0x1f353,
    },
    {
      name: 'blueberries',
      hex: 0x1fad0,
    },
    {
      name: 'kiwifruit',
      hex: 0x1f95d,
    },
    {
      name: 'tomato',
      hex: 0x1f345,
    },
    {
      name: 'olive',
      hex: 0x1fad2,
    },
    {
      name: 'coconut',
      hex: 0x1f965,
    },
    {
      name: 'avocado',
      hex: 0x1f951,
    },
    {
      name: 'eggplant',
      hex: 0x1f346,
    },
    {
      name: 'potato',
      hex: 0x1f954,
    },
    {
      name: 'carrot',
      hex: 0x1f955,
    },
    {
      name: 'corn',
      hex: 0x1f33d,
    },
    {
      name: 'hot_pepper',
      hex: 0x1f336,
    },
    {
      name: 'bell_pepper',
      hex: 0x1fad1,
    },
    {
      name: 'cucumber',
      hex: 0x1f952,
    },
    {
      name: 'leafy_green',
      hex: 0x1f96c,
    },
    {
      name: 'broccoli',
      hex: 0x1f966,
    },
    {
      name: 'garlic',
      hex: 0x1f9c4,
    },
    {
      name: 'onion',
      hex: 0x1f9c5,
    },
    {
      name: 'mushroom',
      hex: 0x1f344,
    },
    {
      name: 'peanuts',
      hex: 0x1f95c,
    },
    {
      name: 'chestnut',
      hex: 0x1f330,
    },
    {
      name: 'bread',
      hex: 0x1f35e,
    },
    {
      name: 'croissant',
      hex: 0x1f950,
    },
    {
      name: 'baguette_bread',
      hex: 0x1f956,
    },
    {
      name: 'flatbread',
      hex: 0x1fad3,
    },
    {
      name: 'pretzel',
      hex: 0x1f968,
    },
    {
      name: 'bagel',
      hex: 0x1f96f,
    },
    {
      name: 'pancakes',
      hex: 0x1f95e,
    },
    {
      name: 'waffle',
      hex: 0x1f9c7,
    },
    {
      name: 'cheese_wedge',
      hex: 0x1f9c0,
    },
    {
      name: 'meat_on_bone',
      hex: 0x1f356,
    },
    {
      name: 'poultry_leg',
      hex: 0x1f357,
    },
    {
      name: 'cut_of_meat',
      hex: 0x1f969,
    },
    {
      name: 'bacon',
      hex: 0x1f953,
    },
    {
      name: 'hamburger',
      hex: 0x1f354,
    },
    {
      name: 'fries',
      hex: 0x1f35f,
    },
    {
      name: 'pizza',
      hex: 0x1f355,
    },
    {
      name: 'hotdog',
      hex: 0x1f32d,
    },
    {
      name: 'sandwich',
      hex: 0x1f96a,
    },
    {
      name: 'taco',
      hex: 0x1f32e,
    },
    {
      name: 'burrito',
      hex: 0x1f32f,
    },
    {
      name: 'tamale',
      hex: 0x1fad4,
    },
    {
      name: 'stuffed_flatbread',
      hex: 0x1f959,
    },
    {
      name: 'falafel',
      hex: 0x1f9c6,
    },
    {
      name: 'egg',
      hex: 0x1f95a,
    },
    {
      name: 'fried_egg',
      hex: 0x1f373,
    },
    {
      name: 'shallow_pan_of_food',
      hex: 0x1f958,
    },
    {
      name: 'stew',
      hex: 0x1f372,
    },
    {
      name: 'fondue',
      hex: 0x1fad5,
    },
    {
      name: 'bowl_with_spoon',
      hex: 0x1f963,
    },
    {
      name: 'green_salad',
      hex: 0x1f957,
    },
    {
      name: 'popcorn',
      hex: 0x1f37f,
    },
    {
      name: 'butter',
      hex: 0x1f9c8,
    },
    {
      name: 'salt',
      hex: 0x1f9c2,
    },
    {
      name: 'canned_food',
      hex: 0x1f96b,
    },
    {
      name: 'bento',
      hex: 0x1f371,
    },
    {
      name: 'rice_cracker',
      hex: 0x1f358,
    },
    {
      name: 'rice_ball',
      hex: 0x1f359,
    },
    {
      name: 'rice',
      hex: 0x1f35a,
    },
    {
      name: 'curry',
      hex: 0x1f35b,
    },
    {
      name: 'ramen',
      hex: 0x1f35c,
    },
    {
      name: 'spaghetti',
      hex: 0x1f35d,
    },
    {
      name: 'sweet_potato',
      hex: 0x1f360,
    },
    {
      name: 'oden',
      hex: 0x1f362,
    },
    {
      name: 'sushi',
      hex: 0x1f363,
    },
    {
      name: 'fried_shrimp',
      hex: 0x1f364,
    },
    {
      name: 'fish_cake',
      hex: 0x1f365,
    },
    {
      name: 'moon_cake',
      hex: 0x1f96e,
    },
    {
      name: 'dango',
      hex: 0x1f361,
    },
    {
      name: 'dumpling',
      hex: 0x1f95f,
    },
    {
      name: 'fortune_cookie',
      hex: 0x1f960,
    },
    {
      name: 'takeout_box',
      hex: 0x1f961,
    },
    {
      name: 'crab',
      hex: 0x1f980,
    },
    {
      name: 'lobster',
      hex: 0x1f99e,
    },
    {
      name: 'shrimp',
      hex: 0x1f990,
    },
    {
      name: 'squid',
      hex: 0x1f991,
    },
    {
      name: 'oyster',
      hex: 0x1f9aa,
    },
    {
      name: 'icecream',
      hex: 0x1f366,
    },
    {
      name: 'shaved_ice',
      hex: 0x1f367,
    },
    {
      name: 'ice_cream',
      hex: 0x1f368,
    },
    {
      name: 'doughnut',
      hex: 0x1f369,
    },
    {
      name: 'cookie',
      hex: 0x1f36a,
    },
    {
      name: 'birthday',
      hex: 0x1f382,
    },
    {
      name: 'cake',
      hex: 0x1f370,
    },
    {
      name: 'cupcake',
      hex: 0x1f9c1,
    },
    {
      name: 'pie',
      hex: 0x1f967,
    },
    {
      name: 'chocolate_bar',
      hex: 0x1f36b,
    },
    {
      name: 'candy',
      hex: 0x1f36c,
    },
    {
      name: 'lollipop',
      hex: 0x1f36d,
    },
    {
      name: 'custard',
      hex: 0x1f36e,
    },
    {
      name: 'honey_pot',
      hex: 0x1f36f,
    },
    {
      name: 'baby_bottle',
      hex: 0x1f37c,
    },
    {
      name: 'glass_of_milk',
      hex: 0x1f95b,
    },
    {
      name: 'coffee',
      hex: 0x2615,
    },
    {
      name: 'teapot',
      hex: 0x1fad6,
    },
    {
      name: 'tea',
      hex: 0x1f375,
    },
    {
      name: 'sake',
      hex: 0x1f376,
    },
    {
      name: 'champagne',
      hex: 0x1f37e,
    },
    {
      name: 'wine_glass',
      hex: 0x1f377,
    },
    {
      name: 'cocktail',
      hex: 0x1f378,
    },
    {
      name: 'tropical_drink',
      hex: 0x1f379,
    },
    {
      name: 'beer',
      hex: 0x1f37a,
    },
    {
      name: 'beers',
      hex: 0x1f37b,
    },
    {
      name: 'clinking_glasses',
      hex: 0x1f942,
    },
    {
      name: 'tumbler_glass',
      hex: 0x1f943,
    },
    {
      name: 'cup_with_straw',
      hex: 0x1f964,
    },
    {
      name: 'bubble_tea',
      hex: 0x1f9cb,
    },
    {
      name: 'beverage_box',
      hex: 0x1f9c3,
    },
    {
      name: 'mate_drink',
      hex: 0x1f9c9,
    },
    {
      name: 'ice_cube',
      hex: 0x1f9ca,
    },
    {
      name: 'chopsticks',
      hex: 0x1f962,
    },
    {
      name: 'knife_fork_plate',
      hex: 0x1f37d,
    },
    {
      name: 'fork_and_knife',
      hex: 0x1f374,
    },
    {
      name: 'spoon',
      hex: 0x1f944,
    },
    {
      name: 'knife',
      hex: 0x1f52a,
    },
    {
      name: 'amphora',
      hex: 0x1f3fa,
    },
  ],
  travel_places: [
    {
      name: 'earth_africa',
      hex: 0x1f30d,
    },
    {
      name: 'earth_americas',
      hex: 0x1f30e,
    },
    {
      name: 'earth_asia',
      hex: 0x1f30f,
    },
    {
      name: 'globe_with_meridians',
      hex: 0x1f310,
    },
    {
      name: 'world_map',
      hex: 0x1f5fa,
    },
    {
      name: 'japan',
      hex: 0x1f5fe,
    },
    {
      name: 'compass',
      hex: 0x1f9ed,
    },
    {
      name: 'snow_capped_mountain',
      hex: 0x1f3d4,
    },
    {
      name: 'mountain',
      hex: 0x26f0,
    },
    {
      name: 'volcano',
      hex: 0x1f30b,
    },
    {
      name: 'mount_fuji',
      hex: 0x1f5fb,
    },
    {
      name: 'camping',
      hex: 0x1f3d5,
    },
    {
      name: 'beach_with_umbrella',
      hex: 0x1f3d6,
    },
    {
      name: 'desert',
      hex: 0x1f3dc,
    },
    {
      name: 'desert_island',
      hex: 0x1f3dd,
    },
    {
      name: 'national_park',
      hex: 0x1f3de,
    },
    {
      name: 'stadium',
      hex: 0x1f3df,
    },
    {
      name: 'classical_building',
      hex: 0x1f3db,
    },
    {
      name: 'building_construction',
      hex: 0x1f3d7,
    },
    {
      name: 'bricks',
      hex: 0x1f9f1,
    },
    {
      name: 'rock',
      hex: 0x1faa8,
    },
    {
      name: 'wood',
      hex: 0x1fab5,
    },
    {
      name: 'hut',
      hex: 0x1f6d6,
    },
    {
      name: 'house_buildings',
      hex: 0x1f3d8,
    },
    {
      name: 'derelict_house_building',
      hex: 0x1f3da,
    },
    {
      name: 'house',
      hex: 0x1f3e0,
    },
    {
      name: 'house_with_garden',
      hex: 0x1f3e1,
    },
    {
      name: 'office',
      hex: 0x1f3e2,
    },
    {
      name: 'post_office',
      hex: 0x1f3e3,
    },
    {
      name: 'european_post_office',
      hex: 0x1f3e4,
    },
    {
      name: 'hospital',
      hex: 0x1f3e5,
    },
    {
      name: 'bank',
      hex: 0x1f3e6,
    },
    {
      name: 'hotel',
      hex: 0x1f3e8,
    },
    {
      name: 'love_hotel',
      hex: 0x1f3e9,
    },
    {
      name: 'convenience_store',
      hex: 0x1f3ea,
    },
    {
      name: 'school',
      hex: 0x1f3eb,
    },
    {
      name: 'department_store',
      hex: 0x1f3ec,
    },
    {
      name: 'factory',
      hex: 0x1f3ed,
    },
    {
      name: 'japanese_castle',
      hex: 0x1f3ef,
    },
    {
      name: 'european_castle',
      hex: 0x1f3f0,
    },
    {
      name: 'wedding',
      hex: 0x1f492,
    },
    {
      name: 'tokyo_tower',
      hex: 0x1f5fc,
    },
    {
      name: 'statue_of_liberty',
      hex: 0x1f5fd,
    },
    {
      name: 'church',
      hex: 0x26ea,
    },
    {
      name: 'mosque',
      hex: 0x1f54c,
    },
    {
      name: 'hindu_temple',
      hex: 0x1f6d5,
    },
    {
      name: 'synagogue',
      hex: 0x1f54d,
    },
    {
      name: 'shinto_shrine',
      hex: 0x26e9,
    },
    {
      name: 'kaaba',
      hex: 0x1f54b,
    },
    {
      name: 'fountain',
      hex: 0x26f2,
    },
    {
      name: 'tent',
      hex: 0x26fa,
    },
    {
      name: 'foggy',
      hex: 0x1f301,
    },
    {
      name: 'night_with_stars',
      hex: 0x1f303,
    },
    {
      name: 'cityscape',
      hex: 0x1f3d9,
    },
    {
      name: 'sunrise_over_mountains',
      hex: 0x1f304,
    },
    {
      name: 'sunrise',
      hex: 0x1f305,
    },
    {
      name: 'city_sunset',
      hex: 0x1f306,
    },
    {
      name: 'city_sunrise',
      hex: 0x1f307,
    },
    {
      name: 'bridge_at_night',
      hex: 0x1f309,
    },
    {
      name: 'hotsprings',
      hex: 0x2668,
    },
    {
      name: 'carousel_horse',
      hex: 0x1f3a0,
    },
    {
      name: 'ferris_wheel',
      hex: 0x1f3a1,
    },
    {
      name: 'roller_coaster',
      hex: 0x1f3a2,
    },
    {
      name: 'barber',
      hex: 0x1f488,
    },
    {
      name: 'circus_tent',
      hex: 0x1f3aa,
    },
    {
      name: 'steam_locomotive',
      hex: 0x1f682,
    },
    {
      name: 'railway_car',
      hex: 0x1f683,
    },
    {
      name: 'bullettrain_side',
      hex: 0x1f684,
    },
    {
      name: 'bullettrain_front',
      hex: 0x1f685,
    },
    {
      name: 'train2',
      hex: 0x1f686,
    },
    {
      name: 'metro',
      hex: 0x1f687,
    },
    {
      name: 'light_rail',
      hex: 0x1f688,
    },
    {
      name: 'station',
      hex: 0x1f689,
    },
    {
      name: 'tram',
      hex: 0x1f68a,
    },
    {
      name: 'monorail',
      hex: 0x1f69d,
    },
    {
      name: 'mountain_railway',
      hex: 0x1f69e,
    },
    {
      name: 'train',
      hex: 0x1f68b,
    },
    {
      name: 'bus',
      hex: 0x1f68c,
    },
    {
      name: 'oncoming_bus',
      hex: 0x1f68d,
    },
    {
      name: 'trolleybus',
      hex: 0x1f68e,
    },
    {
      name: 'minibus',
      hex: 0x1f690,
    },
    {
      name: 'ambulance',
      hex: 0x1f691,
    },
    {
      name: 'fire_engine',
      hex: 0x1f692,
    },
    {
      name: 'police_car',
      hex: 0x1f693,
    },
    {
      name: 'oncoming_police_car',
      hex: 0x1f694,
    },
    {
      name: 'taxi',
      hex: 0x1f695,
    },
    {
      name: 'oncoming_taxi',
      hex: 0x1f696,
    },
    {
      name: 'red_car',
      hex: 0x1f697,
    },
    {
      name: 'oncoming_automobile',
      hex: 0x1f698,
    },
    {
      name: 'blue_car',
      hex: 0x1f699,
    },
    {
      name: 'pickup_truck',
      hex: 0x1f6fb,
    },
    {
      name: 'truck',
      hex: 0x1f69a,
    },
    {
      name: 'articulated_lorry',
      hex: 0x1f69b,
    },
    {
      name: 'tractor',
      hex: 0x1f69c,
    },
    {
      name: 'racing_car',
      hex: 0x1f3ce,
    },
    {
      name: 'racing_motorcycle',
      hex: 0x1f3cd,
    },
    {
      name: 'motor_scooter',
      hex: 0x1f6f5,
    },
    {
      name: 'manual_wheelchair',
      hex: 0x1f9bd,
    },
    {
      name: 'motorized_wheelchair',
      hex: 0x1f9bc,
    },
    {
      name: 'auto_rickshaw',
      hex: 0x1f6fa,
    },
    {
      name: 'bike',
      hex: 0x1f6b2,
    },
    {
      name: 'scooter',
      hex: 0x1f6f4,
    },
    {
      name: 'skateboard',
      hex: 0x1f6f9,
    },
    {
      name: 'roller_skate',
      hex: 0x1f6fc,
    },
    {
      name: 'busstop',
      hex: 0x1f68f,
    },
    {
      name: 'motorway',
      hex: 0x1f6e3,
    },
    {
      name: 'railway_track',
      hex: 0x1f6e4,
    },
    {
      name: 'oil_drum',
      hex: 0x1f6e2,
    },
    {
      name: 'fuelpump',
      hex: 0x26fd,
    },
    {
      name: 'rotating_light',
      hex: 0x1f6a8,
    },
    {
      name: 'traffic_light',
      hex: 0x1f6a5,
    },
    {
      name: 'vertical_traffic_light',
      hex: 0x1f6a6,
    },
    {
      name: 'octagonal_sign',
      hex: 0x1f6d1,
    },
    {
      name: 'construction',
      hex: 0x1f6a7,
    },
    {
      name: 'anchor',
      hex: 0x2693,
    },
    {
      name: 'boat',
      hex: 0x26f5,
    },
    {
      name: 'canoe',
      hex: 0x1f6f6,
    },
    {
      name: 'speedboat',
      hex: 0x1f6a4,
    },
    {
      name: 'passenger_ship',
      hex: 0x1f6f3,
    },
    {
      name: 'ferry',
      hex: 0x26f4,
    },
    {
      name: 'motor_boat',
      hex: 0x1f6e5,
    },
    {
      name: 'ship',
      hex: 0x1f6a2,
    },
    {
      name: 'airplane',
      hex: 0x2708,
    },
    {
      name: 'small_airplane',
      hex: 0x1f6e9,
    },
    {
      name: 'airplane_departure',
      hex: 0x1f6eb,
    },
    {
      name: 'airplane_arriving',
      hex: 0x1f6ec,
    },
    {
      name: 'parachute',
      hex: 0x1fa82,
    },
    {
      name: 'seat',
      hex: 0x1f4ba,
    },
    {
      name: 'helicopter',
      hex: 0x1f681,
    },
    {
      name: 'suspension_railway',
      hex: 0x1f69f,
    },
    {
      name: 'mountain_cableway',
      hex: 0x1f6a0,
    },
    {
      name: 'aerial_tramway',
      hex: 0x1f6a1,
    },
    {
      name: 'satellite',
      hex: 0x1f6f0,
    },
    {
      name: 'rocket',
      hex: 0x1f680,
    },
    {
      name: 'flying_saucer',
      hex: 0x1f6f8,
    },
    {
      name: 'bellhop_bell',
      hex: 0x1f6ce,
    },
    {
      name: 'luggage',
      hex: 0x1f9f3,
    },
    {
      name: 'hourglass',
      hex: 0x231b,
    },
    {
      name: 'hourglass_flowing_sand',
      hex: 0x23f3,
    },
    {
      name: 'watch',
      hex: 0x231a,
    },
    {
      name: 'alarm_clock',
      hex: 0x23f0,
    },
    {
      name: 'stopwatch',
      hex: 0x23f1,
    },
    {
      name: 'timer_clock',
      hex: 0x23f2,
    },
    {
      name: 'mantelpiece_clock',
      hex: 0x1f570,
    },
    {
      name: 'clock12',
      hex: 0x1f55b,
    },
    {
      name: 'clock1230',
      hex: 0x1f567,
    },
    {
      name: 'clock1',
      hex: 0x1f550,
    },
    {
      name: 'clock130',
      hex: 0x1f55c,
    },
    {
      name: 'clock2',
      hex: 0x1f551,
    },
    {
      name: 'clock230',
      hex: 0x1f55d,
    },
    {
      name: 'clock3',
      hex: 0x1f552,
    },
    {
      name: 'clock330',
      hex: 0x1f55e,
    },
    {
      name: 'clock4',
      hex: 0x1f553,
    },
    {
      name: 'clock430',
      hex: 0x1f55f,
    },
    {
      name: 'clock5',
      hex: 0x1f554,
    },
    {
      name: 'clock530',
      hex: 0x1f560,
    },
    {
      name: 'clock6',
      hex: 0x1f555,
    },
    {
      name: 'clock630',
      hex: 0x1f561,
    },
    {
      name: 'clock7',
      hex: 0x1f556,
    },
    {
      name: 'clock730',
      hex: 0x1f562,
    },
    {
      name: 'clock8',
      hex: 0x1f557,
    },
    {
      name: 'clock830',
      hex: 0x1f563,
    },
    {
      name: 'clock9',
      hex: 0x1f558,
    },
    {
      name: 'clock930',
      hex: 0x1f564,
    },
    {
      name: 'clock10',
      hex: 0x1f559,
    },
    {
      name: 'clock1030',
      hex: 0x1f565,
    },
    {
      name: 'clock11',
      hex: 0x1f55a,
    },
    {
      name: 'clock1130',
      hex: 0x1f566,
    },
    {
      name: 'new_moon',
      hex: 0x1f311,
    },
    {
      name: 'waxing_crescent_moon',
      hex: 0x1f312,
    },
    {
      name: 'first_quarter_moon',
      hex: 0x1f313,
    },
    {
      name: 'waxing_gibbous_moon',
      hex: 0x1f314,
    },
    {
      name: 'full_moon',
      hex: 0x1f315,
    },
    {
      name: 'waning_gibbous_moon',
      hex: 0x1f316,
    },
    {
      name: 'last_quarter_moon',
      hex: 0x1f317,
    },
    {
      name: 'waning_crescent_moon',
      hex: 0x1f318,
    },
    {
      name: 'crescent_moon',
      hex: 0x1f319,
    },
    {
      name: 'new_moon_with_face',
      hex: 0x1f31a,
    },
    {
      name: 'first_quarter_moon_with_face',
      hex: 0x1f31b,
    },
    {
      name: 'last_quarter_moon_with_face',
      hex: 0x1f31c,
    },
    {
      name: 'thermometer',
      hex: 0x1f321,
    },
    {
      name: 'sunny',
      hex: 0x2600,
    },
    {
      name: 'full_moon_with_face',
      hex: 0x1f31d,
    },
    {
      name: 'sun_with_face',
      hex: 0x1f31e,
    },
    {
      name: 'ringed_planet',
      hex: 0x1fa90,
    },
    {
      name: 'star',
      hex: 0x2b50,
    },
    {
      name: 'star2',
      hex: 0x1f31f,
    },
    {
      name: 'stars',
      hex: 0x1f320,
    },
    {
      name: 'milky_way',
      hex: 0x1f30c,
    },
    {
      name: 'cloud',
      hex: 0x2601,
    },
    {
      name: 'partly_sunny',
      hex: 0x26c5,
    },
    {
      name: 'thunder_cloud_and_rain',
      hex: 0x26c8,
    },
    {
      name: 'sun_small_cloud',
      hex: 0x1f324,
    },
    {
      name: 'sun_behind_cloud',
      hex: 0x1f325,
    },
    {
      name: 'sun_behind_rain_cloud',
      hex: 0x1f326,
    },
    {
      name: 'rain_cloud',
      hex: 0x1f327,
    },
    {
      name: 'snow_cloud',
      hex: 0x1f328,
    },
    {
      name: 'lightning_cloud',
      hex: 0x1f329,
    },
    {
      name: 'tornado_cloud',
      hex: 0x1f32a,
    },
    {
      name: 'fog',
      hex: 0x1f32b,
    },
    {
      name: 'wind_blowing_face',
      hex: 0x1f32c,
    },
    {
      name: 'cyclone',
      hex: 0x1f300,
    },
    {
      name: 'rainbow',
      hex: 0x1f308,
    },
    {
      name: 'closed_umbrella',
      hex: 0x1f302,
    },
    {
      name: 'umbrella',
      hex: 0x2602,
    },
    {
      name: 'umbrella_with_rain_drops',
      hex: 0x2614,
    },
    {
      name: 'umbrella_on_ground',
      hex: 0x26f1,
    },
    {
      name: 'zap',
      hex: 0x26a1,
    },
    {
      name: 'snowflake',
      hex: 0x2744,
    },
    {
      name: 'snowman',
      hex: 0x2603,
    },
    {
      name: 'snowman_without_snow',
      hex: 0x26c4,
    },
    {
      name: 'comet',
      hex: 0x2604,
    },
    {
      name: 'fire',
      hex: 0x1f525,
    },
    {
      name: 'droplet',
      hex: 0x1f4a7,
    },
    {
      name: 'ocean',
      hex: 0x1f30a,
    },
  ],
  activities: [
    {
      name: 'jack_o_lantern',
      hex: 0x1f383,
    },
    {
      name: 'christmas_tree',
      hex: 0x1f384,
    },
    {
      name: 'fireworks',
      hex: 0x1f386,
    },
    {
      name: 'sparkler',
      hex: 0x1f387,
    },
    {
      name: 'firecracker',
      hex: 0x1f9e8,
    },
    {
      name: 'sparkles',
      hex: 0x2728,
    },
    {
      name: 'balloon',
      hex: 0x1f388,
    },
    {
      name: 'tada',
      hex: 0x1f389,
    },
    {
      name: 'confetti_ball',
      hex: 0x1f38a,
    },
    {
      name: 'tanabata_tree',
      hex: 0x1f38b,
    },
    {
      name: 'bamboo',
      hex: 0x1f38d,
    },
    {
      name: 'dolls',
      hex: 0x1f38e,
    },
    {
      name: 'flags',
      hex: 0x1f38f,
    },
    {
      name: 'wind_chime',
      hex: 0x1f390,
    },
    {
      name: 'rice_scene',
      hex: 0x1f391,
    },
    {
      name: 'red_envelope',
      hex: 0x1f9e7,
    },
    {
      name: 'ribbon',
      hex: 0x1f380,
    },
    {
      name: 'gift',
      hex: 0x1f381,
    },
    {
      name: 'reminder_ribbon',
      hex: 0x1f397,
    },
    {
      name: 'admission_tickets',
      hex: 0x1f39f,
    },
    {
      name: 'ticket',
      hex: 0x1f3ab,
    },
    {
      name: 'medal',
      hex: 0x1f396,
    },
    {
      name: 'trophy',
      hex: 0x1f3c6,
    },
    {
      name: 'sports_medal',
      hex: 0x1f3c5,
    },
    {
      name: 'first_place_medal',
      hex: 0x1f947,
    },
    {
      name: 'second_place_medal',
      hex: 0x1f948,
    },
    {
      name: 'third_place_medal',
      hex: 0x1f949,
    },
    {
      name: 'soccer',
      hex: 0x26bd,
    },
    {
      name: 'baseball',
      hex: 0x26be,
    },
    {
      name: 'softball',
      hex: 0x1f94e,
    },
    {
      name: 'basketball',
      hex: 0x1f3c0,
    },
    {
      name: 'volleyball',
      hex: 0x1f3d0,
    },
    {
      name: 'football',
      hex: 0x1f3c8,
    },
    {
      name: 'rugby_football',
      hex: 0x1f3c9,
    },
    {
      name: 'tennis',
      hex: 0x1f3be,
    },
    {
      name: 'flying_disc',
      hex: 0x1f94f,
    },
    {
      name: 'bowling',
      hex: 0x1f3b3,
    },
    {
      name: 'cricket_bat_and_ball',
      hex: 0x1f3cf,
    },
    {
      name: 'field_hockey_stick_and_ball',
      hex: 0x1f3d1,
    },
    {
      name: 'ice_hockey_stick_and_puck',
      hex: 0x1f3d2,
    },
    {
      name: 'lacrosse',
      hex: 0x1f94d,
    },
    {
      name: 'table_tennis_paddle_and_ball',
      hex: 0x1f3d3,
    },
    {
      name: 'badminton_racquet_and_shuttlecock',
      hex: 0x1f3f8,
    },
    {
      name: 'boxing_glove',
      hex: 0x1f94a,
    },
    {
      name: 'martial_arts_uniform',
      hex: 0x1f94b,
    },
    {
      name: 'goal_net',
      hex: 0x1f945,
    },
    {
      name: 'golf',
      hex: 0x26f3,
    },
    {
      name: 'ice_skate',
      hex: 0x26f8,
    },
    {
      name: 'fishing_pole_and_fish',
      hex: 0x1f3a3,
    },
    {
      name: 'diving_mask',
      hex: 0x1f93f,
    },
    {
      name: 'running_shirt_with_sash',
      hex: 0x1f3bd,
    },
    {
      name: 'ski',
      hex: 0x1f3bf,
    },
    {
      name: 'sled',
      hex: 0x1f6f7,
    },
    {
      name: 'curling_stone',
      hex: 0x1f94c,
    },
    {
      name: 'dart',
      hex: 0x1f3af,
    },
    {
      name: 'yo-yo',
      hex: 0x1fa80,
    },
    {
      name: 'kite',
      hex: 0x1fa81,
    },
    {
      name: '8ball',
      hex: 0x1f3b1,
    },
    {
      name: 'crystal_ball',
      hex: 0x1f52e,
    },
    {
      name: 'magic_wand',
      hex: 0x1fa84,
    },
    {
      name: 'nazar_amulet',
      hex: 0x1f9ff,
    },
    {
      name: 'video_game',
      hex: 0x1f3ae,
    },
    {
      name: 'joystick',
      hex: 0x1f579,
    },
    {
      name: 'slot_machine',
      hex: 0x1f3b0,
    },
    {
      name: 'game_die',
      hex: 0x1f3b2,
    },
    {
      name: 'jigsaw',
      hex: 0x1f9e9,
    },
    {
      name: 'teddy_bear',
      hex: 0x1f9f8,
    },
    {
      name: 'pinata',
      hex: 0x1fa85,
    },
    {
      name: 'nesting_dolls',
      hex: 0x1fa86,
    },
    {
      name: 'spades',
      hex: 0x2660,
    },
    {
      name: 'hearts',
      hex: 0x2665,
    },
    {
      name: 'diamonds',
      hex: 0x2666,
    },
    {
      name: 'clubs',
      hex: 0x2663,
    },
    {
      name: 'chess_pawn',
      hex: 0x265f,
    },
    {
      name: 'black_joker',
      hex: 0x1f0cf,
    },
    {
      name: 'mahjong',
      hex: 0x1f004,
    },
    {
      name: 'flower_playing_cards',
      hex: 0x1f3b4,
    },
    {
      name: 'performing_arts',
      hex: 0x1f3ad,
    },
    {
      name: 'frame_with_picture',
      hex: 0x1f5bc,
    },
    {
      name: 'art',
      hex: 0x1f3a8,
    },
    {
      name: 'thread',
      hex: 0x1f9f5,
    },
    {
      name: 'sewing_needle',
      hex: 0x1faa1,
    },
    {
      name: 'yarn',
      hex: 0x1f9f6,
    },
    {
      name: 'knot',
      hex: 0x1faa2,
    },
  ],
  objects: [
    {
      name: 'eyeglasses',
      hex: 0x1f453,
    },
    {
      name: 'dark_sunglasses',
      hex: 0x1f576,
    },
    {
      name: 'goggles',
      hex: 0x1f97d,
    },
    {
      name: 'lab_coat',
      hex: 0x1f97c,
    },
    {
      name: 'safety_vest',
      hex: 0x1f9ba,
    },
    {
      name: 'necktie',
      hex: 0x1f454,
    },
    {
      name: 'tshirt',
      hex: 0x1f455,
    },
    {
      name: 'jeans',
      hex: 0x1f456,
    },
    {
      name: 'scarf',
      hex: 0x1f9e3,
    },
    {
      name: 'gloves',
      hex: 0x1f9e4,
    },
    {
      name: 'coat',
      hex: 0x1f9e5,
    },
    {
      name: 'socks',
      hex: 0x1f9e6,
    },
    {
      name: 'dress',
      hex: 0x1f457,
    },
    {
      name: 'kimono',
      hex: 0x1f458,
    },
    {
      name: 'sari',
      hex: 0x1f97b,
    },
    {
      name: 'one-piece_swimsuit',
      hex: 0x1fa71,
    },
    {
      name: 'briefs',
      hex: 0x1fa72,
    },
    {
      name: 'shorts',
      hex: 0x1fa73,
    },
    {
      name: 'bikini',
      hex: 0x1f459,
    },
    {
      name: 'womans_clothes',
      hex: 0x1f45a,
    },
    {
      name: 'purse',
      hex: 0x1f45b,
    },
    {
      name: 'handbag',
      hex: 0x1f45c,
    },
    {
      name: 'pouch',
      hex: 0x1f45d,
    },
    {
      name: 'shopping_bags',
      hex: 0x1f6cd,
    },
    {
      name: 'school_satchel',
      hex: 0x1f392,
    },
    {
      name: 'thong_sandal',
      hex: 0x1fa74,
    },
    {
      name: 'shoe',
      hex: 0x1f45e,
    },
    {
      name: 'athletic_shoe',
      hex: 0x1f45f,
    },
    {
      name: 'hiking_boot',
      hex: 0x1f97e,
    },
    {
      name: 'womans_flat_shoe',
      hex: 0x1f97f,
    },
    {
      name: 'high_heel',
      hex: 0x1f460,
    },
    {
      name: 'sandal',
      hex: 0x1f461,
    },
    {
      name: 'ballet_shoes',
      hex: 0x1fa70,
    },
    {
      name: 'boot',
      hex: 0x1f462,
    },
    {
      name: 'crown',
      hex: 0x1f451,
    },
    {
      name: 'womans_hat',
      hex: 0x1f452,
    },
    {
      name: 'tophat',
      hex: 0x1f3a9,
    },
    {
      name: 'mortar_board',
      hex: 0x1f393,
    },
    {
      name: 'billed_cap',
      hex: 0x1f9e2,
    },
    {
      name: 'military_helmet',
      hex: 0x1fa96,
    },
    {
      name: 'helmet_with_white_cross',
      hex: 0x26d1,
    },
    {
      name: 'prayer_beads',
      hex: 0x1f4ff,
    },
    {
      name: 'lipstick',
      hex: 0x1f484,
    },
    {
      name: 'ring',
      hex: 0x1f48d,
    },
    {
      name: 'gem',
      hex: 0x1f48e,
    },
    {
      name: 'mute',
      hex: 0x1f507,
    },
    {
      name: 'speaker',
      hex: 0x1f508,
    },
    {
      name: 'sound',
      hex: 0x1f509,
    },
    {
      name: 'loud_sound',
      hex: 0x1f50a,
    },
    {
      name: 'loudspeaker',
      hex: 0x1f4e2,
    },
    {
      name: 'mega',
      hex: 0x1f4e3,
    },
    {
      name: 'postal_horn',
      hex: 0x1f4ef,
    },
    {
      name: 'bell',
      hex: 0x1f514,
    },
    {
      name: 'no_bell',
      hex: 0x1f515,
    },
    {
      name: 'musical_score',
      hex: 0x1f3bc,
    },
    {
      name: 'musical_note',
      hex: 0x1f3b5,
    },
    {
      name: 'notes',
      hex: 0x1f3b6,
    },
    {
      name: 'studio_microphone',
      hex: 0x1f399,
    },
    {
      name: 'level_slider',
      hex: 0x1f39a,
    },
    {
      name: 'control_knobs',
      hex: 0x1f39b,
    },
    {
      name: 'microphone',
      hex: 0x1f3a4,
    },
    {
      name: 'headphones',
      hex: 0x1f3a7,
    },
    {
      name: 'radio',
      hex: 0x1f4fb,
    },
    {
      name: 'saxophone',
      hex: 0x1f3b7,
    },
    {
      name: 'accordion',
      hex: 0x1fa97,
    },
    {
      name: 'guitar',
      hex: 0x1f3b8,
    },
    {
      name: 'musical_keyboard',
      hex: 0x1f3b9,
    },
    {
      name: 'trumpet',
      hex: 0x1f3ba,
    },
    {
      name: 'violin',
      hex: 0x1f3bb,
    },
    {
      name: 'banjo',
      hex: 0x1fa95,
    },
    {
      name: 'drum_with_drumsticks',
      hex: 0x1f941,
    },
    {
      name: 'long_drum',
      hex: 0x1fa98,
    },
    {
      name: 'iphone',
      hex: 0x1f4f1,
    },
    {
      name: 'calling',
      hex: 0x1f4f2,
    },
    {
      name: 'telephone',
      hex: 0x260e,
    },
    {
      name: 'telephone_receiver',
      hex: 0x1f4de,
    },
    {
      name: 'pager',
      hex: 0x1f4df,
    },
    {
      name: 'fax',
      hex: 0x1f4e0,
    },
    {
      name: 'battery',
      hex: 0x1f50b,
    },
    {
      name: 'electric_plug',
      hex: 0x1f50c,
    },
    {
      name: 'computer',
      hex: 0x1f4bb,
    },
    {
      name: 'desktop_computer',
      hex: 0x1f5a5,
    },
    {
      name: 'printer',
      hex: 0x1f5a8,
    },
    {
      name: 'keyboard',
      hex: 0x2328,
    },
    {
      name: 'three_button_mouse',
      hex: 0x1f5b1,
    },
    {
      name: 'trackball',
      hex: 0x1f5b2,
    },
    {
      name: 'minidisc',
      hex: 0x1f4bd,
    },
    {
      name: 'floppy_disk',
      hex: 0x1f4be,
    },
    {
      name: 'cd',
      hex: 0x1f4bf,
    },
    {
      name: 'dvd',
      hex: 0x1f4c0,
    },
    {
      name: 'abacus',
      hex: 0x1f9ee,
    },
    {
      name: 'movie_camera',
      hex: 0x1f3a5,
    },
    {
      name: 'film_frames',
      hex: 0x1f39e,
    },
    {
      name: 'film_projector',
      hex: 0x1f4fd,
    },
    {
      name: 'clapper',
      hex: 0x1f3ac,
    },
    {
      name: 'tv',
      hex: 0x1f4fa,
    },
    {
      name: 'camera',
      hex: 0x1f4f7,
    },
    {
      name: 'camera_with_flash',
      hex: 0x1f4f8,
    },
    {
      name: 'video_camera',
      hex: 0x1f4f9,
    },
    {
      name: 'vhs',
      hex: 0x1f4fc,
    },
    {
      name: 'mag',
      hex: 0x1f50d,
    },
    {
      name: 'mag_right',
      hex: 0x1f50e,
    },
    {
      name: 'candle',
      hex: 0x1f56f,
    },
    {
      name: 'bulb',
      hex: 0x1f4a1,
    },
    {
      name: 'flashlight',
      hex: 0x1f526,
    },
    {
      name: 'lantern',
      hex: 0x1f3ee,
    },
    {
      name: 'diya_lamp',
      hex: 0x1fa94,
    },
    {
      name: 'notebook_with_decorative_cover',
      hex: 0x1f4d4,
    },
    {
      name: 'closed_book',
      hex: 0x1f4d5,
    },
    {
      name: 'open_book',
      hex: 0x1f4d6,
    },
    {
      name: 'green_book',
      hex: 0x1f4d7,
    },
    {
      name: 'blue_book',
      hex: 0x1f4d8,
    },
    {
      name: 'orange_book',
      hex: 0x1f4d9,
    },
    {
      name: 'books',
      hex: 0x1f4da,
    },
    {
      name: 'notebook',
      hex: 0x1f4d3,
    },
    {
      name: 'ledger',
      hex: 0x1f4d2,
    },
    {
      name: 'page_with_curl',
      hex: 0x1f4c3,
    },
    {
      name: 'scroll',
      hex: 0x1f4dc,
    },
    {
      name: 'page_facing_up',
      hex: 0x1f4c4,
    },
    {
      name: 'newspaper',
      hex: 0x1f4f0,
    },
    {
      name: 'rolled_up_newspaper',
      hex: 0x1f5de,
    },
    {
      name: 'bookmark_tabs',
      hex: 0x1f4d1,
    },
    {
      name: 'bookmark',
      hex: 0x1f516,
    },
    {
      name: 'label',
      hex: 0x1f3f7,
    },
    {
      name: 'moneybag',
      hex: 0x1f4b0,
    },
    {
      name: 'coin',
      hex: 0x1fa99,
    },
    {
      name: 'yen',
      hex: 0x1f4b4,
    },
    {
      name: 'dollar',
      hex: 0x1f4b5,
    },
    {
      name: 'euro',
      hex: 0x1f4b6,
    },
    {
      name: 'pound',
      hex: 0x1f4b7,
    },
    {
      name: 'money_with_wings',
      hex: 0x1f4b8,
    },
    {
      name: 'credit_card',
      hex: 0x1f4b3,
    },
    {
      name: 'receipt',
      hex: 0x1f9fe,
    },
    {
      name: 'chart',
      hex: 0x1f4b9,
    },
    {
      name: 'email',
      hex: 0x2709,
    },
    {
      name: 'e-mail',
      hex: 0x1f4e7,
    },
    {
      name: 'incoming_envelope',
      hex: 0x1f4e8,
    },
    {
      name: 'envelope_with_arrow',
      hex: 0x1f4e9,
    },
    {
      name: 'outbox_tray',
      hex: 0x1f4e4,
    },
    {
      name: 'inbox_tray',
      hex: 0x1f4e5,
    },
    {
      name: 'package',
      hex: 0x1f4e6,
    },
    {
      name: 'mailbox',
      hex: 0x1f4eb,
    },
    {
      name: 'mailbox_closed',
      hex: 0x1f4ea,
    },
    {
      name: 'mailbox_with_mail',
      hex: 0x1f4ec,
    },
    {
      name: 'mailbox_with_no_mail',
      hex: 0x1f4ed,
    },
    {
      name: 'postbox',
      hex: 0x1f4ee,
    },
    {
      name: 'ballot_box_with_ballot',
      hex: 0x1f5f3,
    },
    {
      name: 'pencil2',
      hex: 0x270f,
    },
    {
      name: 'black_nib',
      hex: 0x2712,
    },
    {
      name: 'lower_left_fountain_pen',
      hex: 0x1f58b,
    },
    {
      name: 'lower_left_ballpoint_pen',
      hex: 0x1f58a,
    },
    {
      name: 'lower_left_paintbrush',
      hex: 0x1f58c,
    },
    {
      name: 'lower_left_crayon',
      hex: 0x1f58d,
    },
    {
      name: 'pencil',
      hex: 0x1f4dd,
    },
    {
      name: 'briefcase',
      hex: 0x1f4bc,
    },
    {
      name: 'file_folder',
      hex: 0x1f4c1,
    },
    {
      name: 'open_file_folder',
      hex: 0x1f4c2,
    },
    {
      name: 'card_index_dividers',
      hex: 0x1f5c2,
    },
    {
      name: 'date',
      hex: 0x1f4c5,
    },
    {
      name: 'calendar',
      hex: 0x1f4c6,
    },
    {
      name: 'spiral_note_pad',
      hex: 0x1f5d2,
    },
    {
      name: 'spiral_calendar_pad',
      hex: 0x1f5d3,
    },
    {
      name: 'card_index',
      hex: 0x1f4c7,
    },
    {
      name: 'chart_with_upwards_trend',
      hex: 0x1f4c8,
    },
    {
      name: 'chart_with_downwards_trend',
      hex: 0x1f4c9,
    },
    {
      name: 'bar_chart',
      hex: 0x1f4ca,
    },
    {
      name: 'clipboard',
      hex: 0x1f4cb,
    },
    {
      name: 'pushpin',
      hex: 0x1f4cc,
    },
    {
      name: 'round_pushpin',
      hex: 0x1f4cd,
    },
    {
      name: 'paperclip',
      hex: 0x1f4ce,
    },
    {
      name: 'linked_paperclips',
      hex: 0x1f587,
    },
    {
      name: 'straight_ruler',
      hex: 0x1f4cf,
    },
    {
      name: 'triangular_ruler',
      hex: 0x1f4d0,
    },
    {
      name: 'scissors',
      hex: 0x2702,
    },
    {
      name: 'card_file_box',
      hex: 0x1f5c3,
    },
    {
      name: 'file_cabinet',
      hex: 0x1f5c4,
    },
    {
      name: 'wastebasket',
      hex: 0x1f5d1,
    },
    {
      name: 'lock',
      hex: 0x1f512,
    },
    {
      name: 'unlock',
      hex: 0x1f513,
    },
    {
      name: 'lock_with_ink_pen',
      hex: 0x1f50f,
    },
    {
      name: 'closed_lock_with_key',
      hex: 0x1f510,
    },
    {
      name: 'key',
      hex: 0x1f511,
    },
    {
      name: 'old_key',
      hex: 0x1f5dd,
    },
    {
      name: 'hammer',
      hex: 0x1f528,
    },
    {
      name: 'axe',
      hex: 0x1fa93,
    },
    {
      name: 'pick',
      hex: 0x26cf,
    },
    {
      name: 'hammer_and_pick',
      hex: 0x2692,
    },
    {
      name: 'hammer_and_wrench',
      hex: 0x1f6e0,
    },
    {
      name: 'dagger_knife',
      hex: 0x1f5e1,
    },
    {
      name: 'crossed_swords',
      hex: 0x2694,
    },
    {
      name: 'gun',
      hex: 0x1f52b,
    },
    {
      name: 'boomerang',
      hex: 0x1fa83,
    },
    {
      name: 'bow_and_arrow',
      hex: 0x1f3f9,
    },
    {
      name: 'shield',
      hex: 0x1f6e1,
    },
    {
      name: 'carpentry_saw',
      hex: 0x1fa9a,
    },
    {
      name: 'wrench',
      hex: 0x1f527,
    },
    {
      name: 'screwdriver',
      hex: 0x1fa9b,
    },
    {
      name: 'nut_and_bolt',
      hex: 0x1f529,
    },
    {
      name: 'gear',
      hex: 0x2699,
    },
    {
      name: 'compression',
      hex: 0x1f5dc,
    },
    {
      name: 'scales',
      hex: 0x2696,
    },
    {
      name: 'probing_cane',
      hex: 0x1f9af,
    },
    {
      name: 'link',
      hex: 0x1f517,
    },
    {
      name: 'chains',
      hex: 0x26d3,
    },
    {
      name: 'hook',
      hex: 0x1fa9d,
    },
    {
      name: 'toolbox',
      hex: 0x1f9f0,
    },
    {
      name: 'magnet',
      hex: 0x1f9f2,
    },
    {
      name: 'ladder',
      hex: 0x1fa9c,
    },
    {
      name: 'alembic',
      hex: 0x2697,
    },
    {
      name: 'test_tube',
      hex: 0x1f9ea,
    },
    {
      name: 'petri_dish',
      hex: 0x1f9eb,
    },
    {
      name: 'dna',
      hex: 0x1f9ec,
    },
    {
      name: 'microscope',
      hex: 0x1f52c,
    },
    {
      name: 'telescope',
      hex: 0x1f52d,
    },
    {
      name: 'satellite_antenna',
      hex: 0x1f4e1,
    },
    {
      name: 'syringe',
      hex: 0x1f489,
    },
    {
      name: 'drop_of_blood',
      hex: 0x1fa78,
    },
    {
      name: 'pill',
      hex: 0x1f48a,
    },
    {
      name: 'adhesive_bandage',
      hex: 0x1fa79,
    },
    {
      name: 'stethoscope',
      hex: 0x1fa7a,
    },
    {
      name: 'door',
      hex: 0x1f6aa,
    },
    {
      name: 'elevator',
      hex: 0x1f6d7,
    },
    {
      name: 'mirror',
      hex: 0x1fa9e,
    },
    {
      name: 'window',
      hex: 0x1fa9f,
    },
    {
      name: 'bed',
      hex: 0x1f6cf,
    },
    {
      name: 'couch_and_lamp',
      hex: 0x1f6cb,
    },
    {
      name: 'chair',
      hex: 0x1fa91,
    },
    {
      name: 'toilet',
      hex: 0x1f6bd,
    },
    {
      name: 'plunger',
      hex: 0x1faa0,
    },
    {
      name: 'shower',
      hex: 0x1f6bf,
    },
    {
      name: 'bathtub',
      hex: 0x1f6c1,
    },
    {
      name: 'mouse_trap',
      hex: 0x1faa4,
    },
    {
      name: 'razor',
      hex: 0x1fa92,
    },
    {
      name: 'lotion_bottle',
      hex: 0x1f9f4,
    },
    {
      name: 'safety_pin',
      hex: 0x1f9f7,
    },
    {
      name: 'broom',
      hex: 0x1f9f9,
    },
    {
      name: 'basket',
      hex: 0x1f9fa,
    },
    {
      name: 'roll_of_paper',
      hex: 0x1f9fb,
    },
    {
      name: 'bucket',
      hex: 0x1faa3,
    },
    {
      name: 'soap',
      hex: 0x1f9fc,
    },
    {
      name: 'toothbrush',
      hex: 0x1faa5,
    },
    {
      name: 'sponge',
      hex: 0x1f9fd,
    },
    {
      name: 'fire_extinguisher',
      hex: 0x1f9ef,
    },
    {
      name: 'shopping_trolley',
      hex: 0x1f6d2,
    },
    {
      name: 'smoking',
      hex: 0x1f6ac,
    },
    {
      name: 'coffin',
      hex: 0x26b0,
    },
    {
      name: 'headstone',
      hex: 0x1faa6,
    },
    {
      name: 'funeral_urn',
      hex: 0x26b1,
    },
    {
      name: 'moyai',
      hex: 0x1f5ff,
    },
    {
      name: 'placard',
      hex: 0x1faa7,
    },
  ],
  symbols: [
    {
      name: 'atm',
      hex: 0x1f3e7,
    },
    {
      name: 'put_litter_in_its_place',
      hex: 0x1f6ae,
    },
    {
      name: 'potable_water',
      hex: 0x1f6b0,
    },
    {
      name: 'wheelchair',
      hex: 0x267f,
    },
    {
      name: 'mens',
      hex: 0x1f6b9,
    },
    {
      name: 'womens',
      hex: 0x1f6ba,
    },
    {
      name: 'restroom',
      hex: 0x1f6bb,
    },
    {
      name: 'baby_symbol',
      hex: 0x1f6bc,
    },
    {
      name: 'wc',
      hex: 0x1f6be,
    },
    {
      name: 'passport_control',
      hex: 0x1f6c2,
    },
    {
      name: 'customs',
      hex: 0x1f6c3,
    },
    {
      name: 'baggage_claim',
      hex: 0x1f6c4,
    },
    {
      name: 'left_luggage',
      hex: 0x1f6c5,
    },
    {
      name: 'warning',
      hex: 0x26a0,
    },
    {
      name: 'children_crossing',
      hex: 0x1f6b8,
    },
    {
      name: 'no_entry',
      hex: 0x26d4,
    },
    {
      name: 'no_entry_sign',
      hex: 0x1f6ab,
    },
    {
      name: 'no_bicycles',
      hex: 0x1f6b3,
    },
    {
      name: 'no_smoking',
      hex: 0x1f6ad,
    },
    {
      name: 'do_not_litter',
      hex: 0x1f6af,
    },
    {
      name: 'non-potable_water',
      hex: 0x1f6b1,
    },
    {
      name: 'no_pedestrians',
      hex: 0x1f6b7,
    },
    {
      name: 'no_mobile_phones',
      hex: 0x1f4f5,
    },
    {
      name: 'underage',
      hex: 0x1f51e,
    },
    {
      name: 'radioactive_sign',
      hex: 0x2622,
    },
    {
      name: 'biohazard_sign',
      hex: 0x2623,
    },
    {
      name: 'arrow_up',
      hex: 0x2b06,
    },
    {
      name: 'arrow_upper_right',
      hex: 0x2197,
    },
    {
      name: 'arrow_right',
      hex: 0x27a1,
    },
    {
      name: 'arrow_lower_right',
      hex: 0x2198,
    },
    {
      name: 'arrow_down',
      hex: 0x2b07,
    },
    {
      name: 'arrow_lower_left',
      hex: 0x2199,
    },
    {
      name: 'arrow_left',
      hex: 0x2b05,
    },
    {
      name: 'arrow_upper_left',
      hex: 0x2196,
    },
    {
      name: 'arrow_up_down',
      hex: 0x2195,
    },
    {
      name: 'left_right_arrow',
      hex: 0x2194,
    },
    {
      name: 'leftwards_arrow_with_hook',
      hex: 0x21a9,
    },
    {
      name: 'arrow_right_hook',
      hex: 0x21aa,
    },
    {
      name: 'arrow_heading_up',
      hex: 0x2934,
    },
    {
      name: 'arrow_heading_down',
      hex: 0x2935,
    },
    {
      name: 'arrows_clockwise',
      hex: 0x1f503,
    },
    {
      name: 'arrows_counterclockwise',
      hex: 0x1f504,
    },
    {
      name: 'back',
      hex: 0x1f519,
    },
    {
      name: 'end',
      hex: 0x1f51a,
    },
    {
      name: 'on',
      hex: 0x1f51b,
    },
    {
      name: 'soon',
      hex: 0x1f51c,
    },
    {
      name: 'top',
      hex: 0x1f51d,
    },
    {
      name: 'place_of_worship',
      hex: 0x1f6d0,
    },
    {
      name: 'atom_symbol',
      hex: 0x269b,
    },
    {
      name: 'om_symbol',
      hex: 0x1f549,
    },
    {
      name: 'star_of_david',
      hex: 0x2721,
    },
    {
      name: 'wheel_of_dharma',
      hex: 0x2638,
    },
    {
      name: 'yin_yang',
      hex: 0x262f,
    },
    {
      name: 'latin_cross',
      hex: 0x271d,
    },
    {
      name: 'orthodox_cross',
      hex: 0x2626,
    },
    {
      name: 'star_and_crescent',
      hex: 0x262a,
    },
    {
      name: 'peace_symbol',
      hex: 0x262e,
    },
    {
      name: 'menorah_with_nine_branches',
      hex: 0x1f54e,
    },
    {
      name: 'six_pointed_star',
      hex: 0x1f52f,
    },
    {
      name: 'aries',
      hex: 0x2648,
    },
    {
      name: 'taurus',
      hex: 0x2649,
    },
    {
      name: 'gemini',
      hex: 0x264a,
    },
    {
      name: 'cancer',
      hex: 0x264b,
    },
    {
      name: 'leo',
      hex: 0x264c,
    },
    {
      name: 'virgo',
      hex: 0x264d,
    },
    {
      name: 'libra',
      hex: 0x264e,
    },
    {
      name: 'scorpius',
      hex: 0x264f,
    },
    {
      name: 'sagittarius',
      hex: 0x2650,
    },
    {
      name: 'capricorn',
      hex: 0x2651,
    },
    {
      name: 'aquarius',
      hex: 0x2652,
    },
    {
      name: 'pisces',
      hex: 0x2653,
    },
    {
      name: 'ophiuchus',
      hex: 0x26ce,
    },
    {
      name: 'twisted_rightwards_arrows',
      hex: 0x1f500,
    },
    {
      name: 'repeat',
      hex: 0x1f501,
    },
    {
      name: 'repeat_one',
      hex: 0x1f502,
    },
    {
      name: 'arrow_forward',
      hex: 0x25b6,
    },
    {
      name: 'fast_forward',
      hex: 0x23e9,
    },
    {
      name: 'black_right_pointing_double_triangle_with_vertical_bar',
      hex: 0x23ed,
    },
    {
      name: 'black_right_pointing_triangle_with_double_vertical_bar',
      hex: 0x23ef,
    },
    {
      name: 'arrow_backward',
      hex: 0x25c0,
    },
    {
      name: 'rewind',
      hex: 0x23ea,
    },
    {
      name: 'black_left_pointing_double_triangle_with_vertical_bar',
      hex: 0x23ee,
    },
    {
      name: 'arrow_up_small',
      hex: 0x1f53c,
    },
    {
      name: 'arrow_double_up',
      hex: 0x23eb,
    },
    {
      name: 'arrow_down_small',
      hex: 0x1f53d,
    },
    {
      name: 'arrow_double_down',
      hex: 0x23ec,
    },
    {
      name: 'double_vertical_bar',
      hex: 0x23f8,
    },
    {
      name: 'black_square_for_stop',
      hex: 0x23f9,
    },
    {
      name: 'black_circle_for_record',
      hex: 0x23fa,
    },
    {
      name: 'eject',
      hex: 0x23cf,
    },
    {
      name: 'cinema',
      hex: 0x1f3a6,
    },
    {
      name: 'low_brightness',
      hex: 0x1f505,
    },
    {
      name: 'high_brightness',
      hex: 0x1f506,
    },
    {
      name: 'signal_strength',
      hex: 0x1f4f6,
    },
    {
      name: 'vibration_mode',
      hex: 0x1f4f3,
    },
    {
      name: 'mobile_phone_off',
      hex: 0x1f4f4,
    },
    {
      name: 'female_sign',
      hex: 0x2640,
    },
    {
      name: 'male_sign',
      hex: 0x2642,
    },
    {
      name: 'transgender_symbol',
      hex: 0x26a7,
    },
    {
      name: 'heavy_multiplication_x',
      hex: 0x2716,
    },
    {
      name: 'heavy_plus_sign',
      hex: 0x2795,
    },
    {
      name: 'heavy_minus_sign',
      hex: 0x2796,
    },
    {
      name: 'heavy_division_sign',
      hex: 0x2797,
    },
    {
      name: 'infinity',
      hex: 0x267e,
    },
    {
      name: 'bangbang',
      hex: 0x203c,
    },
    {
      name: 'interrobang',
      hex: 0x2049,
    },
    {
      name: 'question',
      hex: 0x2753,
    },
    {
      name: 'grey_question',
      hex: 0x2754,
    },
    {
      name: 'grey_exclamation',
      hex: 0x2755,
    },
    {
      name: 'heavy_exclamation_mark',
      hex: 0x2757,
    },
    {
      name: 'wavy_dash',
      hex: 0x3030,
    },
    {
      name: 'currency_exchange',
      hex: 0x1f4b1,
    },
    {
      name: 'heavy_dollar_sign',
      hex: 0x1f4b2,
    },
    {
      name: 'staff_of_aesculapius',
      hex: 0x2695,
    },
    {
      name: 'recycle',
      hex: 0x267b,
    },
    {
      name: 'fleur_de_lis',
      hex: 0x269c,
    },
    {
      name: 'trident',
      hex: 0x1f531,
    },
    {
      name: 'name_badge',
      hex: 0x1f4db,
    },
    {
      name: 'beginner',
      hex: 0x1f530,
    },
    {
      name: 'o',
      hex: 0x2b55,
    },
    {
      name: 'white_check_mark',
      hex: 0x2705,
    },
    {
      name: 'ballot_box_with_check',
      hex: 0x2611,
    },
    {
      name: 'heavy_check_mark',
      hex: 0x2714,
    },
    {
      name: 'x',
      hex: 0x274c,
    },
    {
      name: 'negative_squared_cross_mark',
      hex: 0x274e,
    },
    {
      name: 'curly_loop',
      hex: 0x27b0,
    },
    {
      name: 'loop',
      hex: 0x27bf,
    },
    {
      name: 'part_alternation_mark',
      hex: 0x303d,
    },
    {
      name: 'eight_spoked_asterisk',
      hex: 0x2733,
    },
    {
      name: 'eight_pointed_black_star',
      hex: 0x2734,
    },
    {
      name: 'sparkle',
      hex: 0x2747,
    },
    {
      name: 'copyright',
      hex: 0x00a9,
    },
    {
      name: 'registered',
      hex: 0x00ae,
    },
    {
      name: 'tm',
      hex: 0x2122,
    },
    {
      name: 'hash',
      hex: 0x0023,
    },
    {
      name: 'keycap_star',
      hex: 0x002a,
    },
    {
      name: 'zero',
      hex: 0x0030,
    },
    {
      name: 'one',
      hex: 0x0031,
    },
    {
      name: 'two',
      hex: 0x0032,
    },
    {
      name: 'three',
      hex: 0x0033,
    },
    {
      name: 'four',
      hex: 0x0034,
    },
    {
      name: 'five',
      hex: 0x0035,
    },
    {
      name: 'six',
      hex: 0x0036,
    },
    {
      name: 'seven',
      hex: 0x0037,
    },
    {
      name: 'eight',
      hex: 0x0038,
    },
    {
      name: 'nine',
      hex: 0x0039,
    },
    {
      name: 'keycap_ten',
      hex: 0x1f51f,
    },
    {
      name: 'capital_abcd',
      hex: 0x1f520,
    },
    {
      name: 'abcd',
      hex: 0x1f521,
    },
    {
      name: '1234',
      hex: 0x1f522,
    },
    {
      name: 'symbols',
      hex: 0x1f523,
    },
    {
      name: 'abc',
      hex: 0x1f524,
    },
    {
      name: 'a',
      hex: 0x1f170,
    },
    {
      name: 'ab',
      hex: 0x1f18e,
    },
    {
      name: 'b',
      hex: 0x1f171,
    },
    {
      name: 'cl',
      hex: 0x1f191,
    },
    {
      name: 'cool',
      hex: 0x1f192,
    },
    {
      name: 'free',
      hex: 0x1f193,
    },
    {
      name: 'information_source',
      hex: 0x2139,
    },
    {
      name: 'id',
      hex: 0x1f194,
    },
    {
      name: 'm',
      hex: 0x24c2,
    },
    {
      name: 'new',
      hex: 0x1f195,
    },
    {
      name: 'ng',
      hex: 0x1f196,
    },
    {
      name: 'o2',
      hex: 0x1f17e,
    },
    {
      name: 'ok',
      hex: 0x1f197,
    },
    {
      name: 'parking',
      hex: 0x1f17f,
    },
    {
      name: 'sos',
      hex: 0x1f198,
    },
    {
      name: 'up',
      hex: 0x1f199,
    },
    {
      name: 'vs',
      hex: 0x1f19a,
    },
    {
      name: 'koko',
      hex: 0x1f201,
    },
    {
      name: 'sa',
      hex: 0x1f202,
    },
    {
      name: 'u6708',
      hex: 0x1f237,
    },
    {
      name: 'u6709',
      hex: 0x1f236,
    },
    {
      name: 'u6307',
      hex: 0x1f22f,
    },
    {
      name: 'ideograph_advantage',
      hex: 0x1f250,
    },
    {
      name: 'u5272',
      hex: 0x1f239,
    },
    {
      name: 'u7121',
      hex: 0x1f21a,
    },
    {
      name: 'u7981',
      hex: 0x1f232,
    },
    {
      name: 'accept',
      hex: 0x1f251,
    },
    {
      name: 'u7533',
      hex: 0x1f238,
    },
    {
      name: 'u5408',
      hex: 0x1f234,
    },
    {
      name: 'u7a7a',
      hex: 0x1f233,
    },
    {
      name: 'congratulations',
      hex: 0x3297,
    },
    {
      name: 'secret',
      hex: 0x3299,
    },
    {
      name: 'u55b6',
      hex: 0x1f23a,
    },
    {
      name: 'u6e80',
      hex: 0x1f235,
    },
    {
      name: 'red_circle',
      hex: 0x1f534,
    },
    {
      name: 'large_orange_circle',
      hex: 0x1f7e0,
    },
    {
      name: 'large_yellow_circle',
      hex: 0x1f7e1,
    },
    {
      name: 'large_green_circle',
      hex: 0x1f7e2,
    },
    {
      name: 'large_blue_circle',
      hex: 0x1f535,
    },
    {
      name: 'large_purple_circle',
      hex: 0x1f7e3,
    },
    {
      name: 'large_brown_circle',
      hex: 0x1f7e4,
    },
    {
      name: 'black_circle',
      hex: 0x26ab,
    },
    {
      name: 'white_circle',
      hex: 0x26aa,
    },
    {
      name: 'large_red_square',
      hex: 0x1f7e5,
    },
    {
      name: 'large_orange_square',
      hex: 0x1f7e7,
    },
    {
      name: 'large_yellow_square',
      hex: 0x1f7e8,
    },
    {
      name: 'large_green_square',
      hex: 0x1f7e9,
    },
    {
      name: 'large_blue_square',
      hex: 0x1f7e6,
    },
    {
      name: 'large_purple_square',
      hex: 0x1f7ea,
    },
    {
      name: 'large_brown_square',
      hex: 0x1f7eb,
    },
    {
      name: 'black_large_square',
      hex: 0x2b1b,
    },
    {
      name: 'white_large_square',
      hex: 0x2b1c,
    },
    {
      name: 'black_medium_square',
      hex: 0x25fc,
    },
    {
      name: 'white_medium_square',
      hex: 0x25fb,
    },
    {
      name: 'black_medium_small_square',
      hex: 0x25fe,
    },
    {
      name: 'white_medium_small_square',
      hex: 0x25fd,
    },
    {
      name: 'black_small_square',
      hex: 0x25aa,
    },
    {
      name: 'white_small_square',
      hex: 0x25ab,
    },
    {
      name: 'large_orange_diamond',
      hex: 0x1f536,
    },
    {
      name: 'large_blue_diamond',
      hex: 0x1f537,
    },
    {
      name: 'small_orange_diamond',
      hex: 0x1f538,
    },
    {
      name: 'small_blue_diamond',
      hex: 0x1f539,
    },
    {
      name: 'small_red_triangle',
      hex: 0x1f53a,
    },
    {
      name: 'small_red_triangle_down',
      hex: 0x1f53b,
    },
    {
      name: 'diamond_shape_with_a_dot_inside',
      hex: 0x1f4a0,
    },
    {
      name: 'radio_button',
      hex: 0x1f518,
    },
    {
      name: 'white_square_button',
      hex: 0x1f533,
    },
    {
      name: 'black_square_button',
      hex: 0x1f532,
    },
  ],
};

export {
  categories,
  emojis,
};
