export const EMPLOYMENT_STATUSES = [
  {
    value: 'SALARIED',
    name: 'Salaried',
  },
  {
    value: 'SELF_EMPLOYED',
    name: 'Self Employed',
  },
  {
    value: 'BUSINESS_OWNER',
    name: 'Business owner',
  },
  {
    value: 'OTHER',
    name: 'Other',
  },
];
