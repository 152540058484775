<template>
  <onboarding-step
    title="Main information"
    description="We need a few personal details please to register you"
  >
    <validation-observer v-slot="{ validate }">
      <div class="onboarding-main-information">
        <ui-avatar
          :image="image"
          :is-loading="loading.avatar"
          :title="avatarTitle"
          class="onboarding-main-information__avatar"
          @save="uploadAvatar"
        />
        <div class="onboarding-main-information__card">
          <validation-provider
            tag="div"
            :name="$t('form.labels.firstName')"
            rules="required|min:2|max:30"
            mode="passive"
            v-slot="{ errors }"
          >
            <ui-input
              v-model="form.firstName"
              :label="$t('form.labels.firstName')"
              maxlength="30"
              minlength="2"
              capitalized
              required
              :error="errors[0]"
            />
          </validation-provider>

          <validation-provider
            tag="div"
            :name="$t('form.labels.lastName')"
            rules="required|min:2|max:30"
            mode="passive"
            v-slot="{ errors }"
          >
            <ui-input
              v-model="form.lastName"
              :label="$t('form.labels.lastName')"
              maxlength="30"
              minlength="2"
              capitalized
              required
              :error="errors[0]"
            />
          </validation-provider>
          <validation-provider
            tag="div"
            :name="$t('form.labels.dateOfBirth')"
            rules="adult|required"
            mode="passive"
            v-slot="{ errors }"
          >
            <ui-date-birth
              v-model="form.dateOfBirth"
              required
              :error="errors[0]"
            />
          </validation-provider>
          <validation-provider
            tag="div"
            :name="$t('form.labels.email')"
            rules="required|email"
            mode="passive"
            v-slot="{ errors }"
          >
            <ui-input
              v-model="form.email"
              :label="$t('form.labels.email')"
              required
              :error="errors[0]"
            />
          </validation-provider>
          <validation-provider
            v-if="showStaffTypeSelector"
            tag="div"
            :name="$t('form.labels.staffType')"
            rules="required"
            mode="passive"
            v-slot="{ errors }"
          >
            <ui-select
              v-model="form.staffType"
              v-protected
              :items="filteredStaffTypes"
              :label="$t('form.labels.staffType')"
              :placeholder="$t('form.placeholders.select')"
              label-key="name"
              value-key="value"
              required
              :error="errors[0]"
              transparent
            />
          </validation-provider>
          <validation-provider
            v-if="showIndividualFields"
            tag="div"
            name="Nickname (for tipping page)"
            rules="min:2|max:30"
            mode="passive"
            v-slot="{ errors }"
          >
            <ui-input
              v-model="form.nickName"
              label="Nickname (for tipping page)"
              maxlength="30"
              minlength="2"
              :error="errors[0]"
            />
          </validation-provider>
          <validation-provider
            v-if="showIndividualFields"
            tag="div"
            :name="$t('form.labels.wish')"
            rules="min:2|max:120"
            mode="passive"
            v-slot="{ errors }"
          >
            <ui-input
              v-model="form.wish"
              :label="$t('form.placeholders.wish')"
              tag="textarea"
              rows="1"
              maxlength="120"
              minlength="2"
              :error="errors[0]"
              class="input-wish"
            />
          </validation-provider>
          <new-button
            :is-loading="loading.form"
            :disabled="loading.form || !submitAvailable"
            class="onboarding-main-information__submit-btn"
            @click.prevent="send(validate)"
          >
            Next
          </new-button>
        </div>
      </div>
      <vue-bottom-sheet ref="mainInfoSubmitError">
        <onboarding-main-information-error
          :error-code="submitErrorCode"
          :form-loading="loading.form"
          @try-again="closeSubmitError"
          @exit="logoutHandler"
        />
      </vue-bottom-sheet>
      <vue-bottom-sheet ref="requiredFieldsAlert">
        <onboarding-main-information-required-error
          @submit="closeRequiredError"
        />
      </vue-bottom-sheet>
    </validation-observer>
  </onboarding-step>
</template>

<script>
import * as Sentry from '@sentry/vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import parseDate from '@/utils/parseDate';
import OnboardingStep from '@/components/Onboarding/OnboardingStep.vue';
import api from '@/api';
import toSQLDate from '@/utils/toSQLDate';
import OnboardingMainInformationError from '@/components/Onboarding/Errors/OnboardingMainInformationError.vue';
import OnboardingMainInformationRequiredError from '@/components/Onboarding/Errors/OnboardingMainInformationRequiredError.vue';
import { NOBODY } from '@/config/staffTypes';

export default {
  name: 'OnboardingMainInformation',

  components: {
    OnboardingMainInformationRequiredError,
    OnboardingMainInformationError,
    OnboardingStep,
  },

  data: () => ({
    loading: {
      avatar: false,
      form: false,
    },
    form: {
      email: '',
      firstName: '',
      lastName: '',
      wish: '',
      dateOfBirth: null,
      nickName: null,
      staffType: null,
    },
    submitErrorCode: 0,
  }),

  computed: {
    ...mapState('user', [
      'image',
      'location',
      'profile',
    ]),

    ...mapGetters({
      isStaff: 'auth/isStaff',
      isOwner: 'auth/isOwner',
      isTroncMaster: 'auth/isTroncMaster',
      filteredStaffTypes: 'handbook/filteredStaffTypes',
      isCommon: 'user/isCommon',
      staffTypeDisabled: 'handbook/staffTypeDisabled',
      isUAE: 'user/isUAE',
    }),

    avatarTitle() {
      return 'Insert your photo. Max photo size 5 MB. It will be seen by customers when they tip you.';
    },

    submitAvailable() {
      return this.form.email
        && this.form.firstName
        && this.form.lastName
        && this.form.dateOfBirth
        && (!this.isOwner || (!this.showStaffTypeSelector || this.form.staffType));
    },

    showIndividualFields() {
      return !this.isCommon && (this.isStaff || (this.form.staffType && this.form.staffType !== NOBODY));
    },

    showStaffTypeSelector() {
      return (this.isOwner || (this.isTroncMaster && this.isUAE)) && !this.staffTypeDisabled;
    },
  },

  methods: {
    ...mapActions({
      uploadImage: 'user/uploadImage',
      initializeSupport: 'support/initialize',
      loadInfo: 'user/loadInfo',
    }),

    loadData() {
      this.form.email = this.profile.email;
      this.form.firstName = this.profile.firstName;
      this.form.lastName = this.profile.lastName;
      this.form.wish = this.profile.wish;
      this.form.dateOfBirth = this.profile.dateOfBirth
        ? parseDate(this.profile.dateOfBirth)
        : null;
      this.form.nickName = this.profile.nickName;
      this.form.staffType = this.profile.staffType;
    },

    async uploadAvatar(file) {
      this.loading.avatar = true;

      try {
        await this.uploadImage(file);
      } catch (e) {
        this.$showServerError(e);

        Sentry.captureException(e, {
          tags: {
            section: 'onboarding',
          },
        });
      } finally {
        this.loading.avatar = false;
      }
    },

    async send(validate) {
      this.closeSubmitError();
      if (!await validate()) {
        this.$refs.requiredFieldsAlert.open();

        return;
      }

      if (this.loading.form) {
        return;
      }

      this.loading.form = true;
      this.submitErrorCode = 0;

      try {
        await api.user.updateProfile({
          ...this.form,
          wish: this.showIndividualFields ? this.form.wish : null,
          nickName: this.showIndividualFields ? this.form.nickName : null,
          dateOfBirth: toSQLDate(this.form.dateOfBirth),
        });

        await this.loadInfo();
        await this.initializeSupport();
        this.$emit('next-step');
      } catch (e) {
        const errorCode = e?.response?.data?.errorCode;
        if (errorCode) {
          this.submitErrorCode = errorCode;
        }

        Sentry.captureException(e, {
          tags: {
            section: 'onboarding',
          },
        });

        this.$refs.mainInfoSubmitError.open();
      } finally {
        this.loading.form = false;
      }
    },
    closeRequiredError() {
      this.$refs.requiredFieldsAlert.close();
    },
    closeSubmitError() {
      this.$refs.mainInfoSubmitError.close();
    },
    logoutHandler() {
      this.closeSubmitError();
      this.$router.push({ name: 'logout' });
    },
  },
  async mounted() {
    this.loadData();
  },
};
</script>
