<template>
  <div class="payout-card-error-alert">
    <div class="payout-card-error-alert__content">
      <div>
        <ui-icon name="card-status-error" size="20px" />
      </div>
      <div class="payout-card-error-alert__text">
        {{ $t('payoutCard.invalid.message') }}
      </div>
    </div>
    <new-button
      class="payout-card-error-alert__submit-btn"
      @click="$emit('try-again')"
    >
      Try again
    </new-button>
    <new-button
      type="ghost"
      class="payout-card-error-alert__skip-btn"
      @click="$emit('skip')"
    >
      Skip
    </new-button>
  </div>
</template>

<script>
export default {
  name: 'PayoutCardErrorAlert',
};
</script>
