<template>
  <validation-observer tag="form" v-slot="{ handleSubmit }">
    <ui-card
      class="recovery"
      type="login"
      :no-padding="noPadding"
      :no-box-shadow="noBoxShadow"
    >
      <template #header>
        <h4 class="card-title text-center"> {{ title }} </h4>
      </template>

      <div v-if="uuid" class="row mt-4">
        <div class="col-12">
          <validation-provider
            key="recovery-code"
            name="Code"
            rules="required"
            mode="passive"
            v-slot="{ errors }"
          >
            <ui-input
              v-if="!otpInput"
              v-model="code"
              label="Enter received code"
              input-class="form-control p-1"
              :error="errors[0]"
              required
            />

            <otp-input
              v-else
              v-model="code"
              :is-danger="errors[0] || error"
            />
          </validation-provider>
        </div>
      </div>

      <div v-else class="row">
        <div class="col-12">
          <validation-provider
            key="recovery-phone"
            name="Number"
            :rules="{
                required: {
                  invalid: phoneInvalid,
                },
              }"
            mode="passive"
            v-slot="{ errors }"
          >
            <ui-phone
              v-model="phoneNumber"
              :label="phoneLabel"
              input-class="form-control p-1"
              :error="errors[0]"
              required
              @validate="phoneInvalid = $event"
            />
          </validation-provider>
        </div>
      </div>

      <div v-if="uuid && resendCode">
        <resend-code :timeout="120" :error="error" @resend="recovery" />
      </div>

      <template #footer>
        <template v-if="uuid && onboarding">
          <ui-button
            native-type="submit"
            type="primary"
            block
            class="mb-3"
            :is-loading="loading"
            :disabled="loading"
            @click.prevent="handleSubmit(submit)">
            Submit code
          </ui-button>

          <new-button
            type="secondary"
            native-type="button"
            wide
            @click="goLogin"
          >
            Back to login
          </new-button>
        </template>
        <template v-if="uuid && !onboarding">
          <ui-button
            native-type="submit"
            type="warning"
            block
            class="mb-3"
            :is-loading="loading"
            :disabled="loading"
            @click.prevent="handleSubmit(submit)">
            Send password
          </ui-button>

          <ui-button
            type="primary"
            block
            class="mb-3"
            :disabled="loading"
            @click.prevent="reset">
            Cancel
          </ui-button>
        </template>
        <template v-if="!uuid">
          <ui-button
            native-type="submit"
            type="primary"
            block
            class="mb-3"
            :is-loading="loading"
            :disabled="loading"
            @click.prevent="handleSubmit(submit)">
            Send verification code
          </ui-button>

          <ui-button
            v-if="!onboarding"
            type="secondary"
            outline
            block
            class="mb-3"
            :disabled="loading"
            @click.prevent="goLogin">
            Back to login
          </ui-button>

          <new-button
            v-else
            type="secondary"
            native-type="button"
            wide
            @click="goLogin"
          >
            Back to login
          </new-button>
        </template>
      </template>
    </ui-card>
  </validation-observer>
</template>

<script>
import api from '@/api';
import resetMixin from '@/mixins/reset-mixin';
import ResendCode from '@/components/ResendCode.vue';
import OtpInput from '@/components/UI/Inputs/Otp/OtpInput.vue';

export default {
  mixins: [
    resetMixin(() => ({
      error: null,
      loading: false,
      phoneNumber: '',
      code: '',
      uuid: null,
      phoneInvalid: false,
    })),
  ],

  components: {
    OtpInput,
    ResendCode,
  },

  props: {
    title: {
      type: String,
      default: 'Recovery',
    },
    otpInput: Boolean,
    noPadding: Boolean,
    resendCode: Boolean,
    noBoxShadow: Boolean,
    onboarding: Boolean,
  },

  computed: {
    phoneLabel() {
      return !this.onboarding ? 'Enter phone number' : '';
    },
  },

  mounted() {
    this.reset();
  },

  methods: {
    async submit() {
      if (this.loading) {
        return;
      }

      this.loading = true;

      try {
        if (this.uuid) {
          await this.confirm();
        } else {
          await this.recovery();
        }
      } catch (e) {
        this.$showServerError(e, { phoneNumber: this.phoneNumber });
      } finally {
        this.loading = false;
      }
    },
    async recovery() {
      this.error = null;

      const {
        data: {
          uuid,
        },
      } = await api.auth.recovery({
        phoneNumber: this.phoneNumber,
      });

      if (uuid) {
        this.uuid = uuid;
      }
    },
    async confirm() {
      try {
        await api.auth.recoveryConfirm({
          uuid: this.uuid,
          code: this.code,
        });

        this.$showSuccess({
          message: 'New password send to your phone!',
        });

        this.uuid = null;
        this.$emit('confirm');
      } catch (e) {
        if (e.response.status === 404) {
          this.error = '422013';
        }

        if (e.response.status === 400) {
          this.error = '422012';
        }
      }
    },
    goLogin() {
      this.uuid = null;
      this.$emit('goLogin');
    },
  },
};
</script>
