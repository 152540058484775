<template>
  <onboarding-step
    title="Payout plans"
    description="Choose a plan depending on how quickly you want
                to receive your tips. Payouts will be made at the end of your
                chosen periods as one single amount directly to your bank card.
                You can change your plan any time."
  >
    <div class="onboarding-payout-plans">
      <ui-loading
        v-if="payoutPlansLoading || currentPlanLoading"
        inline
      />
      <template v-else>
        <payout-plan
          v-for="plan in computedPayoutPlans"
          :key="plan.id"
          :plan="plan"
          class="onboarding-payout-plans__plan"
          :is-free="plan.activePromo"
          :selected="plan.activePromo || currentPlanId === plan.id"
          :disabled="plan.disabled"
          @click.native="planClickedHandler(plan)"
        />
      </template>
      <new-button
        class="onboarding-payout-plans__complete-btn"
        @click="$emit('cancel-onboarding')"
      >
        Complete
      </new-button>
    </div>
    <vue-bottom-sheet ref="disabledPlanAlert">
      <div class="onboarding-payout-plans__alert">
        <div class="onboarding-payout-plans__alert-text">
          Your payouts are currently set on a FREE Daily payout plan.
          This promotion lasts first 14 days since your registration.
          You can change a payout plan once this period is over.
        </div>
        <new-button @click="closeDisabledPlanAlert">
          Ok
        </new-button>
      </div>
    </vue-bottom-sheet>
  </onboarding-step>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import OnboardingStep from '@/components/Onboarding/OnboardingStep.vue';
import PayoutPlan from '../Payouts/PayoutPlan.vue';

export default {
  name: 'OnboardingPayoutPlans',

  components: {
    PayoutPlan,
    OnboardingStep,
  },

  computed: {
    ...mapState('payouts', [
      'currentPlanLoading',
      'payoutPlansLoading',
    ]),
    ...mapGetters({
      computedPayoutPlans: 'payouts/computedPayoutPlans',
      currentPlanId: 'payouts/currentPlanId',
    }),
  },

  methods: {
    ...mapActions('payouts', [
      'selectNewPlan',
      'getCurrentPlan',
      'getPayoutPlans',
    ]),
    openDisabledPlanAlert() {
      this.$refs.disabledPlanAlert.open();
    },
    closeDisabledPlanAlert() {
      this.$refs.disabledPlanAlert.close();
    },
    planClickedHandler(plan) {
      if (plan.disabled) {
        this.openDisabledPlanAlert();
      } else {
        this.selectNewPlan(plan.id);
      }
    },
  },
  created() {
    if (!this.computedPayoutPlans?.length) {
      this.getPayoutPlans();
      this.getCurrentPlan();
    }
  },
};
</script>
