var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('onboarding-step',{attrs:{"title":"Main information","description":"We need a few personal details please to register you"}},[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
return [_c('div',{staticClass:"onboarding-main-information"},[_c('ui-avatar',{staticClass:"onboarding-main-information__avatar",attrs:{"image":_vm.image,"is-loading":_vm.loading.avatar,"title":_vm.avatarTitle},on:{"save":_vm.uploadAvatar}}),_c('div',{staticClass:"onboarding-main-information__card"},[_c('validation-provider',{attrs:{"tag":"div","name":_vm.$t('form.labels.firstName'),"rules":"required|min:2|max:30","mode":"passive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ui-input',{attrs:{"label":_vm.$t('form.labels.firstName'),"maxlength":"30","minlength":"2","capitalized":"","required":"","error":errors[0]},model:{value:(_vm.form.firstName),callback:function ($$v) {_vm.$set(_vm.form, "firstName", $$v)},expression:"form.firstName"}})]}}],null,true)}),_c('validation-provider',{attrs:{"tag":"div","name":_vm.$t('form.labels.lastName'),"rules":"required|min:2|max:30","mode":"passive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ui-input',{attrs:{"label":_vm.$t('form.labels.lastName'),"maxlength":"30","minlength":"2","capitalized":"","required":"","error":errors[0]},model:{value:(_vm.form.lastName),callback:function ($$v) {_vm.$set(_vm.form, "lastName", $$v)},expression:"form.lastName"}})]}}],null,true)}),_c('validation-provider',{attrs:{"tag":"div","name":_vm.$t('form.labels.dateOfBirth'),"rules":"adult|required","mode":"passive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ui-date-birth',{attrs:{"required":"","error":errors[0]},model:{value:(_vm.form.dateOfBirth),callback:function ($$v) {_vm.$set(_vm.form, "dateOfBirth", $$v)},expression:"form.dateOfBirth"}})]}}],null,true)}),_c('validation-provider',{attrs:{"tag":"div","name":_vm.$t('form.labels.email'),"rules":"required|email","mode":"passive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ui-input',{attrs:{"label":_vm.$t('form.labels.email'),"required":"","error":errors[0]},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})]}}],null,true)}),(_vm.showStaffTypeSelector)?_c('validation-provider',{attrs:{"tag":"div","name":_vm.$t('form.labels.staffType'),"rules":"required","mode":"passive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ui-select',{directives:[{name:"protected",rawName:"v-protected"}],attrs:{"items":_vm.filteredStaffTypes,"label":_vm.$t('form.labels.staffType'),"placeholder":_vm.$t('form.placeholders.select'),"label-key":"name","value-key":"value","required":"","error":errors[0],"transparent":""},model:{value:(_vm.form.staffType),callback:function ($$v) {_vm.$set(_vm.form, "staffType", $$v)},expression:"form.staffType"}})]}}],null,true)}):_vm._e(),(_vm.showIndividualFields)?_c('validation-provider',{attrs:{"tag":"div","name":"Nickname (for tipping page)","rules":"min:2|max:30","mode":"passive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ui-input',{attrs:{"label":"Nickname (for tipping page)","maxlength":"30","minlength":"2","error":errors[0]},model:{value:(_vm.form.nickName),callback:function ($$v) {_vm.$set(_vm.form, "nickName", $$v)},expression:"form.nickName"}})]}}],null,true)}):_vm._e(),(_vm.showIndividualFields)?_c('validation-provider',{attrs:{"tag":"div","name":_vm.$t('form.labels.wish'),"rules":"min:2|max:120","mode":"passive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ui-input',{staticClass:"input-wish",attrs:{"label":_vm.$t('form.placeholders.wish'),"tag":"textarea","rows":"1","maxlength":"120","minlength":"2","error":errors[0]},model:{value:(_vm.form.wish),callback:function ($$v) {_vm.$set(_vm.form, "wish", $$v)},expression:"form.wish"}})]}}],null,true)}):_vm._e(),_c('new-button',{staticClass:"onboarding-main-information__submit-btn",attrs:{"is-loading":_vm.loading.form,"disabled":_vm.loading.form || !_vm.submitAvailable},on:{"click":function($event){$event.preventDefault();return _vm.send(validate)}}},[_vm._v(" Next ")])],1)],1),_c('vue-bottom-sheet',{ref:"mainInfoSubmitError"},[_c('onboarding-main-information-error',{attrs:{"error-code":_vm.submitErrorCode,"form-loading":_vm.loading.form},on:{"try-again":_vm.closeSubmitError,"exit":_vm.logoutHandler}})],1),_c('vue-bottom-sheet',{ref:"requiredFieldsAlert"},[_c('onboarding-main-information-required-error',{on:{"submit":_vm.closeRequiredError}})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }