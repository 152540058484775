<template>
  <div class="onboarding-step">
    <div v-if="title" class="onboarding-step__title">
      {{ title }}
    </div>
    <div
      v-if="description"
      class="onboarding-step__description"
      v-html="description"
    />
    <slot />
  </div>
</template>

<script>
export default {
  name: 'OnboardingStep',

  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      default: '',
    },
  },
};
</script>
