<template>
  <div class="onboarding-checklist__wrapper">
    <div class="onboarding-checklist">
      <div class="onboarding-checklist__title">{{ $t('onboarding.checklist.title') }}</div>
      <div class="onboarding-checklist__subtitle">{{ $t('onboarding.checklist.subtitle') }}</div>
      <img
        src="@/assets/images/welcome/onboarding-checklist.png"
        alt=""
        class="onboarding-checklist__image"
      >
      <ui-card>
        <div class="onboarding-checklist__list">
          <div
            v-for="(agreement, index) in $t('onboarding.checklist.list').length"
            :key="agreement"
            class="onboarding-checklist__element"
          >
            <div v-html="$t(`onboarding.checklist.list[${index}]`)"/>
            <ui-icon
              name="onboarding-check"
              size="24px"
              class="onboarding-checklist__icon"
            />
          </div>
        </div>
        <new-button
          class="onboarding-checklist__next-button"
          @click="$emit('start-onboarding')"
        >
          {{ $t('form.buttons.next') }}
        </new-button>
      </ui-card>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OnboardingChecklist',
};
</script>
