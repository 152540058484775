<template>
  <div class="password-change-status">
    <ui-icon
      v-if="icon"
      :name="icon"
      size="120px"
      class="password-change-status__icon"
    />
    <div
      v-if="title"
      class="password-change-status__title"
    >
      {{ title }}
    </div>
    <div
      v-if="description"
      class="password-change-status__description"
    >
      {{ description }}
    </div>
    <new-button
      class="password-change-status__submit-btn"
      @click="$emit('submit')"
    >
      {{ $t('form.buttons.ok') }}
    </new-button>
  </div>
</template>

<script>
export default {
  name: 'PasswordChangeStatus',

  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
  },
};
</script>
