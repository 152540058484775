import { throttle } from 'lodash';

const ATTRIBUTE = '__STICKY__';

function getOffsetTop(element) {
  if (typeof element.getBoundingClientRect === 'function') {
    return element.getBoundingClientRect()?.top || 0;
  }

  return 0;
}

function getCSSTop(element) {
  if (typeof window.getComputedStyle === 'function') {
    return parseInt(window.getComputedStyle(element)?.top || 0, 10);
  }

  return 0;
}

export default {
  bind(el, { arg = 500 }) {
    if (el[ATTRIBUTE]) {
      return;
    }

    el[ATTRIBUTE] = throttle((e) => {
      el.classList.toggle('is-sticky', getOffsetTop(el) <= getCSSTop(el));
    }, arg);

    document.addEventListener('scroll', el[ATTRIBUTE]);
  },
  unbind(el) {
    if (el[ATTRIBUTE]) {
      document.removeEventListener('scroll', el[ATTRIBUTE], false);
      delete el[ATTRIBUTE];
    }
  },
};
