<template>
  <onboarding-step class="onboarding-onfido">
    <user-onfido
      class="onboarding-onfido__container"
      :sdk-token="sdkToken"
      @complete="$emit('complete')"
    />
  </onboarding-step>
</template>

<script>
import OnboardingStep from '@/components/Onboarding/OnboardingStep.vue';
import UserOnfido from '@/components/User/UserOnfido.vue';

export default {
  name: 'OnboardingOnfido',

  components: {
    UserOnfido,
    OnboardingStep,
  },

  props: {
    sdkToken: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.design .onboarding-onfido {
  position: relative;
  padding: 0;

  &__container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 80px;
  }
}
</style>
