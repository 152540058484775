export const INDUSTRIES = [
  {
    value: 'HOTELS_AND_FOOD_SERVICES',
    name: 'Hotels and Food Services',
  },
  {
    value: 'ARTS_ENTERTAINMENT_AND_RECREATION',
    name: 'Arts, Entertainment, and Recreation',
  },
  {
    value: 'HEALTHS_CARE_AND_SOCIAL_ASSISTANCE',
    name: 'Health Care and Social Assistance',
  },
  {
    value: 'OTHER',
    name: 'Other',
  },
];
